// //------------------------------------------------------------------------------------

// import { useEffect, useState } from 'react';
// import Header from '../../../components/Header/Header';
// import Navigation from '../../../components/Navigation/Navigation';
// import './Logs.scss';
// import baseUrl from '../../../scripts/baseUrl';
// import axios from 'axios';
// import Buffer from '../../../components/Buffer/Buffer';
// import Fallback from '../../../components/Fallback/Fallback';
// import getTime from '../../../scripts/getTime';
// import Pagination from '../../../components/Pagination/Pagination';

// //------------------------------------------------------------------------------------

// const toggleData = [
//     {
//         label: 'Contractors',
//         tableName: '',
//         isActive: true,
//     },
//     {
//         label: 'Employees',
//         tableName: '',
//         isActive: false,
//     },
//     {
//         label: 'Super Admin',
//         tableName: '',
//         isActive: false,
//     },
//     {
//         label: 'Admin',
//         tableName: '',
//         isActive: false,
//     },
//     // {
//     //     label: 'Guests',
//     //     tableName: '',
//     //     isActive: false,
//     // },
// ]

// const Logs = () => {
//     const [toggle, setToggle] = useState([...toggleData]);
//     const [superAdminLogs, setSuperAdminLogs] = useState([]);
//     const [adminLogs, setAdminLogs] = useState([]);
//     const [ownerLogs, setOwnerLogs] = useState([]);
//     const [employeeLogs, setEmployeeLogs] = useState([]);
//     const [guestLogs, setGuestLogs] = useState([]);

//     const [notFound, setNotFound] = useState(true);
//     const [buffer, setBuffer] = useState(true);
//     const [loader, setLoader] = useState(false);

//     const toggleTables = (id) => {
//         let newToggleData = [];
//         for (const data of toggle) {
//             data.isActive = false;
//             newToggleData.push(data);
//         }
//         for (let i = 0; i < newToggleData.length; i++) {
//             if (i == id) {
//                 newToggleData[i].isActive = true;
//             }
//         }
//         setToggle([...newToggleData]);
//     }

//     const getAdmins = async (tableName) => {
//         try {
//             const res = await axios.get(`${baseUrl}/logs/${tableName}`);
//             if (res.data) {
//                 if (tableName == 'superAdmin') {
//                     setSuperAdminLogs(res.data);
//                 };
//                 if (tableName == 'admin') {
//                     setAdminLogs(res.data);
//                 };
//                 setNotFound(false);
//             } else {
//                 setNotFound(true);
//             }
//             setBuffer(false);
//         } catch (err) {
//             console.log(err);
//         }
//     }

//     const getOwners = async (tableName) => {
//         try {
//             const res = await axios.get(`${baseUrl}/logs/${tableName}`);
//             if (res.data) {
//                 setOwnerLogs(res.data);
//                 setNotFound(false);
//             } else {
//                 setNotFound(true);
//             }
//             setBuffer(false);
//         } catch (err) {
//             console.log(err);
//         }
//     }

//     const getEmployees = async (tableName) => {
//         try {
//             const res = await axios.get(`${baseUrl}/logs/${tableName}`);
//             if (res.data) {
//                 setEmployeeLogs(res.data);
//                 setNotFound(false);
//             } else {
//                 setNotFound(true);
//             }
//             setBuffer(false);
//         } catch (err) {
//             console.log(err);
//         }
//     }

//     const getGuests = async (tableName) => {
//         try {
//             const res = await axios.get(`${baseUrl}/logs/${tableName}`);
//             if (res.data) {
//                 setGuestLogs(res.data);
//                 setNotFound(false);
//             } else {
//                 setNotFound(true);
//             }
//             setBuffer(false);
//         } catch (err) {
//             console.log(err);
//         }
//     }

//     useEffect(() => {
//         getAdmins('superAdmin');
//         getAdmins('admin');
//         getOwners('owner_logs');
//         getEmployees('employee_logs');
//         // getGuests('guest_logs');
//     }, []);

//     return (
//         <>
//             <Header />
//             <div className="screen-container">
//                 <Navigation />
//                 <div className='side-container'>
//                     {buffer && notFound ? <Buffer /> : <></>}

//                     {
//                         superAdminLogs.length == 0
//                             && adminLogs.length == 0
//                             && ownerLogs.length == 0
//                             && employeeLogs.length == 0
//                             && guestLogs.length == 0
//                             && !buffer
//                             ? <Fallback title='Not found' text='No data found in the database. Try refreshing the page.' />
//                             : <></>
//                     }

//                     {
//                         superAdminLogs.length != 0
//                             || adminLogs.length != 0
//                             || ownerLogs.length != 0
//                             || employeeLogs.length != 0
//                             || guestLogs.length != 0
//                             && !notFound
//                             && !buffer
//                             ? <>
//                                 <div className='screen-title title-large'>Logs</div>
//                                 <div className='logs-toggle-container'>
//                                     <div className='logs-toggle'>
//                                         {
//                                             toggle.map((data, index) => {
//                                                 const className = data.isActive ? 'toggle label-large active' : 'toggle label-large';
//                                                 return <button key={index} className={className} onClick={() => { toggleTables(index) }}>{data.label}</button>
//                                             })
//                                         }
//                                     </div>
//                                 </div>

//                                 {
//                                     toggle[0].isActive
//                                         ?
//                                         ownerLogs.length !== 0
//                                             ?
//                                             <div className='table-container'>
//                                                 <table>
//                                                     <tbody>
//                                                         <tr>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Sr.&nbsp;No.</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Fullname</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Date</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Time</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Action</div>
//                                                                 </div>
//                                                             </th>
//                                                         </tr>
//                                                         {
//                                                             ownerLogs.map((data, index) => {
//                                                                 return (
//                                                                     <tr key={index}>
//                                                                         <td>{index + 1}</td>
//                                                                         <td>{data.fullname}</td>
//                                                                         <td>{`${new Date(data.datetime).getFullYear()}-${new Date(data.datetime).getMonth()}-${new Date(data.datetime).getDate()}`}</td>
//                                                                         <td>{getTime(data.datetime)}</td>
//                                                                         <td className='logs-text'>
//                                                                             {data.type == 'registered' && 'Registered'}
//                                                                             {data.type == 'loggedIn' && 'Logged In'}
//                                                                             {data.type == 'loggedOut' && 'Logged Out'}
//                                                                             {
//                                                                                 data.type != 'registered' &&
//                                                                                     data.type != 'loggedIn' &&
//                                                                                     data.type != 'loggedOut' ?
//                                                                                     data.type
//                                                                                     : <></>
//                                                                             }
//                                                                         </td>
//                                                                     </tr>
//                                                                 )
//                                                             })
//                                                         }
//                                                     </tbody>
//                                                 </table>

//                                                 <Pagination />
//                                             </div>
//                                             : <Fallback title='Not found' text='No data found the database' />
//                                         : <></>
//                                 }

//                                 {
//                                     toggle[1].isActive
//                                         ?
//                                         employeeLogs.length !== 0
//                                             ?
//                                             <div className='table-container'>
//                                                 <table>
//                                                     <tbody>
//                                                         <tr>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Sr.&nbsp;No.</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Fullname</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Date</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Time</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Action</div>
//                                                                 </div>
//                                                             </th>
//                                                         </tr>
//                                                         {
//                                                             employeeLogs.map((data, index) => {
//                                                                 return (
//                                                                     <tr key={index}>
//                                                                         <td>{index + 1}</td>
//                                                                         <td>{data.fullname}</td>
//                                                                         <td>{`${new Date(data.datetime).getFullYear()}-${new Date(data.datetime).getMonth()}-${new Date(data.datetime).getDate()}`}</td>
//                                                                         <td>{getTime(data.datetime)}</td>
//                                                                         <td className='logs-text'>
//                                                                             {data.type == 'registered' && 'Registered'}
//                                                                             {data.type == 'loggedIn' && 'Logged In'}
//                                                                             {data.type == 'loggedOut' && 'Logged Out'}
//                                                                             {
//                                                                                 data.type != 'registered' &&
//                                                                                     data.type != 'loggedIn' &&
//                                                                                     data.type != 'loggedOut' ?
//                                                                                     data.type
//                                                                                     : <></>
//                                                                             }
//                                                                         </td>
//                                                                     </tr>
//                                                                 )
//                                                             })
//                                                         }
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                             : <Fallback title='Not found' text='No data found the database' />
//                                         : <></>
//                                 }

//                                 {
//                                     toggle[2].isActive
//                                         ?
//                                         superAdminLogs.length !== 0
//                                             ?
//                                             <div className='table-container'>
//                                                 <table>
//                                                     <tbody>
//                                                         <tr>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Sr.&nbsp;No.</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Fullname</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Date</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Time</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Action</div>
//                                                                 </div>
//                                                             </th>
//                                                         </tr>
//                                                         {
//                                                             superAdminLogs.map((data, index) => {
//                                                                 return (
//                                                                     <tr key={index}>
//                                                                         <td>{index + 1}</td>
//                                                                         <td>{data.fullname}</td>
//                                                                         <td>{`${new Date(data.datetime).getFullYear()}-${new Date(data.datetime).getMonth()}-${new Date(data.datetime).getDate()}`}</td>
//                                                                         <td>{getTime(data.datetime)}</td>
//                                                                         <td className='logs-text'>
//                                                                             {data.type == 'registered' && 'Registered'}
//                                                                             {data.type == 'loggedIn' && 'Logged In'}
//                                                                             {data.type == 'loggedOut' && 'Logged Out'}
//                                                                             {
//                                                                                 data.type != 'registered' &&
//                                                                                     data.type != 'loggedIn' &&
//                                                                                     data.type != 'loggedOut' ?
//                                                                                     data.type
//                                                                                     : <></>
//                                                                             }
//                                                                         </td>
//                                                                     </tr>
//                                                                 )
//                                                             })
//                                                         }
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                             : <Fallback title='Not found' text='No data found the database' />
//                                         : <></>
//                                 }

//                                 {
//                                     toggle[3].isActive
//                                         ?
//                                         adminLogs.length !== 0
//                                             ?
//                                             <div className='table-container'>
//                                                 <table>
//                                                     <tbody>
//                                                         <tr>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Sr.&nbsp;No.</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Fullname</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Date</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Time</div>
//                                                                 </div>
//                                                             </th>
//                                                             <th>
//                                                                 <div className='table-head'>
//                                                                     <div className='table-title'>Action</div>
//                                                                 </div>
//                                                             </th>
//                                                         </tr>
//                                                         {
//                                                             adminLogs.map((data, index) => {
//                                                                 return (
//                                                                     <tr key={index}>
//                                                                         <td>{index + 1}</td>
//                                                                         <td>{data.fullname}</td>
//                                                                         <td>{`${new Date(data.datetime).getFullYear()}-${new Date(data.datetime).getMonth()}-${new Date(data.datetime).getDate()}`}</td>
//                                                                         <td>{getTime(data.datetime)}</td>
//                                                                         <td className='logs-text'>
//                                                                             {data.type == 'registered' && 'Registered'}
//                                                                             {data.type == 'loggedIn' && 'Logged In'}
//                                                                             {data.type == 'loggedOut' && 'Logged Out'}
//                                                                             {
//                                                                                 data.type != 'registered' &&
//                                                                                     data.type != 'loggedIn' &&
//                                                                                     data.type != 'loggedOut' ?
//                                                                                     data.type
//                                                                                     : <></>
//                                                                             }
//                                                                         </td>
//                                                                     </tr>
//                                                                 )
//                                                             })
//                                                         }
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                             : <Fallback title='Not found' text='No data found the database' />
//                                         : <></>
//                                 }

//                                 {/* {
//                                             toggle[4].isActive
//                                                 ?
//                                                 guestLogs.length !== 0
//                                                     ?
//                                                     <div className='table-container'>
//                                                         <table>
//                                                             <tbody>
//                                                                 <tr>
//                                                                     <th>
//                                                                         <div className='table-head'>
//                                                                             <div className='table-title'>Sr.&nbsp;No.</div>
//                                                                         </div>
//                                                                     </th>
//                                                                     <th>
//                                                                         <div className='table-head'>
//                                                                             <div className='table-title'>Guest</div>
//                                                                         </div>
//                                                                     </th>
//                                                                     <th>
//                                                                         <div className='table-head'>
//                                                                             <div className='table-title'>Date</div>
//                                                                         </div>
//                                                                     </th>
//                                                                     <th>
//                                                                         <div className='table-head'>
//                                                                             <div className='table-title'>Time</div>
//                                                                         </div>
//                                                                     </th>
//                                                                     <th>
//                                                                         <div className='table-head'>
//                                                                             <div className='table-title'>Action</div>
//                                                                         </div>
//                                                                     </th>
//                                                                 </tr>
//                                                                 {
//                                                                     guestLogs.map((data, index) => {
//                                                                         return (
//                                                                             <tr key={index}>
//                                                                                 <td>{index + 1}</td>
//                                                                                 <td>{data.unique_id}</td>
//                                                                                 <td>{`${new Date(data.datetime).getFullYear()}-${new Date(data.datetime).getMonth()}-${new Date(data.datetime).getDate()}`}</td>
//                                                                                 <td>{getTime(data.datetime)}</td>
//                                                                                 <td>
//                                                                                     {data.type == 'registered' && 'Registered'}
//                                                                                     {data.type == 'loggedIn' && 'Logged In'}
//                                                                                     {data.type == 'loggedOut' && 'Logged Out'}
//                                                                                     {data.type == 'visited' && 'Visited'}
//                                                                                 </td>
//                                                                             </tr>
//                                                                         )
//                                                                     })
//                                                                 }
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                     : <Fallback title='Not found' text='No data found the database' />
//                                                 : <></>
//                                         } */}

//                                 {/* Contractor logs */}
//                                 {/* <div className='table-container'>
//                         <table>
//                             <tbody>
//                                 <tr>
//                                     <th>
//                                         <div className='table-head'>
//                                             <div className='table-title'>Sr.&nbsp;No.</div>
//                                         </div>
//                                     </th>
//                                     <th>
//                                         <div className='table-head'>
//                                             <div className='table-title'>Fullname</div>
//                                         </div>
//                                     </th>
//                                     <th>
//                                         <div className='table-head'>
//                                             <div className='table-title'>Mobile&nbsp;no.</div>
//                                         </div>
//                                     </th>
//                                     <th>
//                                         <div className='table-head'>
//                                             <div className='table-title'>Date</div>
//                                         </div>
//                                     </th>
//                                     <th>
//                                         <div className='table-head'>
//                                             <div className='table-title'>Time</div>
//                                         </div>
//                                     </th>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div> */}
//                             </>
//                             : <></>
//                     }
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Logs;

// //------------------------------------------------------------------------------------

//------------------------------------------------------------------------------------

import './Logs.scss';

import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import Navigation from '../../../components/Navigation/Navigation';
import Pagination from '../../../components/Pagination/Pagination';
import auth from '../../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import getTime from '../../../scripts/getTime';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { socket } from '../../../App';

//------------------------------------------------------------------------------------

const toggleData = [
	{
		label: 'Contractors',
		tableName: '',
		isActive: true,
	},
	{
		label: 'Employees',
		tableName: '',
		isActive: false,
	},
	{
		label: 'Super Admin',
		tableName: '',
		isActive: false,
	},
	{
		label: 'Admin',
		tableName: '',
		isActive: false,
	},
	// {
	//     label: 'Guests',
	//     tableName: '',
	//     isActive: false,
	// },
];

const Logs = () => {
	unauthorizedRedirection();
	document.title = `Logs • Bhusawal Division Catering Vendor Id`;

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const [toggle, setToggle] = useState([...toggleData]);
	const [superAdminLogs, setSuperAdminLogs] = useState([]);
	const [adminLogs, setAdminLogs] = useState([]);
	const [ownerLogs, setOwnerLogs] = useState([]);
	const [employeeLogs, setEmployeeLogs] = useState([]);
	const [guestLogs, setGuestLogs] = useState([]);

	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);
	const [loader, setLoader] = useState(false);

	const [ownerTotalItems, setOwnerTotalItems] = useState(0);
	const [ownerItemsPerPage, setOwnerItemsPerPage] = useState(200);
	const [ownerActivePage, setOwnerActivePage] = useState(1);

	const [employeeTotalItems, setemployeeTotalItems] = useState(0);
	const [employeeItemsPerPage, setemployeeItemsPerPage] = useState(200);
	const [employeeActivePage, setemployeeActivePage] = useState(1);

	const [superAdminTotalItems, setsuperAdminTotalItems] = useState(0);
	const [superAdminItemsPerPage, setsuperAdminItemsPerPage] = useState(200);
	const [superAdminActivePage, setsuperAdminActivePage] = useState(1);

	const [adminTotalItems, setadminTotalItems] = useState(0);
	const [adminItemsPerPage, setadminItemsPerPage] = useState(200);
	const [adminActivePage, setadminActivePage] = useState(1);

	const toggleTables = (id) => {
		let newToggleData = [];
		for (const data of toggle) {
			data.isActive = false;
			newToggleData.push(data);
		}
		for (let i = 0; i < newToggleData.length; i++) {
			if (i == id) {
				newToggleData[i].isActive = true;
			}
		}
		setToggle([...newToggleData]);
	};

	const getAdmins = async (tableName) => {
		try {
			const res = await axios.get(`${baseUrl}/logs/${tableName}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				if (tableName == 'superAdmin') {
					// setSuperAdminLogs(res.data);
					let array = [...res.data];
					let mainArray = [];

					let n = 0;
					for (
						let i = 0;
						i < Math.ceil(array.length / superAdminItemsPerPage);
						i++
					) {
						mainArray.push([]);
						for (let a = 0; a < superAdminItemsPerPage; a++) {
							if (array[n] != undefined) {
								mainArray[i].push(array[n]);
								n++;
							}
						}
					}
					setSuperAdminLogs(mainArray);
					setsuperAdminTotalItems(res.data.length);
				}
				if (tableName == 'admin') {
					// setAdminLogs(res.data);
					let array = [...res.data];
					let mainArray = [];

					let n = 0;
					for (
						let i = 0;
						i < Math.ceil(array.length / adminItemsPerPage);
						i++
					) {
						mainArray.push([]);
						for (let a = 0; a < adminItemsPerPage; a++) {
							if (array[n] != undefined) {
								mainArray[i].push(array[n]);
								n++;
							}
						}
					}
					setAdminLogs(mainArray);
					setadminTotalItems(res.data.length);
				}
				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const getOwners = async (tableName) => {
		try {
			const res = await axios.get(`${baseUrl}/logs/${tableName}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				// setOwnerLogs(res.data);
				let array = [...res.data];
				let mainArray = [];

				let n = 0;
				for (
					let i = 0;
					i < Math.ceil(array.length / ownerItemsPerPage);
					i++
				) {
					mainArray.push([]);
					for (let a = 0; a < ownerItemsPerPage; a++) {
						if (array[n] != undefined) {
							mainArray[i].push(array[n]);
							n++;
						}
					}
				}
				setOwnerLogs(mainArray);
				setOwnerTotalItems(res.data.length);
				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const getEmployees = async (tableName) => {
		try {
			const res = await axios.get(`${baseUrl}/logs/${tableName}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				// setEmployeeLogs(res.data);
				let array = [...res.data];
				let mainArray = [];

				let n = 0;
				for (
					let i = 0;
					i < Math.ceil(array.length / employeeItemsPerPage);
					i++
				) {
					mainArray.push([]);
					for (let a = 0; a < employeeItemsPerPage; a++) {
						if (array[n] != undefined) {
							mainArray[i].push(array[n]);
							n++;
						}
					}
				}

				setEmployeeLogs(mainArray);
				setemployeeTotalItems(res.data.length);
				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const getGuests = async (tableName) => {
		try {
			const res = await axios.get(`${baseUrl}/logs/${tableName}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				setGuestLogs(res.data);
				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAdmins('superAdmin');
		getAdmins('admin');
		getOwners('owner_logs');
		getEmployees('employee_logs');

		socket.on('isOwnerUpdated', (data) => {
			getOwners('owner_logs');
		});

		// const interval = setInterval(() => {
		// 	getAdmins('superAdmin');
		// 	getAdmins('admin');
		// 	getOwners('owner_logs');
		// 	getEmployees('employee_logs');
		// }, 1000);

		// return () => {
		// 	clearInterval(interval);
		// };
	}, []);

	const getPageNumberOwner = (number) => {
		setOwnerActivePage(number);
	};

	const getPageNumberemployee = (number) => {
		setemployeeActivePage(number);
	};

	const getPageNumbersuperAdmin = (number) => {
		setsuperAdminActivePage(number);
	};

	const getPageNumberadmin = (number) => {
		setadminActivePage(number);
	};

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Navigation />
				<div className='side-container'>
					{buffer && notFound ? <Buffer /> : <></>}

					{superAdminLogs.length == 0 &&
					adminLogs.length == 0 &&
					ownerLogs.length == 0 &&
					employeeLogs.length == 0 &&
					guestLogs.length == 0 &&
					!buffer ? (
						<Fallback
							title='Not found'
							text='No data found in the database. Try refreshing the page.'
						/>
					) : (
						<></>
					)}

					{superAdminLogs.length != 0 ||
					adminLogs.length != 0 ||
					ownerLogs.length != 0 ||
					employeeLogs.length != 0 ||
					(guestLogs.length != 0 && !notFound && !buffer) ? (
						<>
							<div className='screen-title headline-large'>
								Logs
							</div>
							<div className='logs-toggle-container'>
								<div className='logs-toggle'>
									{toggle.map((data, index) => {
										const className = data.isActive
											? 'toggle label-large active'
											: 'toggle label-large';
										return (
											<button
												key={index}
												className={className}
												onClick={() => {
													toggleTables(index);
												}}
											>
												{data.label}
											</button>
										);
									})}
								</div>
							</div>

							{toggle[0].isActive ? (
								ownerLogs.length !== 0 ? (
									<>
										<div className='table-container center'>
											<table>
												<thead>
													<tr>
														{/* <th>
                                                                <div className='table-head'>
                                                                    <div className='table-title'>Sr.&nbsp;No.</div>
                                                                </div>
                                                            </th> */}
														<th className='left-box '>
															<div className='table-head'>
																<div className='table-title'>
																	Sr. No.
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Fullname
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Date
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Time
																</div>
															</div>
														</th>
														<th className='right-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Action
																</div>
															</div>
														</th>
													</tr>
												</thead>
												{ownerLogs[
													ownerActivePage - 1
												].map((data, index) => {
													return (
														<tbody key={index}>
															<tr className='gap'></tr>
															<tr>
																{/* <td>{index + 1}</td> */}
																<td className='left-box center'>
																	{(ownerActivePage -
																		1) *
																		ownerItemsPerPage +
																		(index +
																			1)}
																</td>
																<td>
																	{
																		data.fullname
																	}
																</td>
																<td>{`${new Date(
																	data.datetime
																).getDate()}-${
																	new Date(
																		data.datetime
																	).getMonth() +
																	1
																}-${new Date(
																	data.datetime
																).getFullYear()}`}</td>
																<td>
																	{getTime(
																		data.datetime
																	)}
																</td>
																<td className='logs-text right-box'>
																	{data.type ==
																		'registered' &&
																		'Registered'}
																	{data.type ==
																		'loggedIn' &&
																		'Logged In'}
																	{data.type ==
																		'loggedOut' &&
																		'Logged Out'}
																	{data.type !=
																		'registered' &&
																	data.type !=
																		'loggedIn' &&
																	data.type !=
																		'loggedOut' ? (
																		data.type
																	) : (
																		<></>
																	)}
																</td>
															</tr>
														</tbody>
													);
												})}
											</table>
										</div>
										<Pagination
											getTotalItems={ownerTotalItems}
											getItemsPerPage={ownerItemsPerPage}
											getPageNumber={getPageNumberOwner}
											activePage={ownerActivePage}
										/>
									</>
								) : (
									<Fallback
										title='Not found'
										text='No data found the database'
									/>
								)
							) : (
								<></>
							)}

							{toggle[1].isActive ? (
								employeeLogs.length !== 0 ? (
									<>
										<div className='table-container center'>
											<table>
												<thead>
													<tr>
														{/* <th>
                                                                <div className='table-head'>
                                                                    <div className='table-title'>Sr.&nbsp;No.</div>
                                                                </div>
                                                            </th> */}
														<th className='left-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Sr. No.
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Fullname
																</div>
															</div>
														</th>
														{/* <th>
															<div className='table-head'>
																<div className='table-title'>
																	Designation
																</div>
															</div>
														</th> */}
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Date
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Time
																</div>
															</div>
														</th>
														<th className='right-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Action
																</div>
															</div>
														</th>
													</tr>
												</thead>
												{employeeLogs[
													employeeActivePage - 1
												].map((data, index) => {
													return (
														<tbody key={index}>
															<tr className='gap'></tr>
															<tr>
																{/* <td>{index + 1}</td> */}
																<td className='left-box center'>
																	{(employeeActivePage -
																		1) *
																		employeeItemsPerPage +
																		(index +
																			1)}
																</td>
																<td>
																	{
																		data.fullname
																	}
																</td>
																{/* <td>
																	{
																		data.designation
																	}
																</td> */}
																<td>{`${new Date(
																	data.datetime
																).getDate()}-${
																	new Date(
																		data.datetime
																	).getMonth() +
																	1
																}-${new Date(
																	data.datetime
																).getFullYear()}`}</td>
																<td>
																	{getTime(
																		data.datetime
																	)}
																</td>
																<td className='logs-text right-box'>
																	{data.type ==
																		'registered' &&
																		'Registered'}
																	{data.type ==
																		'loggedIn' &&
																		'Logged In'}
																	{data.type ==
																		'loggedOut' &&
																		'Logged Out'}
																	{data.type !=
																		'registered' &&
																	data.type !=
																		'loggedIn' &&
																	data.type !=
																		'loggedOut' ? (
																		data.type
																	) : (
																		<></>
																	)}
																</td>
															</tr>
														</tbody>
													);
												})}
											</table>
										</div>
										<Pagination
											getTotalItems={employeeTotalItems}
											getItemsPerPage={
												employeeItemsPerPage
											}
											getPageNumber={
												getPageNumberemployee
											}
											activePage={employeeActivePage}
										/>
									</>
								) : (
									<Fallback
										title='Not found'
										text='No data found the database'
									/>
								)
							) : (
								<></>
							)}

							{toggle[2].isActive ? (
								superAdminLogs.length !== 0 ? (
									<>
										<div className='table-container center'>
											<table>
												<thead>
													<tr>
														{/* <th>
                                                                <div className='table-head'>
                                                                    <div className='table-title'>Sr.&nbsp;No.</div>
                                                                </div>
                                                            </th> */}
														<th className='left-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Sr. No.
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Fullname
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Date
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Time
																</div>
															</div>
														</th>
														<th className='right-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Action
																</div>
															</div>
														</th>
													</tr>
												</thead>
												{superAdminLogs[
													superAdminActivePage - 1
												].map((data, index) => {
													return (
														<tbody key={index}>
															<tr className='gap'></tr>
															<tr>
																{/* <td>{index + 1}</td> */}
																<td className='left-box center'>
																	{(superAdminActivePage -
																		1) *
																		superAdminItemsPerPage +
																		(index +
																			1)}
																</td>
																<td>
																	{
																		data.fullname
																	}
																</td>
																<td>{`${new Date(
																	data.datetime
																).getDate()}-${
																	new Date(
																		data.datetime
																	).getMonth() +
																	1
																}-${new Date(
																	data.datetime
																).getFullYear()}`}</td>
																<td>
																	{getTime(
																		data.datetime
																	)}
																</td>
																<td className='logs-text right-box'>
																	{data.type ==
																		'registered' &&
																		'Registered'}
																	{data.type ==
																		'loggedIn' &&
																		'Logged In'}
																	{data.type ==
																		'loggedOut' &&
																		'Logged Out'}
																	{data.type !=
																		'registered' &&
																	data.type !=
																		'loggedIn' &&
																	data.type !=
																		'loggedOut' ? (
																		data.type
																	) : (
																		<></>
																	)}
																</td>
															</tr>
														</tbody>
													);
												})}
											</table>
										</div>
										<Pagination
											getTotalItems={superAdminTotalItems}
											getItemsPerPage={
												superAdminItemsPerPage
											}
											getPageNumber={
												getPageNumbersuperAdmin
											}
											activePage={superAdminActivePage}
										/>
									</>
								) : (
									<Fallback
										title='Not found'
										text='No data found the database'
									/>
								)
							) : (
								<></>
							)}

							{toggle[3].isActive ? (
								adminLogs.length !== 0 ? (
									<>
										<div className='table-container center'>
											<table>
												<thead>
													<tr>
														{/* <th>
                                                                <div className='table-head'>
                                                                    <div className='table-title'>Sr.&nbsp;No.</div>
                                                                </div>
                                                            </th> */}
														<th className='left-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Sr. No.
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Fullname
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Date
																</div>
															</div>
														</th>
														<th>
															<div className='table-head'>
																<div className='table-title'>
																	Time
																</div>
															</div>
														</th>
														<th className='right-box'>
															<div className='table-head'>
																<div className='table-title'>
																	Action
																</div>
															</div>
														</th>
													</tr>
												</thead>
												{adminLogs[
													adminActivePage - 1
												].map((data, index) => {
													return (
														<tbody key={index}>
															<tr className='gap'></tr>
															<tr>
																{/* <td>{index + 1}</td> */}
																<td className='left-box center'>
																	{(adminActivePage -
																		1) *
																		adminItemsPerPage +
																		(index +
																			1)}
																</td>
																<td className='left-box'>
																	{
																		data.fullname
																	}
																</td>
																<td>{`${new Date(
																	data.datetime
																).getDate()}-${
																	new Date(
																		data.datetime
																	).getMonth() +
																	1
																}-${new Date(
																	data.datetime
																).getFullYear()}`}</td>
																<td>
																	{getTime(
																		data.datetime
																	)}
																</td>
																<td className='logs-text right-box'>
																	{data.type ==
																		'registered' &&
																		'Registered'}
																	{data.type ==
																		'loggedIn' &&
																		'Logged In'}
																	{data.type ==
																		'loggedOut' &&
																		'Logged Out'}
																	{data.type !=
																		'registered' &&
																	data.type !=
																		'loggedIn' &&
																	data.type !=
																		'loggedOut' ? (
																		data.type
																	) : (
																		<></>
																	)}
																</td>
															</tr>
														</tbody>
													);
												})}
											</table>
										</div>
										<Pagination
											getTotalItems={adminTotalItems}
											getItemsPerPage={adminItemsPerPage}
											getPageNumber={getPageNumberadmin}
											activePage={adminActivePage}
										/>
									</>
								) : (
									<Fallback
										title='Not found'
										text='No data found the database'
									/>
								)
							) : (
								<></>
							)}

							{/* {
                                            toggle[4].isActive
                                                ?
                                                guestLogs.length !== 0
                                                    ?
                                                    <div className='table-container'>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <th>
                                                                        <div className='table-head'>
                                                                            <div className='table-title'>Sr.&nbsp;No.</div>
                                                                        </div>
                                                                    </th>
                                                                    <th>
                                                                        <div className='table-head'>
                                                                            <div className='table-title'>Guest</div>
                                                                        </div>
                                                                    </th>
                                                                    <th>
                                                                        <div className='table-head'>
                                                                            <div className='table-title'>Date</div>
                                                                        </div>
                                                                    </th>
                                                                    <th>
                                                                        <div className='table-head'>
                                                                            <div className='table-title'>Time</div>
                                                                        </div>
                                                                    </th>
                                                                    <th>
                                                                        <div className='table-head'>
                                                                            <div className='table-title'>Action</div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                {
                                                                    guestLogs.map((data, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{data.unique_id}</td>
                                                                                <td>{`${new Date(data.datetime).getFullYear()}-${new Date(data.datetime).getMonth()}-${new Date(data.datetime).getDate()}`}</td>
                                                                                <td>{getTime(data.datetime)}</td>
                                                                                <td>
                                                                                    {data.type == 'registered' && 'Registered'}
                                                                                    {data.type == 'loggedIn' && 'Logged In'}
                                                                                    {data.type == 'loggedOut' && 'Logged Out'}
                                                                                    {data.type == 'visited' && 'Visited'}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <Fallback title='Not found' text='No data found the database' />
                                                : <></>
                                        } */}

							{/* Contractor logs */}
							{/* <div className='table-container'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>
                                        <div className='table-head'>
                                            <div className='table-title'>Sr.&nbsp;No.</div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='table-head'>
                                            <div className='table-title'>Fullname</div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='table-head'>
                                            <div className='table-title'>Mobile&nbsp;no.</div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='table-head'>
                                            <div className='table-title'>Date</div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='table-head'>
                                            <div className='table-title'>Time</div>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
						</>
					) : (
						<></>
					)}

					<Footer />
				</div>
			</div>
		</>
	);
};

export default Logs;

//------------------------------------------------------------------------------------
