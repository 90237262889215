//------------------------------------------------------------------------------------

import './Notifications.scss';

import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import Fallback from '../../../components/Fallback/Fallback';
import Header from '../../../components/Header/Header';
import { Link } from 'react-router-dom';
import Navigation from '../../../components/Navigation/Navigation';
import auth from '../../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { socket } from '../../../App';

//------------------------------------------------------------------------------------

const Notifications = () => {
	unauthorizedRedirection();
	document.title = `Notifications • Bhusawal Division Catering Vendor Id`;

	const [notifications, setNotifications] = useState(['']);
	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);
	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const getNotifications = async () => {
		try {
			const res = await axios.get(`${baseUrl}/notifications`);

			if (res.data) {
				setNotifications(res.data);
				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getNotifications();

		socket.on('isOwnerUpdated', (data) => {
			getNotifications();
		});

		socket.on('isVendorUpdated', (data) => {
			getNotifications();
		});
		// let callData = setInterval(() => {
		// 	getNotifications();
		// }, 1000 * 5);

		// return () => {
		// 	clearInterval(callData);
		// };
	}, []);

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Navigation />
				<div className='side-container'>
					{buffer && notFound ? <Buffer /> : <></>}

					{notifications.length == 0 && !buffer ? (
						<Fallback
							title='Not found'
							text='No data found in the database. Try refreshing the page.'
						/>
					) : (
						<></>
					)}

					{notifications.length !== 0 && !notFound && !buffer && (
						<>
							<div className='screen-title headline-large'>
								Notifications
							</div>

							{notifications.contractorPending.length != 0 && (
								<div className='label label-small'>
									Contractors
								</div>
							)}
							<div className='notification-container'>
								{notifications.contractorPending.map(
									(data, index) => {
										let link =
											data.messageOf == 'owner' &&
											'/owners';
										return (
											<div
												key={index}
												className='notification'
											>
												<div className='text body-medium'>
													{data.message}
												</div>
												<Link
													to={link}
													className='view-button'
												>
													<button className='text-button'>
														View
													</button>
												</Link>
											</div>
										);
									}
								)}
							</div>

							{notifications.vendorsPending.length != 0 && (
								<div className='label label-small'>Vendors</div>
							)}
							<div className='notification-container'>
								{notifications.vendorsPending.map(
									(data, index) => {
										let link =
											data.messageOf == 'vendor' &&
											'/vendors';
										return (
											<div
												key={index}
												className='notification'
											>
												<div className='text body-medium'>
													{data.message}
												</div>
												<Link
													to={link}
													className='view-button'
												>
													<button className='text-button'>
														View
													</button>
												</Link>
											</div>
										);
									}
								)}
							</div>

							{(localStorage.getItem('admin') != null ||
								localStorage.getItem('admin') != '') &&
								(localStorage.getItem('employee') == null ||
									localStorage.getItem('employee') == '') && (
									<>
										{notifications.employeesPending
											.length != 0 && (
											<div className='label label-small'>
												Employees
											</div>
										)}
										<div className='notification-container'>
											{notifications.employeesPending.map(
												(data, index) => {
													let link =
														data.messageOf ==
															'employee' &&
														'/employees';
													return (
														<div
															key={index}
															className='notification'
														>
															<div className='text body-medium'>
																{data.message}
															</div>
															<Link
																to={link}
																className='view-button'
															>
																<button className='text-button'>
																	View
																</button>
															</Link>
														</div>
													);
												}
											)}
										</div>
									</>
								)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Notifications;

//------------------------------------------------------------------------------------
