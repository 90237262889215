//------------------------------------------------------------------------------------

import '../internal.scss';

import {
	CheckCircle,
	Clear,
	ExpandLess,
	ExpandMore,
	Refresh,
} from '../../../components/Icons/Icons';
import baseUrl, { userBaseUrl } from '../../../scripts/baseUrl';
import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import CheckUncheck from '../../../components/CheckUncheck/CheckUncheck';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import Loading from '../../../components/Loading/Loading';
import Navigation from '../../../components/Navigation/Navigation';
import Pagination from '../../../components/Pagination/Pagination';
import auth from '../../../scripts/auth';
import axios from 'axios';
import calculateDateStatus from '../../../scripts/calculateDateStatus';
import filterVendorsByStation from '../../../scripts/filterVendorsByStation';
import hightlight from '../../../scripts/hightlighter';
import sortTable from '../../../scripts/sort';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { useLocation, useNavigate } from 'react-router-dom';
import { socket } from '../../../App';

// import { saveAs } from 'file-saver';

//------------------------------------------------------------------------------------

let searchTextField = '';

const Vendors = () => {
	unauthorizedRedirection();
	document.title = `Vendors • Bhusawal Division Catering Vendor Id`;

	const navigate = useNavigate();

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const [vendors, setVendors] = useState([]);
	const [owner, setOwner] = useState('');
	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);
	const [loader, setLoader] = useState(false);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [fetchComplete, setFetchComplete] = useState(false);
	const [vendorAddStatus, setVendorAddStatus] = useState({
		added: {
			pfVendorCount: 0,
			stallTrolleyVendorCount: 0,
			supportingStaff: 0,
		},
		total: {
			pfVendorCount: 0,
			stallTrolleyVendorCount: 0,
			supportingStaff: 0,
		},
	});

	const [vendorsTotalItems, setVendorsTotalItems] = useState(0);
	const [vendorsItemsPerPage, setVendorsItemsPerPage] = useState(50);
	const [vendorsActivePage, setVendorsActivePage] = useState(1);

	const [renewalAvailable, setRenewalAvailable] = useState(false);
	const [toggle, setToggle] = useState(0);

	const [rawVendors, setRawVendors] = useState([]);

	const currentScreen = useLocation().pathname;
	const { state } = useLocation();

	// const [totalItems, setTotalItems] = useState(0);
	// const [itemsPerPage, setItemsPerPage] = useState(2);
	// const [activePage, setActivePage] = useState(1);

	const getVendorAddStatus = async (ownerId) => {
		try {
			const res = await axios.get(
				`${userBaseUrl}/owners/vendorAddStatus/${ownerId}`,
				{
					headers: {
						'access-token': accessToken,
					},
				}
			);

			if (!auth(res.data.message)) return;

			if (res.data) {
				setVendorAddStatus((prev) => ({ ...prev, ...res.data }));
				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const getVendors = async () => {
		setIsRefreshing(true);

		if (currentScreen === '/owners/vendors') {
			const { ownerId, shopName } = state;
			setOwner(shopName);

			getVendorAddStatus(ownerId);

			try {
				const res = await axios.get(
					`${userBaseUrl}/vendors/${ownerId}`,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					if (res.data.length === 0) {
						alert(`Don't have any vendors under ${shopName}.`);
						navigate('/owners');
					}

					const arrayData = [];

					let vendorsArray =
						(localStorage.getItem('admin') == null ||
							localStorage.getItem('admin') == '') &&
						(localStorage.getItem('employee') != null ||
							localStorage.getItem('employee') != '')
							? filterVendorsByStation(res.data)
							: res.data;

					for (const data of vendorsArray) {
						arrayData.unshift(data);
					}

					let array = arrayData;
					setRawVendors([...array]);

					let mainArray = [];

					let n = 0;
					for (
						let i = 0;
						i < Math.ceil(array.length / vendorsItemsPerPage);
						i++
					) {
						mainArray.push([]);
						for (let a = 0; a < vendorsItemsPerPage; a++) {
							if (array[n] != undefined) {
								mainArray[i].push(array[n]);
								n++;
							}
						}
					}

					setVendors(mainArray);
					setVendorsTotalItems(res.data.length);

					setNotFound(false);
				} else {
					setNotFound(true);
				}
				setBuffer(false);
				setTimeout(() => {
					setTimeout(() => {
						setFetchComplete(false);
					}, 2000);
					setFetchComplete(true);
					setIsRefreshing(false);
				}, 1000);
			} catch (err) {
				console.log(err);
			}
		} else {
			setOwner('');
			try {
				const res = await axios.get(`${baseUrl}/vendors`, {
					headers: {
						'access-token': accessToken,
					},
				});

				if (!auth(res.data.message)) return;

				if (res.data) {
					const arrayData = [];

					let thisVendorsArray =
						(localStorage.getItem('admin') == null ||
							localStorage.getItem('admin') == '') &&
						(localStorage.getItem('employee') != null ||
							localStorage.getItem('employee') != '')
							? filterVendorsByStation(res.data)
							: res.data;

					// for (const data of thisVendorsArray) {
					// 	arrayData.unshift(data);
					// }
					// setVendors([...arrayData]);

					for (const data of thisVendorsArray) {
						arrayData.unshift(data);
					}

					let array = arrayData;
					let mainArray = [];

					let n = 0;
					for (
						let i = 0;
						i < Math.ceil(array.length / vendorsItemsPerPage);
						i++
					) {
						mainArray.push([]);
						for (let a = 0; a < vendorsItemsPerPage; a++) {
							if (array[n] != undefined) {
								mainArray[i].push(array[n]);
								n++;
							}
						}
					}

					setVendors(mainArray);
					setVendorsTotalItems(res.data.length);

					setNotFound(false);

					setTimeout(() => {
						setTimeout(() => {
							setFetchComplete(false);
						}, 2000);
						setFetchComplete(true);
						setIsRefreshing(false);
					}, 1000);
				} else {
					setNotFound(true);
				}
				setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	useEffect(() => {
		getVendors();

		socket.on('isVendorUpdated', (data) => {
			if (searchTextField.length === 0) {
				getVendors();
			}
		});

		socket.on('isOwnerUpdated', (data) => {
			if (searchTextField.length === 0) {
				getVendors();
			}
		});

		// let callData = setInterval(() => {
		//     getVendors();
		// }, 1000 * 5);

		// return () => {
		//     clearInterval(callData);
		// }

		// setTimeout(() => {
		//     const searchInput = document.getElementById('search-input');
		//     const onEnter = document.getElementById('on-enter');
		//     searchInput.addEventListener("keypress", function (event) {
		//         if (event.key === "Enter") {
		//             event.preventDefault();
		//             onEnter.click();
		//         }
		//     });
		// }, 500);
	}, [currentScreen]);

	const sort = async (table, column, order) => {
		setLoader(true);
		let thisArray = await sortTable(table, column, order);
		// const arrayData = [];
		// for (const data of array) {
		// 	arrayData.unshift(data);
		// }
		// setVendors([...arrayData]);
		if (currentScreen === '/owners/vendors') {
			const arrayData = [];

			let vendorsArray =
				(localStorage.getItem('admin') == null ||
					localStorage.getItem('admin') == '') &&
				(localStorage.getItem('employee') != null ||
					localStorage.getItem('employee') != '')
					? filterVendorsByStation(thisArray)
					: thisArray;

			for (const data of vendorsArray) {
				arrayData.unshift(data);
			}

			let array = arrayData;
			let mainArray = [];

			let n = 0;
			for (
				let i = 0;
				i < Math.ceil(array.length / vendorsItemsPerPage);
				i++
			) {
				mainArray.push([]);
				for (let a = 0; a < vendorsItemsPerPage; a++) {
					if (array[n] != undefined) {
						mainArray[i].push(array[n]);
						n++;
					}
				}
			}

			setVendors(mainArray);
			setVendorsTotalItems(thisArray.length);
		} else {
			const arrayData = [];

			let thisVendorsArray =
				(localStorage.getItem('admin') == null ||
					localStorage.getItem('admin') == '') &&
				(localStorage.getItem('employee') != null ||
					localStorage.getItem('employee') != '')
					? filterVendorsByStation(thisArray)
					: thisArray;

			// for (const data of thisVendorsArray) {
			// 	arrayData.unshift(data);
			// }
			// setVendors([...arrayData]);

			for (const data of thisVendorsArray) {
				arrayData.unshift(data);
			}

			let array = arrayData;
			let mainArray = [];

			let n = 0;
			for (
				let i = 0;
				i < Math.ceil(array.length / vendorsItemsPerPage);
				i++
			) {
				mainArray.push([]);
				for (let a = 0; a < vendorsItemsPerPage; a++) {
					if (array[n] != undefined) {
						mainArray[i].push(array[n]);
						n++;
					}
				}
			}

			setVendors(mainArray);
			setVendorsTotalItems(thisArray.length);
		}
		setLoader(false);
	};

	const [searchText, setSearchText] = useState({
		search: '',
	});

	const handleChange = (e) => {
		setSearchText((prev) => ({ ...prev, search: e.target.value }));
	};

	const search = async () => {
		if (searchText.search.length !== 0 && searchText.search.length < 255) {
			let searchUrl = '';

			if (currentScreen === '/owners/vendors') {
				const { ownerId, shopName } = state;
				searchUrl = `${baseUrl}/vendors-under-this-owner/search/${ownerId}`;
			} else {
				searchUrl = `${baseUrl}/vendors/search`;
			}

			try {
				setNotFound(true);
				setBuffer(true);
				const res = await axios.post(searchUrl, searchText, {
					headers: {
						'access-token': accessToken,
					},
				});

				if (!auth(res.data.message)) return;

				if (res.data) {
					if (res.data.length === 0) {
						setNotFound(false);
						setBuffer(false);
						setTimeout(() => {
							alert(`No results for "${searchText.search}".`);
						}, 10);
						return;
					}

					// const arrayData = [];
					// for (const data of res.data) {
					// 	arrayData.unshift(data);
					// }
					// setVendors([...arrayData]);
					if (currentScreen === '/owners/vendors') {
						const arrayData = [];

						let vendorsArray =
							(localStorage.getItem('admin') == null ||
								localStorage.getItem('admin') == '') &&
							(localStorage.getItem('employee') != null ||
								localStorage.getItem('employee') != '')
								? filterVendorsByStation(res.data)
								: res.data;

						for (const data of vendorsArray) {
							arrayData.unshift(data);
						}

						let array = arrayData;
						let mainArray = [];

						let n = 0;
						for (
							let i = 0;
							i < Math.ceil(array.length / vendorsItemsPerPage);
							i++
						) {
							mainArray.push([]);
							for (let a = 0; a < vendorsItemsPerPage; a++) {
								if (array[n] != undefined) {
									mainArray[i].push(array[n]);
									n++;
								}
							}
						}

						setVendors(mainArray);
						setVendorsTotalItems(res.data.length);
					} else {
						const arrayData = [];

						let thisVendorsArray =
							(localStorage.getItem('admin') == null ||
								localStorage.getItem('admin') == '') &&
							(localStorage.getItem('employee') != null ||
								localStorage.getItem('employee') != '')
								? filterVendorsByStation(res.data)
								: res.data;

						// for (const data of thisVendorsArray) {
						// 	arrayData.unshift(data);
						// }
						// setVendors([...arrayData]);

						for (const data of thisVendorsArray) {
							arrayData.unshift(data);
						}

						let array = arrayData;
						let mainArray = [];

						let n = 0;
						for (
							let i = 0;
							i < Math.ceil(array.length / vendorsItemsPerPage);
							i++
						) {
							mainArray.push([]);
							for (let a = 0; a < vendorsItemsPerPage; a++) {
								if (array[n] != undefined) {
									mainArray[i].push(array[n]);
									n++;
								}
							}
						}

						setVendors(mainArray);
						setVendorsTotalItems(res.data.length);
					}
					setNotFound(false);
				} else {
					setNotFound(true);
				}
				setBuffer(false);
				searchTextField = searchText.search;
				hightlight(searchTextField);

				// setSearchText((prev) => ({ ...prev, search: '' }));
				// searchTextField = searchText.search;
			} catch (err) {
				console.log(err);
			}
		} else if (
			searchText.search.length !== 0 &&
			searchText.search.length > 255
		) {
			alert('Search text is too big to search.');
		} else {
			alert("Can't search, search field is empty.");
		}
	};

	//---------------------------------------------------------

	const [dialog, setDialog] = useState(false);
	const [dialogIndex, setDialogIndex] = useState(-1);

	const openDialog = (index) => {
		setDialog(vendors[vendorsActivePage - 1][index]);
		setDialogIndex(index);
	};

	const closeDialog = () => {
		setDialog(false);
		setDialogIndex(-1);
	};

	const previousDialog = () => {
		let index = dialogIndex - 1;
		if (index < 0) return;
		setDialog(vendors[vendorsActivePage - 1][index]);
		setDialogIndex(index);
	};

	const nextDialog = () => {
		let index = dialogIndex + 1;
		if (index > vendors[vendorsActivePage - 1].length) return;
		setDialog(vendors[vendorsActivePage - 1][index]);
		setDialogIndex(index);
	};

	//---------------------------------------------------------

	// const getRemainingDays = (endingDate) => {
	//     const date1 = new Date();
	//     const date2 = new Date(endingDate);
	//     const diffTime = Math.abs(date2 - date1);
	//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	//     if ((date2 - date1).toString()[0] == '-') {
	//         return {
	//             className: 'warning-container strong',
	//             label: `Vendor's validity expired ${diffDays - 1} days ago.`,
	//         };
	//     }

	//     if (diffDays < 1) {
	//         return {
	//             className: 'warning-container strong',
	//             label: `Vendor's validity expired.`,
	//         };
	//     }

	//     if (diffDays < 15) {
	//         return {
	//             className: 'warning-container strong',
	//             label: `${diffDays} days remaining.`,
	//         };
	//     }

	//     return {
	//         className: 'warning-container',
	//         label: `${diffDays} days remaining.`,
	//     };
	// }

	const disableVendor = async (vendorId, vendorName, isEmployee) => {
		if (!isEmployee) {
			let accessToken = JSON.parse(
				localStorage.getItem('admin')
			).accessToken;

			if (
				window.confirm(
					`Are you sure you want to deactivate this vendor with name '${vendorName}'? This process is non-reversal.`
				)
			) {
				// if (
				//     window.prompt(
				//         `Enter vendor name '${vendorName}' to deactivate.`
				//     ) !== vendorName
				// ) {
				//     alert('Wrong input. Vendor not deactivated');
				//     return;
				// }

				try {
					setNotFound(true);
					setBuffer(true);
					let data = {
						updatedBy: 'admins',
						updaterId: JSON.parse(localStorage.getItem('admin')).id,
					};
					const res = await axios.put(
						`${userBaseUrl}/vendors/disable/${vendorId}`,
						data,
						{
							headers: {
								'access-token': accessToken,
							},
						}
					);

					if (!auth(res.data.message)) return;

					if (res.data) {
						getVendors();
						setNotFound(false);

						socket.emit('updateVendors', {
							message: 'updateVendors',
						});
					} else {
						setNotFound(true);
					}
					setBuffer(false);
				} catch (err) {
					console.log(err);
				}
			}
		} else {
			let accessToken = JSON.parse(
				localStorage.getItem('employee')
			).accessToken;

			if (
				window.confirm(
					`Are you sure you want to deactivate this vendor with name '${vendorName}'? This process is non-reversal.`
				)
			) {
				try {
					setNotFound(true);
					setBuffer(true);
					let data = {
						updatedBy: 'employees',
						updaterId: JSON.parse(localStorage.getItem('employee'))
							.id,
					};
					const res = await axios.put(
						`${userBaseUrl}/vendors/disable/${vendorId}`,
						data,
						{
							headers: {
								'access-token': accessToken,
							},
						}
					);

					if (!auth(res.data.message)) return;

					if (res.data) {
						getVendors();
						setNotFound(false);

						socket.emit('updateVendors', {
							message: 'updateVendors',
						});
					} else {
						setNotFound(true);
					}
					setBuffer(false);
				} catch (err) {
					console.log(err);
				}
			}
		}
	};

	// pagination
	// const getPageNumber = (activePageNumber) => {
	//     setActivePage(activePageNumber);
	// }

	const getPageNumberVendors = (number) => {
		setVendorsActivePage(number);
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	// const generateAndDownloadIDCard = async (vendor) => {
	//     closeDialog();
	//     setLoader(true);

	//     axios.get(`${baseUrl}/generate-vendor-pdf/${vendor.id}`, { responseType: 'blob' })
	//         .then((res) => {
	//             if (res.data.message == false) {
	//                 alert("Can't generate ID card. Vendor may be not activated or disabled.");
	//                 return;
	//             }

	//             const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

	//             saveAs(pdfBlob, `${vendor.fullname} (${vendor.unit}).pdf`);
	//             setLoader(false);
	//         });
	// }

	// const totalCount = () => {
	// 	let count = 0;
	// 	for (let i = 0; i < vendors.length; i++) {
	// 		count = count + vendors[i].length;
	// 	}
	// 	return count;
	// };

	const getVendorsRenewalAvailability = async () => {
		try {
			const { ownerId } = state;

			const res = await axios.get(
				`${userBaseUrl}/vendors/renewal/availability/${ownerId}`,
				{
					headers: {
						'access-token': accessToken,
					},
				}
			);

			if (!auth(res.data.message)) return;

			if (res.data) {
				setRenewalAvailable(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (currentScreen === '/owners/vendors') {
			getVendorsRenewalAvailability();
		}

		document.addEventListener('keyup', (e) => {
			if (e.key !== '/') return;
			let searchInput = document.getElementById('search-input');
			searchInput.focus();
		});
	}, []);

	// useEffect(() => {
	// 	if (renewalAvailable.isVendorsRenewed) {
	// 		const visibleVendors = [];

	// 		if (toggle == 0) {
	// 			for (let i = 0; i < rawVendors.length; i++) {
	// 				if (
	// 					new Date(rawVendors[i].validUpto).getFullYear() >
	// 					new Date().getFullYear()
	// 				) {
	// 					visibleVendors.push(rawVendors[i]);
	// 				}
	// 			}
	// 		} else {
	// 			for (let i = 0; i < rawVendors.length; i++) {
	// 				if (
	// 					new Date(rawVendors[i].validUpto).getFullYear() <=
	// 					new Date().getFullYear()
	// 				) {
	// 					visibleVendors.push(rawVendors[i]);
	// 				}
	// 			}
	// 		}

	// 		let mainArray = [];

	// 		let n = 0;
	// 		for (
	// 			let i = 0;
	// 			i < Math.ceil(visibleVendors.length / vendorsItemsPerPage);
	// 			i++
	// 		) {
	// 			mainArray.push([]);
	// 			for (let a = 0; a < vendorsItemsPerPage; a++) {
	// 				if (visibleVendors[n] != undefined) {
	// 					mainArray[i].push(visibleVendors[n]);
	// 					n++;
	// 				}
	// 			}
	// 		}
	// 		setVendors(mainArray);
	// 	}
	// }, [toggle]);

	const renewVendors = async () => {
		const value = window.confirm(
			`Previously active vendors will be renewed.`
		);

		if (value) {
			try {
				const { ownerId } = state;

				const res = await axios.post(
					`${userBaseUrl}/vendors/renew`,
					{
						ownerId: ownerId,
						adminId: JSON.parse(localStorage.getItem('admin')).id,
					},
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					getVendorsRenewalAvailability();
					getVendors();
					socket.emit('updateVendors', {
						message: 'updateVendors',
					});
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Navigation />
				<div className='side-container'>
					{buffer && notFound ? <Buffer /> : <></>}

					{vendors.length === 0 && !buffer ? (
						<Fallback
							title='Not found'
							text='No data found in the database. Try refreshing the page.'
						/>
					) : (
						<></>
					)}

					{vendors.length !== 0 && !notFound && !buffer ? (
						<>
							<div className='screen-title headline-large'>
								{owner ? `Vendors (${owner})` : `Vendors`}
							</div>
							{currentScreen === '/owners/vendors' && (
								<div className='active-vendors-counts-container'>
									<div className='active-vendors-counts'>
										<div className='value headline-large'>
											{
												vendorAddStatus.added
													.stallTrolleyVendorCount
											}
											<span>
												/
												{
													vendorAddStatus.total
														.stallTrolleyVendorCount
												}
											</span>
										</div>
										<div className='title title-small'>
											Number of staff
										</div>
									</div>
									<div className='active-vendors-counts'>
										<div className='value headline-large'>
											{
												vendorAddStatus.added
													.pfVendorCount
											}
											<span>
												/
												{
													vendorAddStatus.total
														.pfVendorCount
												}
											</span>
										</div>
										<div className='title title-small'>
											PF vendor
										</div>
									</div>
									<div className='active-vendors-counts'>
										<div className='value headline-large'>
											{
												vendorAddStatus.added
													.supportingStaff
											}
											<span>
												/
												{
													vendorAddStatus.total
														.supportingStaff
												}
											</span>
										</div>
										<div className='title title-small'>
											Supporting staff
										</div>
									</div>
									<div className='active-vendors-counts total'>
										<div className='value headline-large'>
											{vendorAddStatus.added
												.stallTrolleyVendorCount +
												vendorAddStatus.added
													.pfVendorCount +
												vendorAddStatus.added
													.supportingStaff}
											<span>
												/
												{vendorAddStatus.total
													.stallTrolleyVendorCount +
													vendorAddStatus.total
														.pfVendorCount +
													vendorAddStatus.total
														.supportingStaff}
											</span>
										</div>
										<div className='title title-small'>
											Total vendors
										</div>
									</div>
								</div>
							)}
							{/* {currentScreen !== '/owners/vendors' && ( */}
							<div className='search-container'>
								<input
									type='text'
									className='body-medium'
									id='search-input'
									name='search'
									onChange={handleChange}
									placeholder='Search here (press "/" to focus)'
									onKeyUp={(event) => enterClick(event)}
									defaultValue={searchText.search}
								/>
								<button
									disabled={isRefreshing}
									className={
										isRefreshing
											? 'icon-button refreshing'
											: 'icon-button'
									}
									onClick={() => {
										searchTextField = '';
										setSearchText((prev) => ({
											...prev,
											search: '',
										}));

										document.getElementById(
											'search-input'
										).value = '';

										getVendors();
									}}
									title='Reload'
								>
									{fetchComplete ? (
										<CheckCircle />
									) : (
										<Refresh />
									)}
								</button>
								<button
									type='button'
									id='on-enter'
									className='filled-tonal-button'
									onClick={search}
								>
									Search
								</button>
							</div>
							{/* )} */}

							{(localStorage.getItem('admin') != null ||
								localStorage.getItem('admin') != '') &&
								(localStorage.getItem('employee') == null ||
									localStorage.getItem('employee') == '') &&
								currentScreen === '/owners/vendors' && (
									<>
										{renewalAvailable.isAvailable && (
											<>
												{!renewalAvailable.isVendorsRenewed ? (
													<div className='renew-button-container'>
														<button
															className='renew-button label-large'
															onClick={
																renewVendors
															}
														>
															Renew all vendors
														</button>
													</div>
												) : (
													<></>
													// <div className='vendors-toggle'>
													// 	<button
													// 		className={`toggle label-large ${
													// 			toggle === 0 &&
													// 			'active'
													// 		}`}
													// 		onClick={() => {
													// 			setToggle(0);
													// 		}}
													// 	>
													// 		Renewed vendors
													// 	</button>
													// 	<button
													// 		className={`toggle label-large ${
													// 			toggle === 1 &&
													// 			'active'
													// 		}`}
													// 		onClick={() => {
													// 			setToggle(1);
													// 		}}
													// 	>
													// 		Expiring vendors
													// 	</button>
													// </div>
												)}
											</>
										)}
									</>
								)}

							<div className='table-container center'>
								<table>
									<thead>
										<tr>
											<th className='left-box'>
												<div className='table-head'>
													<div className='table-title'>
														Sr.&nbsp;No.
													</div>
												</div>
											</th>
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Photo
													</div>
												</div>
											</th>
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Photo</div>
                                                    </div>
                                                </th> */}

											<th>
												<div className='table-head'>
													<div className='table-title'>
														Full&nbsp;name
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'fullname',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'fullname',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Vendor Type</div>
                                                    </div>
                                                </th> */}

											{/* <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Aadhaar&nbsp;number</div>
                                                    {
                                                        currentScreen !== '/owners/vendors' &&
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'aadhaar', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'aadhaar', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    }
                                                </div>
                                            </th>

                                            <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Id&nbsp;number</div>
                                                    {
                                                        currentScreen !== '/owners/vendors' &&
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'idNumber', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'idNumber', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    }
                                                </div>
                                            </th>

                                            <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Designation</div>
                                                </div>
                                            </th>

                                            <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Unit</div>
                                                </div>
                                            </th>

                                            <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Date&nbsp;of&nbsp;issue</div>
                                                    {
                                                        currentScreen !== '/owners/vendors' &&
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'issueDate', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'issueDate', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    }
                                                </div>
                                            </th>

                                            <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Valid&nbsp;upto</div>
                                                    {
                                                        currentScreen !== '/owners/vendors' &&
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'validUpto', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'validUpto', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    }
                                                </div>
                                            </th>

                                            <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Valid&nbsp;for</div>
                                                </div>
                                            </th> */}

											{/* <th>
                                                <div className='table-head'>
                                                    <div className='table-title'>Owner&nbsp;Id</div>
                                                    {
                                                        currentScreen !== '/owners/vendors' &&
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'ownerId', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('vendors', 'ownerId', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    }
                                                </div>
                                            </th> */}

											{/* <th>
												<div className='table-head'>
													<div className='table-title'>
														Firm&nbsp;name
													</div>
													{(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'unit',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'unit',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)}
												</div>
											</th> */}

											<th>
												<div className='table-head'>
													<div className='table-title'>
														Designation
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'desigination',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'desigination',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>
														Id No.
													</div>
												</div>
											</th>

											{/* <th>
												<div className='table-head'>
													<div className='table-title'>
														Station
													</div>
													{(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'station',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'station',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)}
												</div>
											</th> */}

											<th>
												<div className='table-head'>
													<div className='table-title'>
														SM
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'stMaster',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'stMaster',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>
														CSCI
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'ci',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'ci',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>
														IPF
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'ipf',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'ipf',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>
														Sr.DCM
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'srdcm',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'srdcm',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											{/* {
                                                    (localStorage.getItem('admin') != null || localStorage.getItem('admin') != '')
                                                    && (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')
                                                    && */}
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Status
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') &&
														currentScreen !==
															'/owners/vendors' && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'isDisabled',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'vendors',
																			'isDisabled',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>
											{/* } */}

											<th className='right-box'>
												<div className='table-head'>
													<div className='table-title'>
														View
													</div>
												</div>
											</th>
										</tr>
									</thead>
									{vendors[vendorsActivePage - 1].map(
										(vendor, index) => {
											let className =
												vendor.isDisabled == '1'
													? 'disabled-row'
													: '';
											let dotClassName = 'dot';

											if (
												vendor.stMaster != 0 &&
												vendor.ci != 0 &&
												vendor.ipf != 0 &&
												vendor.srdcm != 0 &&
												vendor.isDisabled == '0'
											) {
												className = 'success-row';
												dotClassName = 'dot success';
											}

											const isRenewed =
												new Date().getFullYear() <
												new Date(
													vendor.validUpto
												).getFullYear();

											className =
												className +
												(isRenewed
													? ' renewed-vendor'
													: '');

											return (
												<tbody
													// key={`vendor-${vendor.id}`}
													key={Math.random()}
													title={`${vendor.fullname} (${vendor.station})`}
												>
													<tr className='gap'></tr>
													<tr className={className}>
														<td
															className='center left-box'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															<div
																className={
																	dotClassName
																}
															></div>
															{(vendorsActivePage -
																1) *
																vendorsItemsPerPage +
																(index + 1)}
														</td>
														{/* <td className='img hover' >{vendor.photo && <img src={vendor.photo} />}</td> */}
														<td
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															<div className='table-profile-photo'>
																<img
																	src={
																		vendor.photo
																	}
																/>
															</div>
														</td>
														<td
															className='hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{vendor.fullname ||
																'Unknown'}{' '}
															{vendor.addedByAdmin !=
															0 ? (
																<div className='inline-chip'>
																	(Added by
																	Admin)
																</div>
															) : (
																''
															)}
															{isRenewed ? (
																<div className='inline-chip'>
																	(RENEWED)
																</div>
															) : (
																''
															)}
														</td>
														{/* <td
															className='hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{vendor.unit ||
																'Unknown'}
														</td> */}
														<td
															className='hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{vendor.desigination ||
																'Unknown'}
														</td>
														<td
															className='hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{vendor.idNumber ||
																'Unknown'}
														</td>
														{/* <td
															className='hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{vendor.station ||
																'Unknown'}
														</td> */}
														{/* <td>{vendor.vendorType || 'Unknown'}</td> */}
														{/* <td>{vendor.aadhaar || 'Unknown'}</td>
                                                            <td>{vendor.idNumber || 'Unknown'}</td>
                                                            <td>{vendor.desigination || 'Unknown'}</td>
                                                            <td>{vendor.unit || 'Unknown'}</td>
                                                            <td>{vendor.issueDate || 'Unknown'}</td>
                                                            <td>{vendor.validUpto || 'Unknown'}</td>
                                                            <td>{vendor.validFor || 'Unknown'}</td> */}
														{/* <td>{vendor.ownerId || 'Unknown'}</td> */}
														<td
															onClick={() => {
																openDialog(
																	index
																);
															}}
															className={
																vendor.stMaster !=
																0
																	? 'success-background center'
																	: 'error-background center'
															}
														>
															{vendor.stMaster !=
															0 ? (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			true
																		}
																	/>
																</div>
															) : (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			false
																		}
																	/>
																</div>
															)}
														</td>
														<td
															onClick={() => {
																openDialog(
																	index
																);
															}}
															className={
																vendor.ci != 0
																	? 'success-background center'
																	: 'error-background center'
															}
														>
															{vendor.ci != 0 ? (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			true
																		}
																	/>
																</div>
															) : (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			false
																		}
																	/>
																</div>
															)}
														</td>
														<td
															onClick={() => {
																openDialog(
																	index
																);
															}}
															className={
																vendor.ipf != 0
																	? 'success-background center'
																	: 'error-background center'
															}
														>
															{vendor.ipf != 0 ? (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			true
																		}
																	/>
																</div>
															) : (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			false
																		}
																	/>
																</div>
															)}
														</td>
														<td
															onClick={() => {
																openDialog(
																	index
																);
															}}
															className={
																vendor.srdcm !=
																0
																	? 'success-background center'
																	: 'error-background center'
															}
														>
															{vendor.srdcm !=
															0 ? (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			true
																		}
																	/>
																</div>
															) : (
																<div className='approval-status'>
																	<CheckUncheck
																		isChecked={
																			false
																		}
																	/>
																</div>
															)}
														</td>

														{/* if admin */}
														{(localStorage.getItem(
															'admin'
														) != null ||
															localStorage.getItem(
																'admin'
															) != '') &&
															(localStorage.getItem(
																'employee'
															) == null ||
																localStorage.getItem(
																	'employee'
																) == '') && (
																<td
																	className={`center ${
																		vendor.isDisabled ==
																			0 &&
																		'hover'
																	}`}
																	onClick={() => {
																		if (
																			vendor.isDisabled ==
																			'0'
																		) {
																			disableVendor(
																				vendor.id,
																				vendor.fullname,
																				false
																			);
																		}
																	}}
																>
																	{vendor.isDisabled ==
																	0 ? (
																		<button className='text-button'>
																			Deactivate
																		</button>
																	) : (
																		'Deactivated'
																	)}
																</td>
															)}

														{/* if employee */}
														{(localStorage.getItem(
															'admin'
														) == null ||
															localStorage.getItem(
																'admin'
															) == '') &&
															(localStorage.getItem(
																'employee'
															) != null ||
																localStorage.getItem(
																	'employee'
																) != '') && (
																<td
																	className='hover center'
																	onClick={() => {
																		if (
																			vendor.isDisabled ==
																			'0'
																		) {
																			disableVendor(
																				vendor.id,
																				vendor.fullname,
																				true
																			);
																		}
																	}}
																>
																	{vendor.isDisabled ==
																	0 ? (
																		<button className='text-button'>
																			Deactivate
																		</button>
																	) : (
																		'Deactivated'
																	)}
																</td>
															)}

														<td
															className='hover right-box'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															<button className='text-button'>
																View
															</button>
														</td>
													</tr>
												</tbody>
											);
										}
									)}
								</table>
							</div>
							<Pagination
								getTotalItems={vendorsTotalItems}
								getItemsPerPage={vendorsItemsPerPage}
								getPageNumber={getPageNumberVendors}
								activePage={vendorsActivePage}
							/>
							{/* <Pagination getTotalItems={totalItems} getItemsPerPage={itemsPerPage} getPageNumber={getPageNumber} activePage={activePage} /> */}
						</>
					) : (
						<></>
					)}

					<Footer />
				</div>
			</div>

			{dialog && (
				<div className='dialog-container'>
					<button
						className='dialog-previous'
						onClick={previousDialog}
						disabled={dialogIndex === 0 ? true : false}
					>
						<ExpandMore />
					</button>

					<div
						open
						className='dialog'
					>
						<div
							className='dialog-top'
							style={{
								backgroundColor:
									dialog.isDisabled == '1'
										? 'var(--error-container)'
										: 'var(--surface)',
							}}
						>
							<div className='title title-large'>
								{(vendorsActivePage - 1) * vendorsItemsPerPage +
									(dialogIndex + 1)}
								. Vendor details
								{dialog.isDisabled === '1' ? ' (INACTIVE)' : ''}
							</div>
							<button
								className='icon-button'
								onClick={closeDialog}
							>
								<Clear />
							</button>
						</div>

						<div className='dialog-content'>
							<div className='container-1 qr-image-container'>
								<div className='profile-image'>
									<img
										className='img'
										src={dialog.photo}
										alt=''
									/>
								</div>
								{(localStorage.getItem('admin') != null ||
									localStorage.getItem('admin') != '') &&
									(localStorage.getItem('employee') == null ||
										localStorage.getItem('employee') ==
											'') &&
									JSON.parse(localStorage.getItem('admin'))
										.superAdmin == 0 && (
										<>
											<img
												className='qr-image'
												src={dialog.qr}
												alt=''
											/>
											{/* <a href={dialog.qr} className='filled-button label-large download-qr-button' download={dialog.fullname}>Download</a> */}
										</>
									)}
							</div>

							{(localStorage.getItem('admin') != null ||
								localStorage.getItem('admin') != '') &&
								(localStorage.getItem('employee') == null ||
									localStorage.getItem('employee') == '') &&
								JSON.parse(localStorage.getItem('admin'))
									.superAdmin == 0 &&
								JSON.parse(localStorage.getItem('admin'))
									.permissions === 'f3d0a2a4' && (
									<>
										<div className='dialog-buttons-container'>
											{/* {
                                            dialog.stMaster != 0 && dialog.ci != 0 && dialog.ipf != 0 && dialog.srdcm != 0 && dialog.isDisabled == 0 &&
                                            <button className='filled-button' onClick={() => generateAndDownloadIDCard(dialog)}>Download ID card</button>
                                        } */}
											<a
												href={dialog.qr}
												download={`${dialog.fullname} (${dialog.unit}) QR`}
											>
												<button className='filled-tonal-button'>
													Download QR
												</button>
											</a>
										</div>
									</>
								)}

							<div className='label label-medium'>
								Permission letter
							</div>

							<a
								rel='noopener noreferrer'
								href={dialog.pdf}
								target='_blank'
							>
								<button
									className='outlined-button'
									style={{
										width: 'calc(50% - (var(--space) / 2))',
									}}
								>
									{dialog.pdf
										? 'Click to view permission letter'
										: 'Permission letter not found'}
								</button>
							</a>

							<div
								className={
									calculateDateStatus(dialog.validUpto)
										.className
								}
							>
								{calculateDateStatus(dialog.validUpto).message}
							</div>

							<div className='container-3'>
								<div className='label label-medium'>
									Full name
								</div>
								<div className='full-name title-large'>
									{dialog.fullname}
								</div>

								<div className='label label-medium'>
									Aadhaar number
								</div>
								<div className='id-number body-large'>
									{dialog.aadhaar}
								</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Id number
										</div>
										<div className='aadhaar-id body-large'>
											{dialog.idNumber}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Designation
										</div>
										<div className='valid-upto-date body-large'>
											{dialog.desigination}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Unit
										</div>
										<div className='valid-for body-large'>
											{dialog.unit}
										</div>
									</div>
								</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Date of issue
										</div>
										<div className='designation body-large'>
											{`${new Date(
												dialog.issueDate
											).getDate()}-${
												new Date(
													dialog.issueDate
												).getMonth() + 1
											}-${new Date(
												dialog.issueDate
											).getFullYear()}`}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Valid upto
										</div>
										<div className='unit body-large'>
											{`${new Date(
												dialog.validUpto
											).getDate()}-${
												new Date(
													dialog.validUpto
												).getMonth() + 1
											}-${new Date(
												dialog.validUpto
											).getFullYear()}`}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Valid for platform
										</div>
										<div className='issue-date body-large'>
											{dialog.validFor}
										</div>
									</div>
								</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Station
										</div>
										<div className='designation body-large'>
											{dialog.station
												? dialog.station
												: '--'}
										</div>
									</div>

									{/* <div>
                                        <div className='label label-medium'>Vendor Type</div>
                                        <div className='designation body-large'>{dialog.vendorType ? dialog.vendorType : '--'}</div>
                                    </div> */}

									<div>
										<div className='label label-medium'></div>
										<div className='designation body-large'></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<button
						className='dialog-next'
						onClick={nextDialog}
						disabled={
							dialogIndex ===
							vendors[vendorsActivePage - 1].length - 1
								? true
								: false
						}
					>
						<ExpandLess />
					</button>
				</div>
			)}

			{loader && <Loading />}
		</>
	);
};

export default Vendors;

//------------------------------------------------------------------------------------
