//------------------------------------------------------------------------------------

const baseUrl = 'https://admin.backend.bslcatgvid.in';
export const userBaseUrl = 'https://user.backend.bslcatgvid.in';

// const baseUrl = 'http://localhost:1001';
// export const userBaseUrl = 'http://localhost:1000';

export default baseUrl;

//------------------------------------------------------------------------------------
