//------------------------------------------------------------------------------------

// import axios from 'axios';
// import baseUrl from './baseUrl';

//------------------------------------------------------------------------------------

const checkLoginStatus = async () => {
    /* 
    
            &&
            (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '') */


    if (
        (localStorage.getItem('admin') == null || localStorage.getItem('admin') == '')
        &&
        (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')
    ) {
        localStorage.clear();
    } else {
        window.location.replace('/dashboard')
        return;
    }

    // if (localStorage.getItem('admin') == null || localStorage.getItem('employee') == null) {
    //     localStorage.setItem('admin', '');
    //     localStorage.setItem('employee', '');
    // }

    // if (
    //     (localStorage.getItem('admin') == '' || localStorage.getItem('admin') == null)
    //     && (localStorage.getItem('employee') == '' || localStorage.getItem('employee') == null)
    // ) return false;

    // if (
    //     (localStorage.getItem('admin') != null || localStorage.getItem('admin') != '')
    //     && (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')
    // ) {
    //     let admin = JSON.parse(localStorage.getItem('admin'));
    //     try {
    //         const res = await axios.get(`${baseUrl}/login/status/${admin.id}`);
    //         if (res.data) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    // if (
    //     (localStorage.getItem('admin') == null || localStorage.getItem('admin') == '')
    //     && (localStorage.getItem('employee') != null || localStorage.getItem('employee') != '')
    // ) {
    //     return true;
    // }
}

export default checkLoginStatus;

//------------------------------------------------------------------------------------
