//------------------------------------------------------------------------------------

import { useEffect, useState } from 'react';
import './OnlineStatus.scss';

//------------------------------------------------------------------------------------

const OnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(true);
    const [isOnlineClass, setisOnlineClass] = useState(false);

    useEffect(() => {
        window.addEventListener("offline", () => {
            setIsOnline(false);
        });

        window.addEventListener("online", () => {
            setisOnlineClass(true);

            setTimeout(() => {
                let element = document.getElementById('online-status');
                element.style.animation = 'slide-down 300ms ease';

                setTimeout(() => {
                    setIsOnline(true);
                    element.style.animation = 'slide-up 300ms ease';
                    setisOnlineClass(false);
                }, 280);
            }, 2000);
        });
    }, []);

    return (
        <>
            {
                !isOnline &&
                <div id='online-status' className={'online-status label-medium ' + (isOnlineClass ? 'online' : 'offline')}>
                    {
                        isOnlineClass ? 'You are online' : 'You are offline'
                    }
                </div>
            }
        </>

    )
}

export default OnlineStatus;

//------------------------------------------------------------------------------------