//------------------------------------------------------------------------------------

const html = document.getElementsByTagName('html')[0];
const body = document.getElementsByTagName('body')[0];
const themeColor = document.getElementsByName('theme-color')[0];

//------------------------------------------------------------------------------------

const theme = {
	colors: {
		light: () => {
			html.classList.remove('dark-theme');
			html.classList.add('light-theme');
			themeColor.setAttribute('content', '#f1f3fc');
			localStorage.setItem('theme', 'light');
		},
		dark: () => {
			html.classList.remove('light-theme');
			html.classList.add('dark-theme');
			themeColor.setAttribute('content', '#2a2c36');
			localStorage.setItem('theme', 'dark');
		},
	},
	set: () => {
		if (localStorage.getItem('theme') === null) {
			localStorage.setItem('theme', 'light');
		}

		switch (localStorage.getItem('theme')) {
			case 'light':
				theme.colors.light();
				break;
			case 'dark':
				theme.colors.dark();
				break;
		}

		body.style.display = 'block';
	},
	toggle: () => {
		switch (localStorage.getItem('theme')) {
			case 'light':
				theme.colors.dark();
				break;
			case 'dark':
				theme.colors.light();
				break;
		}
	},
};

export default theme;

//------------------------------------------------------------------------------------
