//------------------------------------------------------------------------------------

import axios from 'axios';
import baseUrl from './baseUrl';
import auth from './auth';

//------------------------------------------------------------------------------------

const sortTable = async (table, column, order) => {
    const data = {
        table: table,
        column: column,
        order: order,
    };

    let accessToken = (localStorage.getItem('admin') == null || localStorage.getItem('admin') == '')
        && (localStorage.getItem('employee') != null || localStorage.getItem('employee') != '')
        ? JSON.parse(localStorage.getItem('employee')).accessToken
        : JSON.parse(localStorage.getItem('admin')).accessToken;

    try {
        const res = await axios.post(`${baseUrl}/sort`, data, {
            headers: {
                'access-token': accessToken
            }
        });

        if (!auth(res.data.message)) return;

        if (res.data) {
            return res.data;
        }
    } catch (err) {
        console.log(err);
    }
}

export default sortTable;

//------------------------------------------------------------------------------------
