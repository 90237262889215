//------------------------------------------------------------------------------------

import './Dashboard.scss';
import '../internal.scss';

import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import Navigation from '../../../components/Navigation/Navigation';
import auth from '../../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import filterOwnersByStation from '../../../scripts/filterOwnersByStation';
import filterVendorsByStation from '../../../scripts/filterVendorsByStation';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { socket } from '../../../App';
import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

//------------------------------------------------------------------------------------

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const Dashboard = () => {
    unauthorizedRedirection();
    document.title = `Dashboard • Bhusawal Division Catering Vendor Id`;

    const [owners, setOwners] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [employeesCount, setEmployeesCount] = useState('0');

    const [admin, setAdmin] = useState({
        fullname: '',
        mobile: '',
        email: '',
    });

    const [employee, setEmployee] = useState({
        fullname: '',
        mobile: '',
        designation: '',
        station: '',
        active: '',
    });

    const [notFound, setNotFound] = useState(true);
    const [buffer, setBuffer] = useState(true);
    // const [loader, setLoader] = useState(false);

    let accessToken =
        (localStorage.getItem('admin') == null ||
            localStorage.getItem('admin') == '') &&
        (localStorage.getItem('employee') != null ||
            localStorage.getItem('employee') != '')
            ? JSON.parse(localStorage.getItem('employee')).accessToken
            : JSON.parse(localStorage.getItem('admin')).accessToken;

    const getOwners = async () => {
        try {
            const res = await axios.get(`${baseUrl}/owners`, {
                headers: {
                    'access-token': accessToken,
                },
            });

            if (!auth(res.data.message)) return;

            let ownersArray =
                (localStorage.getItem('admin') == null ||
                    localStorage.getItem('admin') == '') &&
                (localStorage.getItem('employee') != null ||
                    localStorage.getItem('employee') != '')
                    ? filterOwnersByStation(res.data)
                    : res.data;

            if (res.data) {
                setOwners([...ownersArray]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getVendors = async () => {
        try {
            const res = await axios.get(`${baseUrl}/vendors`, {
                headers: {
                    'access-token': accessToken,
                },
            });

            if (!auth(res.data.message)) return;

            let vendorsArray =
                (localStorage.getItem('admin') == null ||
                    localStorage.getItem('admin') == '') &&
                (localStorage.getItem('employee') != null ||
                    localStorage.getItem('employee') != '')
                    ? filterVendorsByStation(res.data)
                    : res.data;

            if (res.data) {
                setVendors(vendorsArray);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getEmployeesCount = async () => {
        try {
            const res = await axios.get(`${baseUrl}/employees`, {
                headers: {
                    'access-token': accessToken,
                },
            });

            if (!auth(res.data.message)) return;

            if (res.data) {
                setEmployeesCount(res.data.length);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAdmin = async () => {
        const admin = JSON.parse(localStorage.getItem('admin'));
        try {
            const res = await axios.get(`${baseUrl}/admins/${admin.id}`, {
                headers: {
                    'access-token': accessToken,
                },
            });

            if (!auth(res.data.message)) return;

            if (res.data) {
                setAdmin(res.data);
                setNotFound(false);
            } else {
                setNotFound(true);
            }
            setBuffer(false);
        } catch (err) {
            console.log(err);
        }
    };

    const getEmployee = async () => {
        const employee = JSON.parse(localStorage.getItem('employee'));
        try {
            const res = await axios.get(
                `${baseUrl}/employee/specific/${employee.id}`
            );
            if (res.data) {
                setEmployee({ ...res.data[0] });
                setNotFound(false);
            } else {
                setNotFound(true);
            }
            setBuffer(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getOwners();
        getVendors();
        getEmployeesCount();

        // const interval = setInterval(() => {
        // 	getOwners();
        // 	getVendors();
        // 	getEmployeesCount();
        // }, 1000);

        socket.on('isOwnerUpdated', (data) => {
            getOwners();
        });

        socket.on('isVendorUpdated', (data) => {
            getVendors();
        });

        if (
            (localStorage.getItem('admin') != null ||
                localStorage.getItem('admin') != '') &&
            (localStorage.getItem('employee') == null ||
                localStorage.getItem('employee') == '')
        ) {
            getAdmin();
        }
        if (
            (localStorage.getItem('admin') == null ||
                localStorage.getItem('admin') == '') &&
            (localStorage.getItem('employee') != null ||
                localStorage.getItem('employee') != '')
        ) {
            getEmployee();
        }

        // return () => {
        // 	clearInterval(interval);
        // };
    }, []);

    const getOwnersCount = () => {
        let count = 0;
        for (const owner of owners) {
            if (owner.isDeleted == 0 && owner.isDisabled == 0) {
                count++;
            }
        }
        return count;
    };

    const getVendorsCount = () => {
        let count = 0;
        for (const vendor of vendors) {
            if (vendor.isDisabled == 0) {
                count++;
            }
        }
        return count;
    };

    const getVendorsAllocationCount = () => {
        let count = 0;
        for (const owner of owners) {
            if (owner.isDeleted == 0 && owner.isDisabled == 0) {
                count = count + owner.vendorsCount;
            }
        }
        return count;
    };

    const getActiveVendorsCount = () => {
        let count = 0;
        for (const vendor of vendors) {
            if (
                vendor.stMaster != 0 &&
                vendor.ci != 0 &&
                vendor.ipf != 0 &&
                vendor.srdcm != 0 &&
                vendor.isDisabled == 0
            ) {
                count++;
            }
        }
        return count;
    };

    const getDisabledVendorsCount = () => {
        let count = 0;
        for (const vendor of vendors) {
            if (vendor.isDisabled == 1) {
                count++;
            }
        }
        return count;
    };

    let dashboardClassname =
        (localStorage.getItem('admin') == null ||
            localStorage.getItem('admin') == '') &&
        (localStorage.getItem('employee') != null ||
            localStorage.getItem('employee') != '')
            ? 'dashboard-container employees'
            : 'dashboard-container';

    const getApprovedVendorsCount = (approvedBy) => {
        let count = 0;

        switch (approvedBy) {
            case 'SM':
                for (const vendor of vendors) {
                    if (vendor.stMaster != 0) {
                        count++;
                    }
                }
                return count;
            case 'CSCI':
                for (const vendor of vendors) {
                    if (vendor.ci != 0) {
                        count++;
                    }
                }
                return count;
            case 'IPF':
                for (const vendor of vendors) {
                    if (vendor.ipf != 0) {
                        count++;
                    }
                }
                return count;
            case 'SRDCM':
                for (const vendor of vendors) {
                    if (vendor.srdcm != 0) {
                        count++;
                    }
                }
                return count;
        }
    };

    const data = {
        labels: ['Sr. DCM', 'Station Manager', 'CSCI', 'IPF'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 10, 192, 0.5)',
                ],
                borderWidth: 0,
            },
        ],
    };

    return (
        <>
            <Header />
            <div className='screen-container'>
                <Navigation />
                <div className='side-container'>
                    <div className='screen-title headline-large'>Dashboard</div>

                    {buffer && notFound ? <Buffer /> : <></>}

                    {/* {admin.fullname == '' || employee.fullname == '' && !buffer
                        ? <Fallback title='Not found' text='No data found in the database. Try refreshing the page.' />
                        : <></>
                    } */}

                    {admin.fullname != '' ||
                    (employee.fullname != '' && !notFound && !buffer) ? (
                        <>
                            <div className={dashboardClassname}>
                                <Link
                                    to='/owners'
                                    title='View all contractors'
                                >
                                    <div className='elevated-card owners-card'>
                                        <div className='card-count display-medium'>
                                            {getOwnersCount()}
                                        </div>
                                        <div className='card-title title-medium'>
                                            {(localStorage.getItem('admin') ==
                                                null ||
                                                localStorage.getItem('admin') ==
                                                    '') &&
                                            (localStorage.getItem('employee') !=
                                                null ||
                                                localStorage.getItem(
                                                    'employee'
                                                ) != '') ? (
                                                <>
                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'employee'
                                                        )
                                                    ).designation == 'CSCI'
                                                        ? 'Total contractors in this section'
                                                        : `Total contractors in ${
                                                              JSON.parse(
                                                                  localStorage.getItem(
                                                                      'employee'
                                                                  )
                                                              ).station
                                                          } station`}
                                                </>
                                            ) : (
                                                'Total contractors'
                                            )}
                                        </div>
                                    </div>
                                </Link>

                                <Link
                                    to='/vendors'
                                    title='View all vendors'
                                >
                                    <div className='elevated-card vendors-card'>
                                        <div className='card-count display-medium'>
                                            {getVendorsCount()}
                                        </div>
                                        <div className='card-title title-medium'>
                                            {(localStorage.getItem('admin') ==
                                                null ||
                                                localStorage.getItem('admin') ==
                                                    '') &&
                                            (localStorage.getItem('employee') !=
                                                null ||
                                                localStorage.getItem(
                                                    'employee'
                                                ) != '') ? (
                                                <>
                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'employee'
                                                        )
                                                    ).designation == 'CSCI'
                                                        ? 'Total vendors in this section'
                                                        : `Total vendors in ${
                                                              JSON.parse(
                                                                  localStorage.getItem(
                                                                      'employee'
                                                                  )
                                                              ).station
                                                          } station`}
                                                </>
                                            ) : (
                                                'Total vendors'
                                            )}
                                        </div>
                                    </div>
                                </Link>

                                {(localStorage.getItem('admin') != null ||
                                    localStorage.getItem('admin') != '') &&
                                    (localStorage.getItem('employee') == null ||
                                        localStorage.getItem('employee') ==
                                            '') && (
                                        <Link
                                            to='/employees'
                                            title='View all employees'
                                        >
                                            <div className='elevated-card employees-card'>
                                                <div className='card-count display-medium'>
                                                    {employeesCount}
                                                </div>
                                                <div className='card-title title-medium'>
                                                    Total employees
                                                </div>
                                            </div>
                                        </Link>
                                    )}

                                {/* {(localStorage.getItem('admin') != null ||
									localStorage.getItem('admin') != '') &&
									(localStorage.getItem('employee') == null ||
										localStorage.getItem('employee') ==
											'') && ( */}
                                <div className='elevated-card id-completion-card'>
                                    <div className='card-count display-medium'>
                                        {getVendorsCount()}
                                        <span style={{ opacity: '0.4' }}>
                                            /{getVendorsAllocationCount()}
                                        </span>
                                    </div>
                                    <div className='card-title title-medium'>
                                        Ready ID cards
                                    </div>
                                </div>
                                {/* )} */}
                            </div>

                            <div className='vendors-cards-container'>
                                <div className='vendors-card'>
                                    <div className='card-details'>
                                        <div className='vendors-card-value display-small'>
                                            {getActiveVendorsCount()}
                                        </div>
                                        <div className='vendors-card-title title-medium'>
                                            Active vendors
                                            <br />
                                            <span className='label-small'>
                                                (Approved by{' '}
                                                <strong>
                                                    Station Manager, CSCI, IPF
                                                </strong>{' '}
                                                and <strong>Sr. DCM</strong>)
                                            </span>
                                        </div>
                                    </div>
                                    <div className='vertical-border'></div>
                                    <div className='card-details'>
                                        <div className='vendors-card-value display-small'>
                                            {getDisabledVendorsCount()}
                                        </div>
                                        <div className='vendors-card-title title-medium'>
                                            Deactivated vendors
                                        </div>
                                    </div>
                                </div>

                                <div className='vendors-detailed-active-status'>
                                    <div className='card-details'>
                                        <div className='vendors-card-value display-small'>
                                            {getApprovedVendorsCount('SM')}
                                        </div>
                                        <div className='vendors-card-title body-small'>
                                            Vendors approved by{' '}
                                            <strong>Station Manager</strong>s
                                        </div>
                                    </div>
                                    <div className='vertical-border'></div>
                                    <div className='card-details'>
                                        <div className='vendors-card-value display-small'>
                                            {getApprovedVendorsCount('CSCI')}
                                        </div>
                                        <div className='vendors-card-title body-small'>
                                            Vendors approved by{' '}
                                            <strong>CSCI</strong>s
                                        </div>
                                    </div>
                                    <div className='vertical-border'></div>
                                    <div className='card-details'>
                                        <div className='vendors-card-value display-small'>
                                            {getApprovedVendorsCount('IPF')}
                                        </div>
                                        <div className='vendors-card-title body-small'>
                                            Vendors approved by{' '}
                                            <strong>IPF</strong>s
                                        </div>
                                    </div>
                                    <div className='vertical-border'></div>
                                    <div className='card-details'>
                                        <div
                                            className='vendors-card-value display-small'
                                            style={{
                                                color: 'var(--primary)',
                                            }}
                                        >
                                            {getApprovedVendorsCount('SRDCM')}
                                        </div>
                                        <div
                                            className='vendors-card-title body-small'
                                            style={{
                                                color: 'var(--primary)',
                                            }}
                                        >
                                            Vendors approved by{' '}
                                            <strong>Sr. DCM</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='dashboard-content'>
                                {(localStorage.getItem('admin') != null ||
                                    localStorage.getItem('admin') != '') &&
                                    (localStorage.getItem('employee') == null ||
                                        localStorage.getItem('employee') ==
                                            '') && (
                                        <>
                                            <div className='label label-medium'>
                                                Full name
                                            </div>
                                            <div className='title-large'>
                                                {admin.fullname}
                                            </div>

                                            <div className='label label-medium'>
                                                Mobile number
                                            </div>
                                            <div className='body-large'>
                                                +91 {admin.mobile}
                                            </div>

                                            <div className='label label-medium'>
                                                Email
                                            </div>
                                            <div className='body-large'>
                                                {admin.email}
                                            </div>
                                        </>
                                    )}

                                {(localStorage.getItem('admin') == null ||
                                    localStorage.getItem('admin') == '') &&
                                    (localStorage.getItem('employee') != null ||
                                        localStorage.getItem('employee') !=
                                            '') && (
                                        <>
                                            <div className='label label-medium'>
                                                Full name
                                            </div>
                                            <div className='title-large'>
                                                {employee.fullname}
                                            </div>

                                            <div className='label label-medium'>
                                                Mobile number
                                            </div>
                                            <div className='body-large'>
                                                +91 {employee.mobile}
                                            </div>

                                            <div className='label label-medium'>
                                                Designation
                                            </div>
                                            <div className='body-large'>
                                                {employee.designation}
                                            </div>

                                            <div className='label label-medium'>
                                                Station
                                            </div>
                                            <div className='body-large'>
                                                {employee.station}
                                            </div>

                                            <div className='label label-medium'>
                                                Active status
                                            </div>
                                            <div className='body-large'>
                                                {employee.active == 1
                                                    ? 'You can activate vendors'
                                                    : 'You cannot activate vendors'}
                                            </div>
                                        </>
                                    )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Dashboard;

//------------------------------------------------------------------------------------
