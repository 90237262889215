//------------------------------------------------------------------------------------

import './Add.scss';

import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import { Navigate } from 'react-router-dom';
import Navigation from '../../../components/Navigation/Navigation';
import auth from '../../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { useNavigate } from 'react-router-dom';

//------------------------------------------------------------------------------------

const Add = () => {
	unauthorizedRedirection();
	document.title = `Add • Bhusawal Division Catering Vendor Id`;

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const navigate = useNavigate();
	const [employeeDesignations, setEmployeeDesignations] = useState([]);
	const [vendorDesignations, setVendorDesignations] = useState([]);
	const [stations, setStations] = useState([]);
	const [stallTypes, setStallTypes] = useState([]);

	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);

	// Employees designation

	const getEmployeesDesignations = async () => {
		try {
			const res = await axios.get(`${baseUrl}/designations/employees`);
			setEmployeeDesignations(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const addEmployeesDesignations = async () => {
		let value = window.prompt('Enter new designation (Employees)');
		if (value) {
			try {
				const res = await axios.post(
					`${baseUrl}/designations/add`,
					{
						designation: value,
						designationFor: 'employees',
						adminId: JSON.parse(localStorage.getItem('admin')).id,
					},
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					getEmployeesDesignations();
					alert('New designation has been added.');
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const deleteDesignations = async (id, designation) => {
		let value = window.confirm(
			`Are you sure you want to delete '${designation}' designation?`
		);
		if (value) {
			const data = {
				adminId: JSON.parse(localStorage.getItem('admin')).id,
			};

			try {
				const res = await axios.post(
					`${baseUrl}/designations/delete/${id}`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data === true) {
					getEmployeesDesignations();
					getVendorsDesignations();
					alert(`'${designation}' designation has been deleted.`);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	// Vendors designation

	const getVendorsDesignations = async () => {
		try {
			const res = await axios.get(`${baseUrl}/designations/vendors`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			setVendorDesignations(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const addVendorsDesignations = async () => {
		let value = window.prompt('Enter new designation (Vendors)');
		if (value) {
			try {
				const data = {
					designation: value,
					designationFor: 'vendors',
					adminId: JSON.parse(localStorage.getItem('admin')).id,
				};

				const res = await axios.post(
					`${baseUrl}/designations/add`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					getVendorsDesignations();
					alert('New designation has been added.');
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	// Stations

	const getStations = async () => {
		try {
			const res = await axios.get(`${baseUrl}/stations`);
			setStations(res.data);
		} catch (error) {
			console.log(error);
		}

		setNotFound(false);
		setBuffer(false);
	};

	const addStations = async () => {
		let value = window.prompt('Enter new station');
		if (value) {
			const data = {
				station: value,
				adminId: JSON.parse(localStorage.getItem('admin')).id,
			};

			try {
				const res = await axios.post(`${baseUrl}/stations/add`, data, {
					headers: {
						'access-token': accessToken,
					},
				});

				if (!auth(res.data.message)) return;

				if (res.data) {
					getStations();
					alert('New station has been added.');
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const deleteStation = async (id, station) => {
		let value = window.confirm(
			`Are you sure you want to delete '${station}' station?`
		);

		const data = {
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				const res = await axios.post(
					`${baseUrl}/stations/delete/${id}`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data === true) {
					getStations();
					alert(`'${station}' station has been deleted.`);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	// Stall types

	const getStallTypes = async () => {
		try {
			const res = await axios.get(`${baseUrl}/stalltypes`);
			setStallTypes(res.data);
		} catch (error) {
			console.log(error);
		}

		setNotFound(false);
		setBuffer(false);
	};

	const addStallType = async () => {
		let value = window.prompt('Enter new stall type');
		if (value) {
			const data = {
				type: value,
				adminId: JSON.parse(localStorage.getItem('admin')).id,
			};

			try {
				const res = await axios.post(
					`${baseUrl}/stalltypes/add`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					getStallTypes();
					alert('New stall type has been added.');
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const deleteStallType = async (id, type) => {
		let value = window.confirm(
			`Are you sure you want to delete '${type}' type?`
		);

		const data = {
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				const res = await axios.post(
					`${baseUrl}/stalltype/delete/${id}`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data === true) {
					getStallTypes();
					alert(`'${type}' type has been deleted.`);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		// getEmployeesDesignations();

		getVendorsDesignations();
		getStations();
		getStallTypes();

		// const interval = setInterval(() => {
		// 	getVendorsDesignations();
		// 	getStations();
		// }, 1000);

		// return () => {
		// 	clearInterval(interval);
		// };
	}, []);

	const navigateTo = (link) => {
		navigate(link);
	};

	return (
		<>
			{/* {
                JSON.parse(localStorage.getItem('admin')).superAdmin == 0 && */}
			<>
				<Header />
				<div className='screen-container'>
					<Navigation />
					<div className='side-container center'>
						<div className='screen-title headline-large'>Add</div>

						<div className='buttons-container'>
							<button
								className='outlined-button'
								onClick={() => navigateTo('/add/owner')}
							>
								Add new contractor
							</button>
							<button
								className='outlined-button'
								onClick={() => navigateTo('/add/vendor')}
							>
								Add new vendor
							</button>
						</div>

						{buffer && notFound ? <Buffer /> : <></>}

						{!notFound && !buffer ? (
							<>
								{/* <div className='label label-medium'>Designations (Employees)</div>
                                <div className='table-container'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Sr.&nbsp;No.</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Designations</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Actions</div>
                                                    </div>
                                                </th>
                                            </tr>
                                            {
                                                employeeDesignations.map((designation, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{designation.designation}</td>
                                                            <td className='hover' onClick={() => deleteDesignations(designation.id, designation.designation)}>
                                                                <div className='warning label-large'>Delete</div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td className='hover' onClick={addEmployeesDesignations}>
                                                    <div className='success label-large'>Add New</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}

								<div className='label label-medium'>
									Designations (Vendors)
								</div>
								<div className='table-container'>
									<table>
										<thead>
											<tr>
												<th className='left-box'>
													<div className='table-head'>
														<div className='table-title'>
															Sr.&nbsp;No.
														</div>
													</div>
												</th>
												<th>
													<div className='table-head'>
														<div className='table-title'>
															Designations
														</div>
													</div>
												</th>
												<th className='right-box'>
													<div className='table-head'>
														<div className='table-title'>
															Action
														</div>
													</div>
												</th>
											</tr>
										</thead>
										{vendorDesignations.map(
											(designation, index) => {
												return (
													<tbody key={index}>
														<tr className='gap'></tr>
														<tr>
															<td className='center left-box'>
																{index + 1}
															</td>
															<td>
																{
																	designation.designation
																}
															</td>
															<td
																className='hover right-box'
																onClick={() =>
																	deleteDesignations(
																		designation.id,
																		designation.designation
																	)
																}
															>
																<div className='warning label-large'>
																	Delete
																</div>
															</td>
														</tr>
													</tbody>
												);
											}
										)}
										<tbody>
											<tr className='gap'></tr>
											<tr>
												<td className='left-box'></td>
												<td></td>
												<td
													className='hover right-box'
													onClick={
														addVendorsDesignations
													}
												>
													<div className='success label-large'>
														Add New
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className='label label-medium'>
									Stations
								</div>
								<div className='table-container'>
									<table>
										<thead>
											<tr>
												<th className='left-box'>
													<div className='table-head'>
														<div className='table-title'>
															Sr.&nbsp;No.
														</div>
													</div>
												</th>
												<th>
													<div className='table-head'>
														<div className='table-title'>
															Stations
														</div>
													</div>
												</th>
												<th className='right-box'>
													<div className='table-head'>
														<div className='table-title'>
															Action
														</div>
													</div>
												</th>
											</tr>
										</thead>
										{stations.map((station, index) => {
											return (
												<tbody key={index}>
													<tr className='gap'></tr>
													<tr>
														<td className='center left-box'>
															{index + 1}
														</td>
														<td>
															{station.station}
														</td>
														<td
															className='hover right-box'
															onClick={() =>
																deleteStation(
																	station.id,
																	station.station
																)
															}
														>
															<div className='warning label-large'>
																Delete
															</div>
														</td>
													</tr>
												</tbody>
											);
										})}
										<tbody>
											<tr className='gap'></tr>
											<tr>
												<td className='left-box'></td>
												<td></td>
												<td
													className='hover right-box'
													onClick={addStations}
												>
													<div className='success label-large'>
														Add New
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className='label label-medium'>
									Stall Types
								</div>
								<div className='table-container'>
									<table>
										<thead>
											<tr>
												<th className='left-box'>
													<div className='table-head'>
														<div className='table-title'>
															Sr.&nbsp;No.
														</div>
													</div>
												</th>
												<th>
													<div className='table-head'>
														<div className='table-title'>
															Types
														</div>
													</div>
												</th>
												<th className='right-box'>
													<div className='table-head'>
														<div className='table-title'>
															Action
														</div>
													</div>
												</th>
											</tr>
										</thead>
										{stallTypes.map((stall, index) => {
											return (
												<tbody key={index}>
													<tr className='gap'></tr>
													<tr>
														<td className='center left-box'>
															{index + 1}
														</td>
														<td>{stall.type}</td>
														<td
															className='hover right-box'
															onClick={() =>
																deleteStallType(
																	stall.id,
																	stall.type
																)
															}
														>
															<div className='warning label-large'>
																Delete
															</div>
														</td>
													</tr>
												</tbody>
											);
										})}
										<tbody>
											<tr className='gap'></tr>
											<tr>
												<td className='left-box'></td>
												<td></td>
												<td
													className='hover right-box'
													onClick={addStallType}
												>
													<div className='success label-large'>
														Add New
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
						) : (
							<></>
						)}

						<Footer />
					</div>
				</div>
			</>
			{/* } */}
		</>
	);
};

export default Add;

//------------------------------------------------------------------------------------
