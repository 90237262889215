//------------------------------------------------------------------------------------

import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, json, useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, Notifications } from '../Icons/Icons';
// import { buttons } from '../Navigation/Navigation';
import './Header.scss';
import baseUrl from '../../scripts/baseUrl';
import Loading from '../Loading/Loading';
import Logo from '../../assets/logo.png';
import auth from '../../scripts/auth';
import theme from '../../scripts/theme';
import { LightMode, DarkMode } from '../Icons/Icons';
import {
	AddBox,
	BadgeIcon,
	Home,
	ListAlt,
	People,
	SupervisorAccount,
} from '../Icons/Icons';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { socket } from '../../App';

//------------------------------------------------------------------------------------

/* const buttons = [
    {
        label: 'Home',
        link: '/',
        isActive: false,
    },
]; */

const Header = () => {
	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	let buttons;

	if (
		(localStorage.getItem('admin') != null ||
			localStorage.getItem('admin') != '') &&
		(localStorage.getItem('employee') == null ||
			localStorage.getItem('employee') == '')
	) {
		if (JSON.parse(localStorage.getItem('admin')).superAdmin == 0) {
			buttons = [
				{
					label: 'Dashboard',
					icon: <Home />,
					link: '/dashboard',
					isActive: false,
				},
				{
					label: 'Contractors',
					icon: <SupervisorAccount />,
					link: '/owners',
					isActive: false,
				},
				{
					label: 'Vendors',
					icon: <People />,
					link: '/vendors',
					isActive: false,
				},
				{
					label: 'Employees',
					icon: <BadgeIcon />,
					link: '/employees',
					isActive: false,
				},
				// {
				//     label: 'Payments',
				//     link: '/payments',
				//     isActive: false,
				// },
				{
					label: 'Add',
					icon: <AddBox />,
					link: '/add',
					isActive: false,
				},
				{
					label: 'View logs',
					icon: <ListAlt />,
					link: '/logs',
					isActive: false,
				},
			];
		} else {
			buttons = [
				{
					label: 'Dashboard',
					icon: <Home />,
					link: '/dashboard',
					isActive: false,
				},
				{
					label: 'Contractors',
					icon: <SupervisorAccount />,
					link: '/owners',
					isActive: false,
				},
				{
					label: 'Vendors',
					icon: <People />,
					link: '/vendors',
					isActive: false,
				},
				{
					label: 'Employees',
					icon: <BadgeIcon />,
					link: '/employees',
					isActive: false,
				},
			];
		}
	}

	if (
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
	) {
		buttons = [
			{
				label: 'Dashboard',
				icon: <Home />,
				link: '/dashboard',
				isActive: false,
			},
			{
				label: 'Contractors',
				icon: <SupervisorAccount />,
				link: '/owners',
				isActive: false,
			},
			{
				label: 'Vendors',
				icon: <People />,
				link: '/vendors',
				isActive: false,
			},
		];
	}

	const [navigationState, setNavigationState] = useState(false);
	const currentScreen = useLocation().pathname;
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [notifications, setNotifications] = useState(0);
	const [currentTheme, setCurrentTheme] = useState(
		localStorage.getItem('theme')
	);

	for (const button of buttons) {
		if (currentScreen === button.link) {
			button.isActive = true;
		} else {
			button.isActive = false;
		}
	}

	const handleNavigationState = () => {
		setNavigationState((prev) => !prev);
	};

	// const logout = async () => {
	//     if (window.confirm("Are you sure you want to logout?")) {
	//         if (
	//             (localStorage.getItem('admin') != null || localStorage.getItem('admin') != '')
	//             && (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')
	//         ) {

	//             const admin = JSON.parse(localStorage.getItem('admin'));
	//             try {
	//                 setLoader(true);
	//                 const res = await axios.get(`${baseUrl}/logout/${admin.id}`);
	//                 if (res.data) {
	//                     // localStorage.setItem('admin', '');
	//                     localStorage.clear();
	//                     navigate('/login');
	//                     theme.set();
	//                 }
	//             } catch (err) {
	//                 console.log(err);
	//             }
	//             setLoader(false);
	//         }

	//         if (
	//             (localStorage.getItem('admin') == null || localStorage.getItem('admin') == '')
	//             && (localStorage.getItem('employee') != null || localStorage.getItem('employee') != '')
	//         ) {
	//             // localStorage.setItem('employee', '');
	//             localStorage.clear();
	//             navigate('/login', { replace: true });
	//         }
	//     }
	// }

	const showNotification = (notificationsCount) => {
		if (Notification.permission === 'granted') {
			const thisNotification = new Notification('Notifications', {
				body: `You have ${notificationsCount} notifications from Bslcatgvid.in`,
			});
		} else if (Notifications.permission !== 'denies') {
			Notification.requestPermission().then((permission) => {});
		}
	};

	const getNotifications = async () => {
		if (
			localStorage.getItem('admin') != null ||
			localStorage.getItem('admin') != ''
		) {
			if (localStorage.getItem('admin') == null) return;

			try {
				const res = await axios.get(`${baseUrl}/notifications`);

				if (res.data) {
					setNotifications(
						res.data.contractorPending.length +
							res.data.vendorsPending.length +
							res.data.employeesPending.length
					);

					if (
						sessionStorage.getItem('notifications') === null ||
						sessionStorage.getItem('notifications') <
							res.data.contractorPending.length +
								res.data.vendorsPending.length +
								res.data.employeesPending.length
					) {
						showNotification(
							res.data.contractorPending.length +
								res.data.vendorsPending.length +
								res.data.employeesPending.length
						);
					}
					sessionStorage.setItem(
						'notifications',
						JSON.stringify(
							res.data.contractorPending.length +
								res.data.vendorsPending.length +
								res.data.employeesPending.length
						)
					);
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	useEffect(() => {
		getNotifications();

		socket.on('isOwnerUpdated', (data) => {
			getNotifications();
		});

		socket.on('isVendorUpdated', (data) => {
			getNotifications();
		});
	}, []);

	// useEffect(() => {
	//     if (prevCount != notifications) {
	//         let audio = new Audio(NotificationSound);
	//         audio.play();
	//         prevCount = notifications;
	//     }
	// }, [notifications]);
	const toggleTheme = () => {
		theme.toggle();
		setCurrentTheme(localStorage.getItem('theme'));
	};

	return (
		<>
			<div className='header'>
				<div className='leading-container'>
					<div className='app-logo'>
						<img src={Logo} />
					</div>
					{/* <div className={"app-title " + (window.innerWidth > 600 ? 'title-large' : 'title-small')}>
                        {
                            (localStorage.getItem('admin') != null || localStorage.getItem('admin') != '')
                                && (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')
                                ? (JSON.parse(localStorage.getItem('admin')).superAdmin == 1 ? 'Super Admin Panel' : 'Admin Panel')
                                : 'Employee Panel'
                        }
                    </div> */}
					<div className='app-title'>
						<h1 className='title-medium'>
							{(localStorage.getItem('admin') != null ||
								localStorage.getItem('admin') != '') &&
							(localStorage.getItem('employee') == null ||
								localStorage.getItem('employee') == '')
								? JSON.parse(localStorage.getItem('admin'))
										.superAdmin == 1
									? 'Super Admin Panel'
									: 'Admin Panel'
								: 'Employee Panel'}
						</h1>
						{window.innerWidth > 600 && (
							<h2 className='body-small'>
								Bhusawal Division Catering Vendor Id
							</h2>
						)}
					</div>
				</div>
				<div className='trailing-container'>
					<button
						className='icon-button'
						onClick={toggleTheme}
					>
						{currentTheme == 'light' ? <DarkMode /> : <LightMode />}
					</button>
					{(localStorage.getItem('admin') != null ||
						localStorage.getItem('admin') != '') &&
						(localStorage.getItem('employee') == null ||
							localStorage.getItem('employee') == '') && (
							<Link to='/notifications'>
								<button
									className={
										currentScreen === '/notifications'
											? 'notification-icon active'
											: 'notification-icon'
									}
								>
									<Notifications />
									{notifications !== 0 && (
										<div className='badge label-small'>
											{notifications}
										</div>
									)}
								</button>
							</Link>
						)}

					{/* {
                        window.innerWidth > 840 &&
                        <button className="outlined-button" onClick={logout}>Logout</button>
                    } */}
					{window.innerWidth < 840 && (
						<button
							className='icon-button'
							onClick={handleNavigationState}
						>
							{navigationState ? <ExpandLess /> : <ExpandMore />}
						</button>
					)}
					<ProfileAvatar />
				</div>

				{navigationState & (window.innerWidth < 840) ? (
					<div className='navigation-container'>
						{buttons.map((button, index) => {
							const className = button.isActive
								? 'navigation-button active'
								: 'navigation-button';
							return (
								<Link
									to={button.link}
									key={index}
								>
									<button className={className}>
										{button.icon}
										{button.label}
									</button>
								</Link>
							);
						})}
						{/* <div className='bottom-buttons'>
                                <button className="outlined-button" onClick={logout}>Logout</button>
                            </div> */}
					</div>
				) : (
					<></>
				)}
			</div>
			{loader && <Loading />}
		</>
	);
};

export default Header;

//------------------------------------------------------------------------------------
