const unauthorizedRedirection = () => {
    if (
        (localStorage.getItem('admin') == null || localStorage.getItem('admin') == '')
        &&
        (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')) {
        localStorage.clear();
        window.location.replace('/');
    }
}

export default unauthorizedRedirection;