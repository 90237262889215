//------------------------------------------------------------------------------------

import '../../external.scss';
import './Vendor.scss';

import { Clear, PersonAdd } from '../../../../components/Icons/Icons';
import baseUrl, { userBaseUrl } from '../../../../scripts/baseUrl';
import { useEffect, useState } from 'react';

import Loading from '../../../../components/Loading/Loading';
import Logo from '../../../../assets/logo.png';
import { Navigate } from 'react-router-dom';
import auth from '../../../../scripts/auth';
import axios from 'axios';
import getUpcomingYears from '../../../../scripts/getUpcomingYears';
import unauthorizedRedirection from '../../../../scripts/unauthorizedRedirection';
import { useNavigate } from 'react-router-dom';
import { socket } from '../../../../App';

//------------------------------------------------------------------------------------

const openProfilePhotoInput = () => {
	const profilePhotoInput = document.getElementById('profile-photo-input');
	profilePhotoInput.click();
};

//------------------------------------------------------------------------------------

const profilePhotoValidation = () => {
	const profilePhotoInput = document.getElementById('profile-photo-input');
	const addProfilePhoto = document.getElementById('add-profile-photo');
	if (profilePhotoInput.value.length !== 0) {
		addProfilePhoto?.parentElement?.classList.remove('error');
		return true;
	} else {
		addProfilePhoto?.parentElement?.classList.add('error');
		return false;
	}
};

const pdfValidation = () => {
	const pdfInput = document.getElementById('pdf-input');
	if (pdfInput.value.length !== 0) {
		pdfInput.classList.remove('error');
		return true;
	} else {
		pdfInput.classList.add('error');
		return false;
	}
};

const nameValidation = () => {
	const name = document.getElementById('name');
	const nameHint = document.getElementById('name-hint');
	if (name.value.length === 0) {
		name.parentElement?.classList.add('error');
		nameHint.innerHTML = 'Please enter full name';
		return false;
	} else {
		name.parentElement?.classList.remove('error');
		nameHint.innerHTML = '&nbsp;';
		return true;
	}
};

const aadhaarValidation = () => {
	const aadhaarNumber = document.getElementById('aadhaar-number');
	const aadhaarNumberHint = document.getElementById('aadhaar-number-hint');
	const aadhaarNumberRegex = /^\d{12}$/;
	if (aadhaarNumber.value.match(aadhaarNumberRegex)) {
		aadhaarNumber.parentElement?.classList.remove('error');
		aadhaarNumberHint.innerHTML = '&nbsp;';
		return true;
	} else {
		aadhaarNumber.parentElement?.classList.add('error');
		aadhaarNumberHint.innerHTML = 'Please enter valid Aadhaar number';
		return false;
	}
};

const idValidation = () => {
	const idNumber = document.getElementById('id-number');
	const idNumberHint = document.getElementById('id-number-hint');
	if (idNumber.value.length === 0) {
		idNumber.parentElement?.classList.add('error');
		idNumberHint.innerHTML = 'Please enter ID number';
		return false;
	} else {
		idNumber.parentElement?.classList.remove('error');
		idNumberHint.innerHTML = '&nbsp;';
		return true;
	}
};

const designationValidation = () => {
	return true;
};

const unitValidation = () => {
	const unit = document.getElementById('unit');
	const unitHint = document.getElementById('unit-hint');
	if (unit.value.length === 0) {
		unit.parentElement?.classList.add('error');
		unitHint.innerHTML = 'Please enter unit';
		return false;
	} else {
		unit.parentElement?.classList.remove('error');
		unitHint.innerHTML = '&nbsp;';
		return true;
	}
};

const issueDateValidation = () => {
	const issueDate = document.getElementById('issue-date');
	if (issueDate.value.length !== 0) {
		issueDate.parentElement?.classList.remove('error');
		issueDate.parentElement?.parentElement?.classList.remove('error');
		return true;
	} else {
		issueDate.parentElement?.classList.add('error');
		issueDate.parentElement?.parentElement?.classList.add('error');
		return false;
	}
};

const validUptoValidation = () => {
	const validUpto = document.getElementById('valid-upto');
	if (validUpto.value.length !== 0) {
		validUpto.parentElement?.classList.remove('error');
		validUpto.parentElement?.parentElement?.classList.remove('error');
		return true;
	} else {
		validUpto.parentElement?.classList.add('error');
		validUpto.parentElement?.parentElement?.classList.add('error');
		return false;
	}
};

const validForValidation = () => {
	const validFor = document.getElementById('valid-for');
	const validForHint = document.getElementById('valid-for-hint');
	if (validFor.value.length === 0) {
		validFor.parentElement?.classList.add('error');
		validForHint.innerHTML = 'Please enter valid for';
		return false;
	} else {
		validFor.parentElement?.classList.remove('error');
		validForHint.innerHTML = '&nbsp;';
		return true;
	}
};

// const designations = [
//     'Stall keeper',
//     'PF vendor',
//     'Helper',
//     'Cleaner',
//     'Service boy',
//     'Assistant manager',
//     'Manager',
//     'IRCT vendor',
//     'PF vending',
//     'RG vendor',
//     'Rest giver',
//     'Stall & trolley vendor',
//     'Stall & trolley vendor',
//     'Trolley vendor',
//     'Waiter'
// ];

// const stations = [
//     'Bhusawal-Jn',
//     'Jalgaon-Jn',
//     'Pachora-Jn',
//     'Chalisgaon-Jn',
//     'Manmad-Jn',
//     'Nashik Road',
//     'Igatpuri',
//     'Dhule',
//     'Bodwad',
//     'Malkapur',
//     'Nandura',
//     'Shegaon',
//     'Akola-Jn',
//     'Badnera-Jn',
//     'Burhanpur',
//     'Khandwa-Jn',
// ];

const vendorTypes = ['Stall/trolley vendor', 'PF vendor'];

const AddVendor = () => {
	unauthorizedRedirection();
	document.title = `Vendor Registration • Bhusawal Division Catering Vendor Id`;

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const [designations, setDesignations] = useState([]);
	const [stations, setStations] = useState([]);
	const adminId = JSON.parse(localStorage.getItem('admin')).id;
	const [vendorType, setVendorType] = useState([...vendorTypes]);

	const setValidUpto = (e) => {
		const today = new Date();
		let date = '31';
		let month = '03';
		let value;

		if (e !== undefined) {
			let year = e.target.value;
			value = `${year}-${month}-${date}`;
			setVendor((prev) => ({ ...prev, validUpto: value }));
		} else {
			value = `${getUpcomingYears()[0]}-${month}-${date}`;
			return value;
		}
	};

	const [vendor, setVendor] = useState({
		photo: '',
		pdf: '',
		fullname: '',
		aadhaar: '',
		idNumber: '',
		desigination: designations[0],
		station: stations[0],
		unit: '',
		issueDate: '',
		validUpto: setValidUpto(),
		validFor: '',
		ownerId: '0',
		qr: '',
		stMaster: '0',
		ci: '0',
		ipf: '0',
		srdcm: '0',
		isDisabled: '0',
		addedByAdmin: adminId,
		// vendorType: vendorTypes[0],
	});

	const [validRegistration, setValidRegistration] = useState(false);

	const [file, setFile] = useState();
	const [fileName, setFileName] = useState('');

	const [pdfFile, setPdfFile] = useState();
	const [pdfFileName, setPdfFileName] = useState('');

	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);

	const getVendorsDesignations = async () => {
		try {
			setLoader(true);
			const res = await axios.get(`${baseUrl}/designations/vendors`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			setDesignations(res.data);
			setVendor((prev) => ({
				...prev,
				desigination: res.data[0].designation,
			}));
		} catch (error) {
			console.log(error);
		}
		setLoader(false);
	};

	const getStation = async () => {
		try {
			setLoader(true);
			const res = await axios.get(`${baseUrl}/stations`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			setStations(res.data);
			setVendor((prev) => ({ ...prev, station: res.data[0].station }));
		} catch (error) {
			console.log(error);
		}
		setLoader(false);
	};

	useEffect(() => {
		getVendorsDesignations();
		getStation();

		/* const body = document.getElementsByTagName('body')[0];
        const onEnter = document.getElementById('on-enter');
        body.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                onEnter.click();
            }
        }); */

		//blocking some keys for name
		const name = document.getElementById('name');
		name.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z ]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});

		//blocking some keys for id number
		const idNumber = document.getElementById('id-number');
		idNumber.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z0-9 -]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});

		//blocking some keys for id number
		const platformNumber = document.getElementById('valid-for');
		platformNumber.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z0-9,]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});

		//blocking some keys for unit
		// const unit = document.getElementById('unit');
		// unit.addEventListener('keypress', (event) => {
		//     var regex = new RegExp("^[a-zA-Z /]+$");
		//     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		//     if (!regex.test(key)) {
		//         event.preventDefault();
		//         return false;
		//     }
		// });

		//blocking some keys for 'valid for'
		// const validFor = document.getElementById('valid-for');
		// validFor.addEventListener('keypress', (event) => {
		//     var regex = new RegExp("^[0-9]+$");
		//     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		//     if (!regex.test(key)) {
		//         event.preventDefault();
		//         return false;
		//     }
		// });
	}, []);

	//profile photo
	const removeProfilePhoto = () => {
		setProfilePhotoSrc('');
		const profilePhotoInput = document.getElementById(
			'profile-photo-input'
		);
		profilePhotoInput.value = '';

		setFile('');
		setFileName('');
	};

	const [profilePhotoSrc, setProfilePhotoSrc] = useState('');
	const displayProfilePhoto = (event) => {
		if (event.target.files[0].size < 1000000) {
			setProfilePhotoSrc(URL.createObjectURL(event.target.files[0]));

			setFile(event.target.files[0]);
			setFileName(event.target.files[0].name);
		} else {
			alert('Image size should be less than 1MB.');
			removeProfilePhoto();
			profilePhotoValidation();
		}
	};

	const addVendor = async (event) => {
		// let accessToken = JSON.parse(localStorage.getItem('admin')).accessToken;

		setLoader(true);
		const formData = new FormData();
		formData.append('image', file);
		formData.append('fileName', fileName);

		const pdfData = new FormData();
		pdfData.append('image', pdfFile);
		pdfData.append('fileName', pdfFileName);

		try {
			const res1 = await axios.post(`${userBaseUrl}/vendors`, vendor, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res1.data.message)) return;

			const vendorId = res1.data.insertId;
			let tempAccessToken = res1.data.tempAccessToken;

			if (res1.data && res1.data !== 'EXISTS') {
				try {
					const res2 = await axios.put(
						`${userBaseUrl}/vendors/${vendorId}`,
						formData,
						{
							headers: {
								'access-token': tempAccessToken,
							},
						}
					);

					if (!auth(res2.data.message)) return;

					// Add pdf image
					try {
						const res3 = await axios.put(
							`${userBaseUrl}/vendors/pdf/${vendorId}`,
							pdfData,
							{
								headers: {
									'access-token': tempAccessToken,
								},
							}
						);

						if (!auth(res3.data.message)) return;

						if (res3.data) {
							alert(
								'New vendor has been registered successsfuly.'
							);
							setValidRegistration(true);

							socket.emit('updateVendors', {
								message: 'updateVendors',
							});
						}
					} catch (err) {
						console.log(err);
					}
				} catch (err) {
					console.log(err);
				}
			} else if (res1.data == 'EXISTS') {
				alert(`Vendor with id ${vendor.idNumber}, already exists.`);
			}
		} catch (err) {
			console.log(err);
		}
		setLoader(false);
	};

	const validate = () => {
		profilePhotoValidation();
		pdfValidation();
		nameValidation();
		aadhaarValidation();
		idValidation();
		designationValidation();
		unitValidation();
		issueDateValidation();
		validUptoValidation();
		validForValidation();

		if (
			profilePhotoValidation() &&
			pdfValidation() &&
			nameValidation() &&
			aadhaarValidation() &&
			idValidation() &&
			designationValidation() &&
			unitValidation() &&
			issueDateValidation() &&
			validUptoValidation() &&
			validForValidation()
		) {
			addVendor();
		} else {
			setValidRegistration(false);
		}
	};

	const handleChange = (e) => {
		setVendor((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	return (
		<>
			<div className='external-screen'>
				<div className='frame'>
					<div className='outlined-card'>
						<form onKeyUp={(event) => enterClick(event)}>
							<div className='form-app-headline'>
								<div className='app-logo'>
									<img src={Logo} />
								</div>
								<div className='app-title title-small'>
									Admin Panel
								</div>
							</div>

							<div className='app-title headline-large'>
								Vendor Registration
								{/*  ({currentFormsCount}/{vendorsCount}) */}
							</div>
							<div className='action-title body-medium'></div>

							<div className='images-container'>
								{/* Profile photo */}
								<div className='content-1'>
									<input
										type='file'
										id='profile-photo-input'
										className='hidden'
										name='image'
										accept='image/*'
										onChange={(event) => {
											profilePhotoValidation();
											displayProfilePhoto(event);
										}}
									/>
									{profilePhotoSrc ? (
										<div className='profile-photo-container'>
											<img
												src={profilePhotoSrc}
												className='profile-photo'
												alt='profile'
											/>
											<button
												type='button'
												className='icon-button'
												onClick={removeProfilePhoto}
											>
												<Clear />
											</button>
										</div>
									) : (
										<div
											className='add-profile-photo'
											id='add-profile-photo'
											onClick={openProfilePhotoInput}
										>
											<PersonAdd />
										</div>
									)}
									<div className='bottom-label body-small'>
										Profile photo
									</div>
								</div>
								<div className='content-2'></div>
							</div>

							<div className='label label-medium'>&nbsp;</div>

							<div className='label label-medium'>
								Permission letter / अनुमति पत्र
							</div>
							<input
								type='file'
								id='pdf-input'
								name='pdf'
								accept='image/*'
								className='pdf-input'
								onChange={(event) => {
									pdfValidation();
									setPdfFile(event.target.files[0]);
									setPdfFileName(event.target.files[0].name);
								}}
							/>

							<div className='label label-medium'>&nbsp;</div>

							{/* Name */}
							<div className='label label-medium'>
								Enter fullname
							</div>
							<div className='input-container'>
								<input
									type='text'
									id='name'
									className='inputfield uppercase'
									name='fullname'
									placeholder='Full name'
									onBlur={nameValidation}
									onChange={handleChange}
								/>
								<div
									id='name-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Aadhaar no. */}
							<div className='label label-medium'>
								Enter aadhar
							</div>
							<div className='input-container'>
								<input
									type='number'
									id='aadhaar-number'
									className='inputfield'
									name='aadhaar'
									placeholder='Aadhaar number'
									onBlur={aadhaarValidation}
									onChange={handleChange}
								/>
								<div
									id='aadhaar-number-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* ID no. */}
							<div className='label label-medium'>
								Enter id number
							</div>
							<div className='input-container'>
								<input
									type='text'
									id='id-number'
									className='inputfield uppercase'
									name='idNumber'
									placeholder='ID number'
									onBlur={idValidation}
									onChange={handleChange}
								/>
								<div
									id='id-number-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Designation */}
							<div className='label label-medium'>
								Enter designation
							</div>
							<div className='input-container'>
								<select
									id='designation'
									className='inputfield'
									name='desigination'
									onBlur={designationValidation}
									onChange={handleChange}
								>
									{designations.map((designation, index) => {
										return (
											<option
												key={index}
												value={designation.designation}
											>
												{designation.designation}
											</option>
										);
									})}
								</select>
								<div
									id='designation-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Vendor type */}
							{/* <div className='label label-medium'>Vendor type</div>
                            <div className="input-container">
                                <select id="designation" className='inputfield' name='vendorType' onChange={handleChange}>
                                    {
                                        vendorType.map((type, index) => {
                                            return <option key={index} value={type}>{type}</option>
                                        })
                                    }
                                </select>
                                <div id='designation-hint' className="input-hints">&nbsp;</div>
                            </div> */}

							{/* Station */}
							<div className='label label-medium'>
								Enter station
							</div>
							<div className='input-container'>
								<select
									id='station'
									className='inputfield'
									name='station'
									onChange={handleChange}
								>
									{stations.map((station, index) => {
										return (
											<option
												key={index}
												value={station.station}
											>
												{station.station}
											</option>
										);
									})}
								</select>
								<div
									id='station-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Unit */}
							<div className='label label-medium'>Enter unit</div>
							<div className='input-container'>
								<input
									type='text'
									id='unit'
									className='inputfield uppercase'
									name='unit'
									placeholder='Unit'
									onBlur={unitValidation}
									onChange={handleChange}
								/>
								<div
									id='unit-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							<div className='two-inputs-container'>
								{/* Date of issue */}
								<div className='date-input'>
									<div className='label label-medium'>
										Starting date
									</div>
									<div className='input-container'>
										<input
											type='date'
											id='issue-date'
											name='issueDate'
											className='inputfield'
											placeholder='Date of issue'
											onBlur={issueDateValidation}
											onChange={handleChange}
										/>
									</div>
									{/* <div className="bottom-label body-small">Date of issue</div> */}
								</div>

								{/* Valid upto */}
								<div className='date-input'>
									<div className='input-container'>
										<div className='label label-medium'>
											Ending date (31-March)
										</div>
										<input
											type='date'
											id='valid-upto'
											name='validUpto'
											className='inputfield'
											placeholder='Valid upto'
											onBlur={validUptoValidation}
											onChange={handleChange}
										/>
										{/* <select
                                            name='validUpto'
                                            className='inputfield'
                                            defaultValue={getUpcomingYears()[0]}
                                            onChange={setValidUpto}
                                        >
                                            {getUpcomingYears().map((data) => {
                                                return (
                                                    <option
                                                        value={data}
                                                        key={data}
                                                    >
                                                        {data}
                                                    </option>
                                                );
                                            })}
                                        </select> */}
									</div>
									{/* <div className="bottom-label body-small">Valid upto</div> */}
								</div>
							</div>

							<div className='label label-medium'>&nbsp;</div>

							{/* Valid for */}
							<div className='label label-medium'>
								Enter valid for platform number
							</div>
							<div className='input-container'>
								<input
									type='text'
									id='valid-for'
									name='validFor'
									className='inputfield uppercase'
									placeholder='Platform number'
									onBlur={validForValidation}
									onChange={handleChange}
								/>
								<div
									id='valid-for-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							<div className='bottom-buttons'>
								<div></div>
								<button
									type='button'
									id='on-enter'
									className='filled-button'
									onClick={validate}
								>
									Register
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			{loader && <Loading />}
			{validRegistration && (
				<Navigate
					replace
					to='/add'
				/>
			)}
		</>
	);
};

export default AddVendor;

//------------------------------------------------------------------------------------
