//------------------------------------------------------------------------------------

import '../internal.scss';

import {
	CheckCircle,
	Clear,
	Edit,
	ExpandLess,
	ExpandMore,
	Refresh,
} from '../../../components/Icons/Icons';
import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import Loading from '../../../components/Loading/Loading';
import Navigation from '../../../components/Navigation/Navigation';
import Pagination from '../../../components/Pagination/Pagination';
import auth from '../../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import calculateDateStatus from '../../../scripts/calculateDateStatus';
import filterOwnersByStation from '../../../scripts/filterOwnersByStation';
import hightlight from '../../../scripts/hightlighter';
import sortTable from '../../../scripts/sort';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { socket } from '../../../App';

//------------------------------------------------------------------------------------

let searchTextField = '';

const Owners = () => {
	unauthorizedRedirection();
	document.title = `Contractors • Bhusawal Division Catering Vendor Id`;

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const currentScreen = useLocation().pathname;

	const navigate = useNavigate();
	const [owners, setOwners] = useState([]);
	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);
	const [loader, setLoader] = useState(false);

	const [isRefreshing, setIsRefreshing] = useState(false);
	const [fetchComplete, setFetchComplete] = useState(false);

	const [stations, setStations] = useState([]);

	const [totalItems, setTotalItems] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(2);

	const [searchText, setSearchText] = useState({
		search: '',
	});

	const [ownerTotalItems, setOwnerTotalItems] = useState(0);
	const [ownerItemsPerPage, setOwnerItemsPerPage] = useState(20);
	const [ownerActivePage, setOwnerActivePage] = useState(1);

	//---------------------------------------------------------

	const [dialog, setDialog] = useState(false);
	const [dialogIndex, setDialogIndex] = useState(-1);

	const openDialog = (index) => {
		setDialog(owners[ownerActivePage - 1][index]);
		setDialogIndex(index);
	};

	const closeDialog = () => {
		setDialog(false);
		setDialogIndex(-1);
	};

	const previousDialog = () => {
		let index = dialogIndex - 1;
		if (index < 0) return;
		setDialog(owners[ownerActivePage - 1][index]);
		setDialogIndex(index);
	};

	const nextDialog = () => {
		let index = dialogIndex + 1;
		if (index > owners[ownerActivePage - 1].length) return;
		setDialog(owners[ownerActivePage - 1][index]);
		setDialogIndex(index);
	};

	const getOwners = async () => {
		setIsRefreshing(true);

		try {
			const res = await axios.get(`${baseUrl}/owners`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				let vendorsArray =
					(localStorage.getItem('admin') == null ||
						localStorage.getItem('admin') == '') &&
					(localStorage.getItem('employee') != null ||
						localStorage.getItem('employee') != '')
						? filterOwnersByStation(res.data)
						: res.data;

				const arrayData = [];
				for (const data of vendorsArray) {
					arrayData.unshift(data);
				}

				let array = arrayData;
				let mainArray = [];

				let n = 0;
				for (
					let i = 0;
					i < Math.ceil(array.length / ownerItemsPerPage);
					i++
				) {
					mainArray.push([]);
					for (let a = 0; a < ownerItemsPerPage; a++) {
						if (array[n] != undefined) {
							mainArray[i].push(array[n]);
							n++;
						}
					}
				}

				setOwners(mainArray);
				setOwnerTotalItems(res.data.length);

				setTotalItems(mainArray.length);
				setNotFound(false);
			} else {
				setNotFound(true);
			}

			setBuffer(false);

			setTimeout(() => {
				setTimeout(() => {
					setFetchComplete(false);
				}, 2000);
				setFetchComplete(true);
				setIsRefreshing(false);
			}, 1000);
		} catch (err) {
			console.log(err);
		}
	};

	const getStations = async () => {
		try {
			const res = await axios.get(`${baseUrl}/stations`);
			setStations(res.data);
		} catch (error) {
			console.log(error);
		}

		setNotFound(false);
		setBuffer(false);
	};

	useEffect(() => {
		getOwners();
		getStations();

		socket.on('isOwnerUpdated', (data) => {
			if (searchTextField.length === 0) {
				getOwners();
			}
		});

		// let callData = setInterval(() => {
		//     getOwners();
		// }, 1000 * 5);

		// return () => {
		//     clearInterval(callData);
		// }
	}, []);

	const sort = async (table, column, order) => {
		setLoader(true);
		let thisArray = await sortTable(table, column, order);

		// const arrayData = [];
		// for (const data of array) {
		// 	arrayData.unshift(data);
		// }
		// setOwners([...arrayData]);
		let vendorsArray =
			(localStorage.getItem('admin') == null ||
				localStorage.getItem('admin') == '') &&
			(localStorage.getItem('employee') != null ||
				localStorage.getItem('employee') != '')
				? filterOwnersByStation(thisArray)
				: thisArray;

		const arrayData = [];
		for (const data of vendorsArray) {
			arrayData.unshift(data);
		}

		let array = arrayData;
		let mainArray = [];

		let n = 0;
		for (let i = 0; i < Math.ceil(array.length / ownerItemsPerPage); i++) {
			mainArray.push([]);
			for (let a = 0; a < ownerItemsPerPage; a++) {
				if (array[n] != undefined) {
					mainArray[i].push(array[n]);
					n++;
				}
			}
		}

		setOwners(mainArray);
		setOwnerTotalItems(thisArray.length);

		setTotalItems(mainArray.length);
		setLoader(false);
	};

	const getPageNumberOwner = (number) => {
		setOwnerActivePage(number);
	};

	const viewVendors = async (ownerId, shopName) => {
		navigate('/owners/vendors', {
			state: { ownerId: ownerId, shopName: shopName },
		});
	};

	const handleChange = (e) => {
		setSearchText((prev) => ({ ...prev, search: e.target.value }));
	};

	const search = async (value) => {
		if (
			(searchText.search.length !== 0 &&
				searchText.search.length < 255) ||
			value
		) {
			try {
				setNotFound(true);
				setBuffer(true);
				const res = await axios.post(
					`${baseUrl}/owners/search`,
					searchText,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					if (res.data.length === 0) {
						setNotFound(false);
						setBuffer(false);
						setTimeout(() => {
							alert(`No results for "${searchText.search}".`);
						}, 10);
						return;
					}

					let vendorsArray =
						(localStorage.getItem('admin') == null ||
							localStorage.getItem('admin') == '') &&
						(localStorage.getItem('employee') != null ||
							localStorage.getItem('employee') != '')
							? filterOwnersByStation(res.data)
							: res.data;

					const arrayData = [];
					for (const data of vendorsArray) {
						arrayData.unshift(data);
					}

					let array = arrayData;
					let mainArray = [];

					let n = 0;
					for (
						let i = 0;
						i < Math.ceil(array.length / ownerItemsPerPage);
						i++
					) {
						mainArray.push([]);
						for (let a = 0; a < ownerItemsPerPage; a++) {
							if (array[n] != undefined) {
								mainArray[i].push(array[n]);
								n++;
							}
						}
					}

					setOwners(mainArray);
					setOwnerTotalItems(res.data.length);

					setTotalItems(mainArray.length);
					setNotFound(false);
				} else {
					setNotFound(true);
				}
				setBuffer(false);
				searchTextField = searchText.search;
				hightlight(searchTextField);

				// setSearchText((prev) => ({ ...prev, search: '' }));
				// searchTextField = searchText.search;
			} catch (err) {
				console.log(err);
			}
			return;
		} else if (
			searchText.search.length !== 0 &&
			searchText.search.length > 255
		) {
			alert('Search text is too big to search.');
			return;
		} else {
			alert("Can't search, search field is empty.");
			return;
		}
	};

	// const changeVendorsCount = async (ownerId, vendorsCount) => {
	//     let value = window.prompt('Total Vendors Count', vendorsCount);

	//     const data = {
	//         id: ownerId,
	//         vendorsCount: value,
	//     }

	//     if (value) {
	//         try {
	//             setNotFound(true);
	//             setBuffer(true);
	//             const res = await axios.put(`${baseUrl}/owners/updateVendorsCount`, data, {
	//                 headers: {
	//                     'access-token': accessToken
	//                 }
	//             });

	//             if (!auth(res.data.message)) return;

	//             if (res.data) {
	//                 getOwners();
	//             } else {
	//                 setNotFound(true);
	//             }
	//             setBuffer(false);
	//         } catch (err) {
	//             console.log(err);
	//         }
	//     }
	// }

	const changeStallTrolleyVendorCount = async (
		ownerId,
		stallTrolleyVendorCount
	) => {
		let value = window.prompt(
			'Update number of staff',
			stallTrolleyVendorCount
		);

		const data = {
			id: ownerId,
			stallTrolleyVendorCount: value,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateStallTrolleyVendorCount`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === ownerId) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].stallTrolleyVendorCount =
										value),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changePfVendorCount = async (ownerId, pfVendorCount) => {
		let value = window.prompt('Update PF vendors count', pfVendorCount);

		const data = {
			id: ownerId,
			pfVendorCount: value,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updatePfVendorCount`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === ownerId) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].pfVendorCount = value),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changeSupportingStaff = async (ownerId, supportingStaff) => {
		let value = window.prompt(
			'Update supporting staff count',
			supportingStaff
		);

		const data = {
			id: ownerId,
			supportingStaff: value,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateSupportingStaffCount`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === ownerId) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].supportingStaff = value),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changeNumberOfStall = async (ownerId, numberOfStall) => {
		let value = window.prompt('Total number of stalls', numberOfStall);

		const data = {
			id: ownerId,
			numberOfStall: value,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateNumberOfStall`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// setOwners('');

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === ownerId) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].numberOfStall = value),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changeNumberOfTrolley = async (ownerId, numberOfTrolley) => {
		let value = window.prompt('Total number of trolleys', numberOfTrolley);

		const data = {
			id: ownerId,
			numberOfTrolley: value,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (value) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateNumberOfTrolley`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === ownerId) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].numberOfTrolley = value),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changeMobile = async (value) => {
		let prevMobile = value.mobile;
		let mobileValue = window.prompt(
			`Change mobile no. of contractor named '${value.name}'.`,
			value.mobile
		);

		const data = {
			id: value.id,
			mobile: mobileValue,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (mobileValue && prevMobile != mobileValue) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateMobile`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === value.id) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].mobile = mobileValue),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const changeEndingDate = async (value) => {
		let prevEndingDate = value.endingDate;
		let endingDateValue = window.prompt(
			`Change ending date of contractor named '${value.name}'. (Note: please enter date in YYYY-MM-DD format)`,
			value.endingDate
		);

		const data = {
			id: value.id,
			endingDate: endingDateValue,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		if (endingDateValue && prevEndingDate != endingDateValue) {
			try {
				// setNotFound(true);
				// setBuffer(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateEndingdate`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === value.id) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].endingDate = endingDateValue),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	// const getRemainingDays = (endingDate) => {
	//     const date1 = new Date();
	//     const date2 = new Date(endingDate);
	//     const diffTime = Math.abs(date2 - date1);
	//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	//     if ((date2 - date1).toString()[0] == '-') {
	//         return {
	//             className: 'warning-container strong',
	//             label: `Contractor's validity expired ${diffDays - 1} days ago.`,
	//         };
	//     }

	//     if (diffDays < 1) {
	//         return {
	//             className: 'warning-container strong',
	//             label: `Contractor's validity expired.`,
	//         };
	//     }

	//     if (diffDays < 15) {
	//         return {
	//             className: 'warning-container strong',
	//             label: `${diffDays} days remaining.`,
	//         };
	//     }

	//     return {
	//         className: 'warning-container',
	//         label: `${diffDays} days remaining.`,
	//     };
	// }

	const changeStatus = async (id, status) => {
		if (status == 0) {
			if (!window.confirm('Do you want to approve this contractor?'))
				return;
		}
		if (status == 1) {
			if (!window.confirm('Do you want to disapprove this contractor?'))
				return;
		}

		if (status == 0) {
			status = 1;
		} else {
			status = 0;
		}

		const data = {
			id: id,
			status: status,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};
		try {
			// setNotFound(true);
			// setBuffer(true);
			setLoader(true);
			const res = await axios.post(
				`${baseUrl}/owners/changeStatus`,
				data,
				{
					headers: {
						'access-token': accessToken,
					},
				}
			);
			setLoader(false);

			if (!auth(res.data.message)) return;

			if (res.data) {
				// setNotFound(false);
				// getOwners();

				for (let i = 0; i < owners.length; i++) {
					for (let n = 0; n < owners[i].length; n++) {
						if (owners[i][n].id === data.id) {
							setOwners((prev) => [
								...prev,
								(prev[i][n].approve = data.status),
							]);
						}
					}
				}

				socket.emit('updateOwner', {
					message: 'updateOwner',
				});
			} else {
				// setNotFound(true);
			}
			// setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const changePaymentStatus = async (id, status) => {
		if (status == 0) {
			if (!window.confirm('Do you want to approve this contractor?'))
				return;
		}
		if (status == 1) {
			if (!window.confirm('Do you want to diapprove this contractor?'))
				return;
		}

		if (status == 0) {
			status = 1;
		} else {
			status = 0;
		}

		const data = {
			id: id,
			status: status,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		try {
			// setNotFound(true);
			// setBuffer(true);
			setLoader(true);
			const res = await axios.post(
				`${baseUrl}/owners/changePaymentStatus`,
				data,
				{
					headers: {
						'access-token': accessToken,
					},
				}
			);
			setLoader(false);

			if (!auth(res.data.message)) return;

			if (res.data) {
				// setNotFound(false);
				// getOwners();

				for (let i = 0; i < owners.length; i++) {
					for (let n = 0; n < owners[i].length; n++) {
						if (owners[i][n].id === data.id) {
							setOwners((prev) => [
								...prev,
								(prev[i][n].payed = data.status),
							]);
						}
					}
				}

				socket.emit('updateOwner', {
					message: 'updateOwner',
				});
			} else {
				// setNotFound(true);
			}
			// setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const toggleStatus = async (ownerId, isDisabled, ownerName) => {
		if (isDisabled == '0') {
			if (
				!window.confirm(
					`Are you sure you want to disable contractor with name '${ownerName}'?`
				)
			)
				return;
			// if (
			//     window.prompt(
			//         `Enter contractor name '${ownerName}' to disable.`
			//     ) !== ownerName
			// ) {
			//     alert('Wrong input. Contractor not disabled');
			//     return;
			// }
		}

		if (isDisabled == '1') {
			if (
				!window.confirm(
					`Are you sure you want to enable contractor with name '${ownerName}'?`
				)
			)
				return;
			// if (
			//     window.prompt(
			//         `Enter contractor name '${ownerName}' to enable.`
			//     ) !== ownerName
			// ) {
			//     alert('Wrong input. Contractor not enabled');
			//     return;
			// }
		}

		const data = {
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		try {
			setNotFound(true);
			setBuffer(true);
			const res = await axios.put(
				`${baseUrl}/owners/disable/${ownerId}`,
				data,
				{
					headers: {
						'access-token': accessToken,
					},
				}
			);

			if (!auth(res.data.message)) return;

			if (res.data) {
				getOwners();
				setNotFound(false);

				socket.emit('updateOwner', {
					message: 'updateOwner',
				});
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const deleteOwner = async (ownerId, ownerName) => {
		if (
			window.confirm(
				`Are you sure you want to permanently delete contractor with name '${ownerName}'? This process is non-reversal.`
			)
		) {
			// if (
			//     window.prompt(
			//         `Enter contractor name '${ownerName}' to delete permanently.`
			//     ) !== ownerName
			// ) {
			//     alert('Wrong input. Contractor not deleted');
			//     return;
			// }

			try {
				// setNotFound(true);
				// setBuffer(true);

				const data = {
					adminId: JSON.parse(localStorage.getItem('admin')).id,
				};

				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/delete/${ownerId}`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// setNotFound(false);
					// getOwners();

					for (let i = 0; i < owners.length; i++) {
						for (let n = 0; n < owners[i].length; n++) {
							if (owners[i][n].id === ownerId) {
								setOwners((prev) => [
									...prev,
									(prev[i][n].isDeleted = 1),
								]);
							}
						}
					}

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	// const totalCount = () => {
	// 	let count = 0;
	// 	for (let i = 0; i < owners.length; i++) {
	// 		count = count + owners[i].length;
	// 	}
	// 	return count;
	// };

	useEffect(() => {
		getStations();

		document.addEventListener('keyup', (e) => {
			if (e.key !== '/') return;
			let searchInput = document.getElementById('search-input');
			searchInput.focus();
		});
	}, []);

	// const sortByStation = (station) => {
	// 	if (station !== '*') {
	// 		search('Bhus');
	// 	} else {
	// 		getOwners();
	// 	}
	// };

	const updateStallLocation = async (ownerId, shopAddress, adminId) => {
		let value = window.prompt('Update stall location', shopAddress);
		// closeDialog();

		if (value) {
			const data = {
				id: ownerId,
				shopAddress: value,
				adminId: adminId,
			};
			try {
				// setNotFound(true);
				setLoader(true);
				const res = await axios.put(
					`${baseUrl}/owners/updateShopAddress`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);
				setLoader(false);

				if (!auth(res.data.message)) return;

				if (res.data) {
					// getOwners();
					setDialog((prev) => ({ ...prev, shopAddress: value }));

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					// setNotFound(true);
				}
				// openDialog(dialogIndex);
				// setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Navigation />
				<div className='side-container'>
					{buffer && notFound ? <Buffer /> : <></>}

					{owners.length === 0 && !buffer ? (
						<Fallback
							title='Not found'
							text='No data found in the database. Try refreshing the page.'
						/>
					) : (
						<></>
					)}

					{owners.length !== 0 && !notFound && !buffer ? (
						<>
							<div className='screen-title headline-large'>
								Contractors
							</div>

							<div className='search-container'>
								<input
									type='text'
									className='body-medium'
									id='search-input'
									onChange={handleChange}
									placeholder='Search here (press "/" to focus)'
									onKeyUp={(event) => enterClick(event)}
									defaultValue={searchText.search}
								/>
								<button
									disabled={isRefreshing}
									className={
										isRefreshing
											? 'icon-button refreshing'
											: 'icon-button'
									}
									onClick={() => {
										searchTextField = '';
										setSearchText((prev) => ({
											...prev,
											search: '',
										}));

										document.getElementById(
											'search-input'
										).value = '';

										getOwners();
									}}
									title='Reload'
								>
									{fetchComplete ? (
										<CheckCircle />
									) : (
										<Refresh />
									)}
								</button>
								<button
									type='button'
									id='on-enter'
									className='filled-tonal-button'
									onClick={() => search(searchText.search)}
								>
									Search
								</button>
							</div>

							{/* <select
								className='stations-container'
								onChange={(event) => {
									const station = event.currentTarget.value;

									if (station === '') {
										getOwners();
										searchTextField = '';
										setSearchText({
											search: '',
										});
										return;
									}

									setSearchText({
										search: station,
									});
									search(station);
								}}
								defaultValue={searchTextField}
							>
								<option
									className='label-large'
									value=''
								>
									All
								</option>
								{stations.map((data, index) => {
									return (
										<option
											key={`station-${index}`}
											className='label-large'
											value={data.station}
											z
										>
											{data.station}
										</option>
									);
								})}
							</select> */}

							{/* <div className="sorting-container">
								<select
									className="sorting-button label-large"
									onChange={(event) =>
										sortByStation(event.currentTarget.value)
									}
								>
									<option
										className="label-large"
										value="*"
									>
										All stations
									</option>
									{stations.map((data) => {
										return (
											<option
												value={data.station}
												key={data.station}
											>
												{data.station}
											</option>
										);
									})}
								</select>
							</div> */}

							<div className='table-container center'>
								<table>
									{/* <tbody> */}
									<thead>
										<tr>
											<th className='left-box'>
												<div className='table-head'>
													<div className='table-title'>
														Sr.&nbsp;No.
													</div>
												</div>
											</th>
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Photo
													</div>
												</div>
											</th>
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Photo</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Id&nbsp;Photo</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Shop&nbsp;Photo</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Sign&nbsp;Photo</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Stamp&nbsp;Photo</div>
                                                    </div>
                                                </th> */}
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Stall&nbsp;name
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'fullname',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'fullname',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Shop&nbsp;address</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Shop&nbsp;name</div>
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('owners', 'shopName', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('owners', 'shopName', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    </div>
                                                </th> */}
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Vendors Count</div>
                                                    </div>
                                                </th> */}

											<th>
												{/* <div className='table-head'>
                                                        <div className='table-title'>Station</div>
                                                    </div> */}
												<div className='table-head'>
													<div className='table-title'>
														Station
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'station',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'station',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>
														Type
													</div>
												</div>
											</th>

											<th>
												{/* <div className='table-head'>
                                                        <div className='table-title'>Number of stalls</div>
                                                    </div> */}
												<div className='table-head'>
													<div className='table-title'>
														Stalls
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'numberOfStall',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'numberOfStall',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												{/* <div className='table-head'>
                                                        <div className='table-title'>Number of trolleys</div>
                                                    </div> */}
												<div className='table-head'>
													<div className='table-title'>
														Trolleys
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'numberOfTrolley',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'numberOfTrolley',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												{/* <div className='table-head'>
                                                        <div className='table-title'>Number of staff</div>
                                                    </div> */}
												<div className='table-head'>
													<div className='table-title'>
														Staff
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'stallTrolleyVendorCount',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'stallTrolleyVendorCount',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>

											<th>
												{/* <div className='table-head'>
                                                        <div className='table-title'>PF vendor</div>
                                                    </div> */}
												<div className='table-head'>
													<div className='table-title'>
														PF
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'pfVendorCount',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'pfVendorCount',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>
											<th>
												{/* <div className='table-head'>
                                                        <div className='table-title'>Supporting staff</div>
                                                    </div> */}
												<div className='table-head'>
													<div className='table-title'>
														SS
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'supportingStaff',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'supportingStaff',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Total
													</div>
												</div>
											</th>

											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Username</div>
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('owners', 'username', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('owners', 'username', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    </div>
                                                </th> */}
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Email</div>
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('owners', 'email', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('owners', 'email', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    </div>
                                                </th> */}
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Mobile
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'mobile',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'mobile',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Starting&nbsp;date</div>
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('owners', 'startingDate', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('owners', 'startingDate', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    </div>
                                                </th> */}
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Ending&nbsp;date
													</div>
													{/* {(localStorage.getItem(
														'admin'
													) != null ||
														localStorage.getItem(
															'admin'
														) != '') &&
														(localStorage.getItem(
															'employee'
														) == null ||
															localStorage.getItem(
																'employee'
															) == '') && (
															<div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'endingDate',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'endingDate',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div>
														)} */}
												</div>
											</th>
											{/* <th>
                                                    <div className='table-head'>
                                                        <div className='table-title'>Id</div>
                                                        <div className='sort-container'>
                                                            <button className='sort-button' onClick={() => sort('owners', 'id', 'ASC')}><ExpandLess /></button>
                                                            <button className='sort-button' onClick={() => sort('owners', 'id', 'DESC')}><ExpandMore /></button>
                                                        </div>
                                                    </div>
                                                </th> */}
											<th>
												<div className='table-head'>
													<div className='table-title'>
														Approval status
													</div>
												</div>
											</th>

											{(localStorage.getItem('admin') !=
												null ||
												localStorage.getItem('admin') !=
													'') &&
												(localStorage.getItem(
													'employee'
												) == null ||
													localStorage.getItem(
														'employee'
													) == '') && (
													<th>
														{/* <div className='table-head'>
                                                            <div className='table-title'>Login approval</div>
                                                        </div> */}
														<div className='table-head'>
															<div className='table-title'>
																Login approval
															</div>
															{/* <div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'approve',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'approve',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div> */}
														</div>
													</th>
												)}

											{(localStorage.getItem('admin') !=
												null ||
												localStorage.getItem('admin') !=
													'') &&
												(localStorage.getItem(
													'employee'
												) == null ||
													localStorage.getItem(
														'employee'
													) == '') &&
												JSON.parse(
													localStorage.getItem(
														'admin'
													)
												).superAdmin == 0 && (
													<th>
														{/* <div className='table-head'>
                                                            <div className='table-title'>Registration payment approval</div>
                                                        </div> */}
														<div className='table-head'>
															<div className='table-title'>
																Payment approval
															</div>
															{/* <div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'payed',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'payed',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div> */}
														</div>
													</th>
												)}

											<th>
												<div className='table-head'>
													<div className='table-title'>
														View
													</div>
												</div>
											</th>
											{/* <th>
												<div className='table-head'>
													<div className='table-title'>
														View contractors
													</div>
												</div>
											</th> */}

											{(localStorage.getItem('admin') !=
												null ||
												localStorage.getItem('admin') !=
													'') &&
												(localStorage.getItem(
													'employee'
												) == null ||
													localStorage.getItem(
														'employee'
													) == '') && (
													<th>
														{/* <div className='table-head'>
                                                            <div className='table-title'>Owner status</div>
                                                        </div> */}
														<div className='table-head'>
															<div className='table-title'>
																Status
															</div>
															{/* <div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'isDisabled',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'isDisabled',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div> */}
														</div>
													</th>
												)}

											{(localStorage.getItem('admin') !=
												null ||
												localStorage.getItem('admin') !=
													'') &&
												(localStorage.getItem(
													'employee'
												) == null ||
													localStorage.getItem(
														'employee'
													) == '') && (
													<th className='right-box'>
														{/* <div className='table-head'>
                                                            <div className='table-title'>Delete</div>
                                                        </div> */}
														<div className='table-head'>
															<div className='table-title'>
																Delete
															</div>
															{/* <div className='sort-container'>
																<button
																	className='sort-button'
																	title='Sort by ascending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'isDeleted',
																			'ASC'
																		)
																	}
																>
																	<ExpandLess />
																</button>
																<button
																	className='sort-button'
																	title='Sort by descending order'
																	onClick={() =>
																		sort(
																			'owners',
																			'isDeleted',
																			'DESC'
																		)
																	}
																>
																	<ExpandMore />
																</button>
															</div> */}
														</div>
													</th>
												)}
										</tr>
									</thead>
									{owners[ownerActivePage - 1].map(
										(owner, index) => {
											let className =
												owner.isDeleted != 0
													? 'deleted-row'
													: '';
											let dotClassName =
												owner.isDeleted != 0
													? 'dot error'
													: 'dot';

											if (
												owner.isDeleted == 0 &&
												owner.isDisabled == 0 &&
												owner.approve == 1 &&
												owner.payed != 0
											) {
												className = 'success-row';
												dotClassName = 'dot success';
											}

											if (
												owner.isDisabled == 1 &&
												owner.isDeleted == 0
											) {
												className =
													'owner-disabled-row';
											}

											// let className;
											// if (owner.isDeleted != 0) className = 'deleted-row';
											// if (owner.isDisabled == 1 && owner.isDeleted == 0) className = 'disable-row';

											return (
												<tbody
													// key={`owner-${owner.id}`}
													key={Math.random()}
													title={`${owner.shopName} (${owner.station})`}
												>
													<tr className='gap'></tr>
													<tr className={className}>
														<td
															className='center left-box'
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	viewVendors(
																		owner.id,
																		owner.shopName
																	);
																}
															}}
															title='Click to view vendors'
															// onClick={() => { openDialog(index) }}
														>
															<div
																className={
																	dotClassName
																}
															></div>
															{(ownerActivePage -
																1) *
																ownerItemsPerPage +
																(index + 1)}
														</td>
														<td
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															<div className='table-profile-photo'>
																<img
																	src={
																		owner.photo
																	}
																/>
															</div>
														</td>
														{/* <td className='hover'></td>
                                                            <td className='hover'></td>
                                                            <td className='hover'></td>
                                                            <td className='hover'></td>
                                                            <td className='hover'></td> */}
														<td
															className='hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{`${
																owner.shopName
															} ${
																owner.isDeleted !=
																0
																	? '(Deleted)'
																	: ''
															}`}

															{new Date() >
																new Date(
																	`${new Date().getFullYear()}-02-01`
																) &&
																new Date() <
																	new Date(
																		`${new Date().getFullYear()}-04-01`
																	) &&
																(owner.isVendorsRenewed ==
																1 ? (
																	<div className='inline-chip'>
																		(Renewed)
																	</div>
																) : (
																	<div className='inline-chip'>
																		(Not
																		renewed)
																	</div>
																))}

															{owner.addedByAdmin !=
															0 ? (
																<div className='inline-chip'>
																	(Added by
																	Admin)
																</div>
															) : (
																''
															)}
														</td>
														<td
															className='center hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{owner.station
																? owner.station
																: '--'}
														</td>
														<td
															className='center hightlight'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{owner.stallType
																? owner.stallType
																: '--'}
														</td>
														<td
															className={
																owner.isDisabled ==
																	'0' &&
																(localStorage.getItem(
																	'admin'
																) != null ||
																	localStorage.getItem(
																		'admin'
																	) != '') &&
																(localStorage.getItem(
																	'employee'
																) == null ||
																	localStorage.getItem(
																		'employee'
																	) == '')
																	? 'hover center'
																	: 'center'
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changeNumberOfStall(
																			owner.id,
																			owner.numberOfStall
																		);
																	}
																}
															}}
														>
															{
																owner.numberOfStall
															}
														</td>

														<td
															className={
																owner.isDisabled ==
																	'0' &&
																(localStorage.getItem(
																	'admin'
																) != null ||
																	localStorage.getItem(
																		'admin'
																	) != '') &&
																(localStorage.getItem(
																	'employee'
																) == null ||
																	localStorage.getItem(
																		'employee'
																	) == '')
																	? 'hover center'
																	: 'center'
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changeNumberOfTrolley(
																			owner.id,
																			owner.numberOfTrolley
																		);
																	}
																}
															}}
														>
															{
																owner.numberOfTrolley
															}
														</td>

														{/* <td>{owner.shopAddress}</td>
                                                            <td>{owner.shopName}</td> */}
														{/* <td onClick={() => changeVendorsCount(owner.id, owner.vendorsCount)}>
                                                                <div className="input-container">
                                                                    <input type="number" className='inputfield' style={{ width: '150px' }} defaultValue={owner.vendorsCount} disabled />
                                                                </div>
                                                            </td> */}

														{/* Stall/trolley vendor count */}
														<td
															className={
																owner.isDisabled ==
																	'0' &&
																(localStorage.getItem(
																	'admin'
																) != null ||
																	localStorage.getItem(
																		'admin'
																	) != '') &&
																(localStorage.getItem(
																	'employee'
																) == null ||
																	localStorage.getItem(
																		'employee'
																	) == '')
																	? 'hover diff-1 center'
																	: 'diff-1 center'
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changeStallTrolleyVendorCount(
																			owner.id,
																			owner.stallTrolleyVendorCount
																		);
																	}
																}
															}}
														>
															{
																owner.stallTrolleyVendorCount
															}
														</td>

														{/* PF vendor count */}
														<td
															className={
																owner.isDisabled ==
																	'0' &&
																(localStorage.getItem(
																	'admin'
																) != null ||
																	localStorage.getItem(
																		'admin'
																	) != '') &&
																(localStorage.getItem(
																	'employee'
																) == null ||
																	localStorage.getItem(
																		'employee'
																	) == '')
																	? 'hover diff-1 center'
																	: 'diff-1 center'
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changePfVendorCount(
																			owner.id,
																			owner.pfVendorCount
																		);
																	}
																}
															}}
														>
															{
																owner.pfVendorCount
															}
														</td>

														{/* supporting staff */}
														<td
															className={
																owner.isDisabled ==
																	'0' &&
																(localStorage.getItem(
																	'admin'
																) != null ||
																	localStorage.getItem(
																		'admin'
																	) != '') &&
																(localStorage.getItem(
																	'employee'
																) == null ||
																	localStorage.getItem(
																		'employee'
																	) == '')
																	? 'hover diff-1 center'
																	: 'diff-1 center'
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changeSupportingStaff(
																			owner.id,
																			owner.supportingStaff
																		);
																	}
																}
															}}
														>
															{
																owner.supportingStaff
															}
														</td>

														<td
															className='center'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{+owner.pfVendorCount +
																+owner.stallTrolleyVendorCount +
																+owner.supportingStaff}
														</td>

														{/* <td>{owner.username}</td> */}
														{/* <td>{owner.email}</td> */}
														<td
															className={
																owner.isDisabled ==
																	'0' &&
																(localStorage.getItem(
																	'admin'
																) != null ||
																	localStorage.getItem(
																		'admin'
																	) != '') &&
																(localStorage.getItem(
																	'employee'
																) == null ||
																	localStorage.getItem(
																		'employee'
																	) == '')
																	? 'hover center hightlight'
																	: 'center hightlight'
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changeMobile(
																			{
																				id: owner.id,
																				name: owner.fullname,
																				mobile: owner.mobile,
																			}
																		);
																	}
																}
															}}
														>
															+91 {owner.mobile}
														</td>
														{/* <td>{owner.startingDate}</td> */}
														<td
															// className={owner.isDisabled == '0' && owner.endingDate != null
															//     && (localStorage.getItem('admin') != null || localStorage.getItem('admin') != '')
															//     && (localStorage.getItem('employee') == null || localStorage.getItem('employee') == '')
															//     ? 'hover center' : 'center'}
															className={`center hightlight ${
																owner.endingDate !==
																	null &&
																'hover'
															}`}
															onClick={() => {
																if (
																	// owner.isDisabled == '0' &&
																	owner.endingDate !=
																	null
																) {
																	if (
																		(localStorage.getItem(
																			'admin'
																		) !=
																			null ||
																			localStorage.getItem(
																				'admin'
																			) !=
																				'') &&
																		(localStorage.getItem(
																			'employee'
																		) ==
																			null ||
																			localStorage.getItem(
																				'employee'
																			) ==
																				'')
																	) {
																		changeEndingDate(
																			{
																				id: owner.id,
																				name: owner.fullname,
																				endingDate:
																					owner.endingDate,
																			}
																		);
																	}
																}
															}}
														>
															{owner.endingDate !=
															null
																? `${new Date(
																		owner.endingDate
																  ).getDate()}-${
																		new Date(
																			owner.endingDate
																		).getMonth() +
																		1
																  }-${new Date(
																		owner.endingDate
																  ).getFullYear()}`
																: 'Not specified'}
														</td>
														{/* <td>{owner.id}</td> */}
														<td
															className='center'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															{owner.approve ==
																1 &&
															owner.payed != 0
																? 'Approved'
																: 'Not approved'}
														</td>

														{(localStorage.getItem(
															'admin'
														) != null ||
															localStorage.getItem(
																'admin'
															) != '') &&
															(localStorage.getItem(
																'employee'
															) == null ||
																localStorage.getItem(
																	'employee'
																) == '') && (
																<td
																	className={
																		owner.isDisabled ==
																		'0'
																			? 'hover'
																			: ''
																	}
																	onClick={() => {
																		if (
																			owner.isDisabled ==
																			'0'
																		) {
																			changeStatus(
																				owner.id,
																				owner.approve
																			);
																		}
																	}}
																>
																	{owner.isDisabled ==
																	'0' ? (
																		owner.approve ==
																		0 ? (
																			<div className='success label-large'>
																				Approve
																			</div>
																		) : (
																			<div className='warning label-large'>
																				Disapprove
																			</div>
																		)
																	) : (
																		<>
																			Disabled
																		</>
																	)}
																</td>
															)}

														{(localStorage.getItem(
															'admin'
														) != null ||
															localStorage.getItem(
																'admin'
															) != '') &&
															(localStorage.getItem(
																'employee'
															) == null ||
																localStorage.getItem(
																	'employee'
																) == '') &&
															JSON.parse(
																localStorage.getItem(
																	'admin'
																)
															).superAdmin ==
																0 && (
																<td
																	className={
																		owner.isDisabled ==
																		'0'
																			? 'hover'
																			: ''
																	}
																	onClick={() => {
																		if (
																			owner.isDisabled ==
																			'0'
																		) {
																			changePaymentStatus(
																				owner.id,
																				owner.payed
																			);
																		}
																	}}
																>
																	{owner.isDisabled ==
																	'0' ? (
																		owner.payed ==
																		0 ? (
																			<div className='success label-large'>
																				Approve
																			</div>
																		) : (
																			<div className='warning label-large'>
																				Disapprove
																			</div>
																		)
																	) : (
																		<>
																			Disabled
																		</>
																	)}
																</td>
															)}

														<td
															className={
																owner.isDisabled ==
																'0'
																	? 'hover'
																	: ''
															}
															onClick={() => {
																if (
																	owner.isDisabled ==
																	'0'
																) {
																	viewVendors(
																		owner.id,
																		owner.shopName
																	);
																}
															}}
														>
															{owner.isDisabled ==
															'0' ? (
																<button className='text-button'>
																	Vendors
																</button>
															) : (
																'Disabled'
															)}
														</td>
														{/* <td
															className='hover'
															onClick={() => {
																openDialog(
																	index
																);
															}}
														>
															<button className='text-button'>
																Click to view
																contractor
															</button>
														</td> */}

														{(localStorage.getItem(
															'admin'
														) != null ||
															localStorage.getItem(
																'admin'
															) != '') &&
															(localStorage.getItem(
																'employee'
															) == null ||
																localStorage.getItem(
																	'employee'
																) == '') && (
																<td
																	className='hover'
																	onClick={() => {
																		toggleStatus(
																			owner.id,
																			owner.isDisabled,
																			owner.fullname
																		);
																	}}
																>
																	<div
																		className={
																			owner.isDisabled ==
																			'0'
																				? 'warning label-large'
																				: 'success label-large'
																		}
																	>
																		{owner.isDisabled ==
																		'0'
																			? 'Disable'
																			: 'Enable'}
																	</div>
																</td>
															)}

														{(localStorage.getItem(
															'admin'
														) != null ||
															localStorage.getItem(
																'admin'
															) != '') &&
															(localStorage.getItem(
																'employee'
															) == null ||
																localStorage.getItem(
																	'employee'
																) == '') && (
																<td
																	className='hover right-box'
																	onClick={() =>
																		deleteOwner(
																			owner.id,
																			owner.fullname
																		)
																	}
																>
																	<div className='warning label-large'>
																		{owner.isDeleted ==
																		0
																			? 'Delete'
																			: 'Deleted'}
																	</div>
																</td>
															)}
													</tr>
												</tbody>
											);
										}
									)}
									{/* </tbody > */}
								</table>
							</div>
							<Pagination
								getTotalItems={ownerTotalItems}
								getItemsPerPage={ownerItemsPerPage}
								getPageNumber={getPageNumberOwner}
								activePage={ownerActivePage}
							/>
							{/* <Pagination getTotalItems={totalItems} getItemsPerPage={itemsPerPage} /> */}
						</>
					) : (
						<></>
					)}

					{/* <Pagination tabelName='owners' activePage={currentPage} getPageNumber={getPageNumber} /> */}
					<Footer />
				</div>
			</div>

			{dialog && (
				<div className='dialog-container'>
					<button
						className='dialog-previous'
						onClick={previousDialog}
						disabled={dialogIndex === 0 ? true : false}
					>
						<ExpandMore />
					</button>

					<div
						open
						className='dialog'
						style={
							{
								// opacity:
								// 	dialog.isDisabled == '1' ||
								// 	dialog.isDeleted == '1'
								// 		? '0.7'
								// 		: '1',
							}
						}
					>
						<div
							className='dialog-top'
							style={{
								backgroundColor:
									dialog.isDisabled == '1' ||
									dialog.isDeleted == '1'
										? 'var(--error-container)'
										: 'var(--surface)',
							}}
						>
							<div className='title title-large'>
								{(ownerActivePage - 1) * ownerItemsPerPage +
									(dialogIndex + 1)}
								. Contractor details
								{dialog.isDisabled == '1' ||
								dialog.isDeleted == '1'
									? ' (INACTIVE)'
									: ''}
							</div>
							<button
								className='icon-button'
								onClick={closeDialog}
							>
								<Clear />
							</button>
						</div>

						<div className='dialog-content'>
							<div className='container-1'>
								<div className='profile-image'>
									<div className='container-label label-small'>
										Passport photo
									</div>
									<img
										className='img'
										src={dialog.photo}
										alt=''
									/>
								</div>
								<div className='id-image'>
									<div className='container-label label-small'>
										Id proof
									</div>
									{dialog.idPhoto.split('.').reverse()[0] ===
									'pdf' ? (
										<embed
											src={dialog.idPhoto}
											className='img'
										/>
									) : (
										<img
											className='img'
											src={dialog.idPhoto}
											alt='Id proof'
										/>
									)}
								</div>
							</div>
							<div className='container-2'>
								<div className='shop-image'>
									<div className='container-label label-small'>
										Stall photo
									</div>
									<img
										className='img'
										src={dialog.shopPhoto}
										alt=''
									/>
								</div>
								<div className='sign-image'>
									<div className='container-label label-small'>
										Signature
									</div>
									<img
										className='img'
										src={dialog.signPhoto}
										alt=''
									/>
								</div>
								<div className='stamp-image'>
									<div className='container-label label-small'>
										Stamp
									</div>
									<img
										className='img'
										src={dialog.stampPhoto}
										alt=''
									/>
								</div>
							</div>

							{dialog.reason == null && (
								<div
									className={
										calculateDateStatus(dialog.endingDate)
											.className
									}
								>
									{
										calculateDateStatus(dialog.endingDate)
											.message
									}
								</div>
							)}

							{/* {
                                JSON.parse(localStorage.getItem('admin')).superAdmin == 0 &&
                                <>
                                    <div className='label label-medium'>Payment screenshot</div>
                                    <a rel="noopener noreferrer" href={dialog.payScreenshot} target="_blank">{dialog.payScreenshot ? 'Click to view screenshot' : 'Screenshot not found'}</a>
                                </>
                            } */}

							<div className='container-3'>
								<div className='label label-medium'>
									Contractor fullname
								</div>
								<div className='full-name title-large'>
									{dialog.fullname}
								</div>

								<div className='label label-medium'>
									Stall location{' '}
									{(localStorage.getItem('admin') != null ||
										localStorage.getItem('admin') != '') &&
										(localStorage.getItem('employee') ==
											null ||
											localStorage.getItem('employee') ==
												'') && (
											<button
												className='icon-button'
												style={{
													display: 'inline-grid',
												}}
												onClick={() =>
													updateStallLocation(
														dialog.id,
														dialog.shopAddress
													)
												}
											>
												<Edit />
											</button>
										)}
								</div>
								<div className='id-number body-large'>
									{dialog.shopAddress}
								</div>

								<div className='label label-medium'>
									Station
								</div>
								<div className='id-number body-large'>
									{dialog.station}
								</div>

								<div className='label label-medium'>
									Stall type
								</div>
								<div className='id-number body-large'>
									{dialog.stallType ? dialog.stallType : '--'}
								</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Stall name
										</div>
										<div className='aadhaar-id body-large'>
											{dialog.shopName}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Stall start date
										</div>
										<div className='valid-upto-date body-large'>
											{dialog.startingDate != null
												? `${new Date(
														dialog.startingDate
												  ).getDate()}-${
														new Date(
															dialog.startingDate
														).getMonth() + 1
												  }-${new Date(
														dialog.startingDate
												  ).getFullYear()}`
												: 'Not specified'}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Stall validity date
										</div>
										<div className='valid-for body-large'>
											{dialog.endingDate != null
												? `${new Date(
														dialog.endingDate
												  ).getDate()}-${
														new Date(
															dialog.endingDate
														).getMonth() + 1
												  }-${new Date(
														dialog.endingDate
												  ).getFullYear()}`
												: 'Not specified'}
										</div>
									</div>
								</div>

								{dialog.reason != null ? (
									<div>
										<div className='label label-medium'>
											Reason for not having validity
										</div>
										<div className='unit body-large'>
											{dialog.reason}
										</div>
									</div>
								) : (
									<></>
								)}

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Email id
										</div>
										<div className='unit body-large'>
											{dialog.email}
										</div>
									</div>

									<div>
										<div className='label label-medium'>
											Mobile no.
										</div>
										<div className='issue-date body-large'>
											{dialog.mobile}
										</div>
									</div>

									{/* <div>
                                        <div className='label label-medium'>Username</div>
                                        <div className='designation body-large'>{dialog.username}</div>
                                    </div> */}

									{/* <div>
                                        <div className='label label-medium'>Mobile no.</div>
                                        <div className='issue-date body-large'>{dialog.mobile}</div>
                                    </div> */}
								</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Number of stalls
										</div>
										<div className='unit body-large'>
											{dialog.numberOfStall}
										</div>
									</div>
									<div>
										<div className='label label-medium'>
											Number of trolleys
										</div>
										<div className='unit body-large'>
											{dialog.numberOfTrolley}
										</div>
									</div>
									<div></div>
								</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>
											Number of staff
										</div>
										<div className='unit body-large'>
											{dialog.stallTrolleyVendorCount}
										</div>
									</div>
									<div>
										<div className='label label-medium'>
											PF vendor
										</div>
										<div className='unit body-large'>
											{dialog.pfVendorCount}
										</div>
									</div>
									<div>
										<div className='label label-medium'>
											Supporting staff
										</div>
										<div className='unit body-large'>
											{dialog.supportingStaff}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<button
						className='dialog-next'
						onClick={nextDialog}
						disabled={
							dialogIndex ===
							owners[ownerActivePage - 1].length - 1
								? true
								: false
						}
					>
						<ExpandLess />
					</button>
				</div>
			)}

			{loader && <Loading />}
		</>
	);
};

export default Owners;

//------------------------------------------------------------------------------------
