import './ProfileAvatar.scss';

import { useEffect, useState } from 'react';

import { Clear } from '../Icons/Icons';
import auth from '../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../scripts/baseUrl';
import theme from '../../scripts/theme';
import { useNavigate } from 'react-router-dom';

const ProfileAvatar = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [admin, setAdmin] = useState({});
	const [employee, setEmployee] = useState({});
	const [initialCharacter, setInitialCharacter] = useState('');
	const navigate = useNavigate();

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const getAdmin = async () => {
		const admin = JSON.parse(localStorage.getItem('admin'));
		try {
			const res = await axios.get(`${baseUrl}/admins/${admin.id}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				setAdmin(res.data);
				setInitialCharacter(res.data.fullname[0]);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getEmployee = async () => {
		const employee = JSON.parse(localStorage.getItem('employee'));
		try {
			const res = await axios.get(
				`${baseUrl}/employee/specific/${employee.id}`
			);
			if (res.data) {
				setEmployee({ ...res.data[0] });
				setInitialCharacter(res.data[0].fullname[0]);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const logout = async () => {
		// if (window.confirm("Are you sure you want to logout?")) {
		if (
			(localStorage.getItem('admin') != null ||
				localStorage.getItem('admin') != '') &&
			(localStorage.getItem('employee') == null ||
				localStorage.getItem('employee') == '')
		) {
			const admin = JSON.parse(localStorage.getItem('admin'));
			try {
				await axios.get(`${baseUrl}/logout/${admin.id}`);
			} catch (err) {
				console.log(err);
			}
			localStorage.clear();
			navigate('/login');
			theme.set();
		}

		if (
			(localStorage.getItem('admin') == null ||
				localStorage.getItem('admin') == '') &&
			(localStorage.getItem('employee') != null ||
				localStorage.getItem('employee') != '')
		) {
			// localStorage.setItem('employee', '');
			localStorage.clear();
			navigate('/login', { replace: true });
		}
		// }
	};

	useEffect(() => {
		if (
			(localStorage.getItem('admin') != null ||
				localStorage.getItem('admin') != '') &&
			(localStorage.getItem('employee') == null ||
				localStorage.getItem('employee') == '')
		) {
			getAdmin();
		}
		if (
			(localStorage.getItem('admin') == null ||
				localStorage.getItem('admin') == '') &&
			(localStorage.getItem('employee') != null ||
				localStorage.getItem('employee') != '')
		) {
			getEmployee();
		}
	}, []);

	return (
		<>
			{(localStorage.getItem('admin') != null ||
				localStorage.getItem('admin') != '') &&
			(localStorage.getItem('employee') == null ||
				localStorage.getItem('employee') == '') ? (
				<>
					<button
						className='avatar-button label-large'
						onClick={() => {
							setIsDialogOpen(true);
						}}
					>
						{initialCharacter}
					</button>
					{isDialogOpen && (
						<div className='avatar-dialog-container'>
							<div className='avatar-dialog'>
								<button
									className='icon-button close-button'
									onClick={() => {
										setIsDialogOpen(false);
									}}
								>
									<Clear />
								</button>
								<div className='top'>
									<div className='avatar headline-large'>
										{initialCharacter}
									</div>
									<div className='name title-large'>
										{admin.fullname}
									</div>
								</div>
								<div className='foot'>
									<button
										className='filled-tonal-button'
										onClick={() => {
											navigate('/dashboard');
											setIsDialogOpen(false);
										}}
									>
										Dashboard
									</button>
									<button
										className='outlined-button'
										onClick={logout}
									>
										Logout
									</button>
								</div>
							</div>
						</div>
					)}
				</>
			) : (
				<>
					<button
						className='avatar-button label-large'
						onClick={() => {
							setIsDialogOpen(true);
						}}
					>
						{initialCharacter}
					</button>
					{isDialogOpen && (
						<div className='avatar-dialog-container'>
							<div className='avatar-dialog'>
								<button
									className='icon-button close-button'
									onClick={() => {
										setIsDialogOpen(false);
									}}
								>
									<Clear />
								</button>
								<div className='top'>
									<div className='avatar headline-large'>
										{initialCharacter}
									</div>
									<div className='name title-large'>
										{employee.fullname}
									</div>
								</div>
								<div className='foot'>
									<button
										className='filled-tonal-button'
										onClick={() => {
											navigate('/dashboard');
											setIsDialogOpen(false);
										}}
									>
										My profile
									</button>
									<button
										className='outlined-button'
										onClick={logout}
									>
										Logout
									</button>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ProfileAvatar;
