//---------------------------------------------------------

const filterOwnersByStation = (mainArray) => {
    let employeeStation = JSON.parse(localStorage.getItem('employee')).station;
    let employeeDesignation = JSON.parse(localStorage.getItem('employee')).designation;
    let allowners = [...mainArray];
    let copyArray = [];

    if (employeeDesignation == 'CSCI') {
        if (
            employeeStation == 'Bhusawal-Jn' ||
            employeeStation == 'Jalgaon-Jn' ||
            employeeStation == 'Malkapur' ||
            employeeStation == 'Nandura'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Bhusawal-Jn' ||
                    owner.station == 'Jalgaon-Jn' ||
                    owner.station == 'Malkapur' ||
                    owner.station == 'Nandura'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else if (
            employeeStation == 'Akola-Jn' ||
            employeeStation == 'Shegaon'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Akola-Jn' ||
                    owner.station == 'Shegaon'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else if (
            employeeStation == 'Nashik Road' ||
            employeeStation == 'Niphad' ||
            employeeStation == 'Devlali'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Nashik Road' ||
                    owner.station == 'Niphad' ||
                    owner.station == 'Devlali'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else if (
            employeeStation == 'Manmad-Jn' ||
            employeeStation == 'Nandgaon' ||
            employeeStation == 'Lasalgaon'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Manmad-Jn' ||
                    owner.station == 'Nandgaon' ||
                    owner.station == 'Lasalgaon'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else if (
            employeeStation == 'Khandwa' ||
            employeeStation == 'Burhanpur' ||
            employeeStation == 'Nepanagar' ||
            employeeStation == 'Raver'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Burhanpur' ||
                    owner.station == 'Khandwa' ||
                    owner.station == 'Nepanagar' ||
                    owner.station == 'Raver'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else if (
            employeeStation == 'Badnera-Jn' ||
            employeeStation == 'Murtizapur' ||
            employeeStation == 'Amravati'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Badnera-Jn' ||
                    owner.station == 'Murtizapur' ||
                    owner.station == 'Amravati'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else if (
            employeeStation == 'Chalisgaon-Jn' ||
            employeeStation == 'Pachora-Jn' ||
            employeeStation == 'Dhule'
        ) {
            for (let owner of allowners) {
                if (
                    owner.station == 'Chalisgaon-Jn' ||
                    owner.station == 'Pachora-Jn' ||
                    owner.station == 'Dhule'
                ) {
                    copyArray.push(owner);
                }
            }
        }
        else {
            for (let owner of allowners) {
                if (owner.station == employeeStation) {
                    copyArray.push(owner);
                }
            }
        }
    } else {
        for (let owner of allowners) {
            if (owner.station == employeeStation) {
                copyArray.push(owner);
            }
        }
    }
    return copyArray;
}

export default filterOwnersByStation;

//---------------------------------------------------------