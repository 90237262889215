//------------------------------------------------------------------------------------

import './Navigation.scss';

import {
	AddBox,
	BadgeIcon,
	Home,
	ListAlt,
	People,
	SupervisorAccount,
} from '../Icons/Icons';
import { Link, useLocation } from 'react-router-dom';

//------------------------------------------------------------------------------------

// export let buttons = [
//     {
//         label: 'Dashboard',
//         link: '/dashboard',
//         isActive: false,
//     },
//     {
//         label: 'Approvals',
//         link: '/approvals',
//         isActive: false,
//     },
//     {
//         label: 'Owners',
//         link: '/owners',
//         isActive: false,
//     },
//     {
//         label: 'Vendors',
//         link: '/vendors',
//         isActive: false,
//     },
//     {
//         label: 'Employees',
//         link: '/employees',
//         isActive: false,
//     },
//     // {
//     //     label: 'Payments',
//     //     link: '/payments',
//     //     isActive: false,
//     // },
// ];

const Navigation = () => {
	let buttons;

	// if (JSON.parse(localStorage.getItem('admin')).superAdmin == 0) {
	//     buttons = [
	//         {
	//             label: 'Dashboard',
	//             link: '/dashboard',
	//             isActive: false,
	//         },
	//         // {
	//         //     label: 'Approvals',
	//         //     link: '/approvals',
	//         //     isActive: false,
	//         // },
	//         {
	//             label: 'Contractors',
	//             link: '/owners',
	//             isActive: false,
	//         },
	//         {
	//             label: 'Vendors',
	//             link: '/vendors',
	//             isActive: false,
	//         },
	//         {
	//             label: 'Employees',
	//             link: '/employees',
	//             isActive: false,
	//         },
	//         // {
	//         //     label: 'Payments',
	//         //     link: '/payments',
	//         //     isActive: false,
	//         // },
	//         {
	//             label: 'Add',
	//             link: '/add',
	//             isActive: false,
	//         },
	//         {
	//             label: 'View logs',
	//             link: '/logs',
	//             isActive: false,
	//         },
	//     ];
	// } else {
	//     buttons = [
	//         {
	//             label: 'Dashboard',
	//             link: '/dashboard',
	//             isActive: false,
	//         },
	//         // {
	//         //     label: 'Approvals',
	//         //     link: '/approvals',
	//         //     isActive: false,
	//         // },
	//         {
	//             label: 'Contractors',
	//             link: '/owners',
	//             isActive: false,
	//         },
	//         {
	//             label: 'Vendors',
	//             link: '/vendors',
	//             isActive: false,
	//         },
	//         {
	//             label: 'Employees',
	//             link: '/employees',
	//             isActive: false,
	//         },
	//         {
	//             label: 'Add',
	//             link: '/add',
	//             isActive: false,
	//         },
	//         {
	//             label: 'View logs',
	//             link: '/logs',
	//             isActive: false,
	//         },
	//     ];
	// }

	if (
		(localStorage.getItem('admin') != null ||
			localStorage.getItem('admin') != '') &&
		(localStorage.getItem('employee') == null ||
			localStorage.getItem('employee') == '')
	) {
		if (JSON.parse(localStorage.getItem('admin')).superAdmin == 0) {
			buttons = [
				{
					label: 'Dashboard',
					icon: <Home />,
					link: '/dashboard',
					isActive: false,
				},
				{
					label: 'Contractors',
					icon: <SupervisorAccount />,
					link: '/owners',
					isActive: false,
				},
				{
					label: 'Vendors',
					icon: <People />,
					link: '/vendors',
					isActive: false,
				},
				{
					label: 'Employees',
					icon: <BadgeIcon />,
					link: '/employees',
					isActive: false,
				},
				// {
				//     label: 'Payments',
				//     link: '/payments',
				//     isActive: false,
				// },
				{
					label: 'Add',
					icon: <AddBox />,
					link: '/add',
					isActive: false,
				},
				{
					label: 'View logs',
					icon: <ListAlt />,
					link: '/logs',
					isActive: false,
				},
			];
		} else {
			buttons = [
				{
					label: 'Dashboard',
					icon: <Home />,
					link: '/dashboard',
					isActive: false,
				},
				{
					label: 'Contractors',
					icon: <SupervisorAccount />,
					link: '/owners',
					isActive: false,
				},
				{
					label: 'Vendors',
					icon: <People />,
					link: '/vendors',
					isActive: false,
				},
				{
					label: 'Employees',
					icon: <BadgeIcon />,
					link: '/employees',
					isActive: false,
				},
			];
		}
	}

	if (
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
	) {
		buttons = [
			{
				label: 'Dashboard',
				icon: <Home />,
				link: '/dashboard',
				isActive: false,
			},
			{
				label: 'Contractors',
				icon: <SupervisorAccount />,
				link: '/owners',
				isActive: false,
			},
			{
				label: 'Vendors',
				icon: <People />,
				link: '/vendors',
				isActive: false,
			},
		];
	}

	const currentScreen = useLocation().pathname;
	for (let button of buttons) {
		if (currentScreen === button.link) {
			button.isActive = true;
		} else {
			button.isActive = false;
		}
	}

	return (
		<>
			{window.innerWidth > 840 && (
				<div className="side-navigation">
					{buttons.map((button, index) => {
						const className = button.isActive
							? 'navigation-button active'
							: 'navigation-button';
						return (
							<Link
								to={button.link}
								key={index}
							>
								<button className={className}>
									{button.icon}
									{button.label}
								</button>
							</Link>
						);
					})}
					{/* <a href='http://msofts.in/' className='development label-small' target='_blank'>Designed & developed By <br />M-SOFTS DEVELOPMENT, Bhusawal</a> */}
				</div>
			)}
		</>
	);
};

export default Navigation;

//------------------------------------------------------------------------------------
