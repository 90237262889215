//------------------------------------------------------------------------------------

import '../external.scss';

import { Link, Navigate, json } from 'react-router-dom';
import { Visibility, VisibilityOff } from '../../../components/Icons/Icons.js';
import { encryptData } from '../../../scripts/encryptDecrypt';
import { useEffect, useState } from 'react';
import Footer from '../../../components/Footer/Footer';
import Loading from '../../../components/Loading/Loading';
import Logo from '../../../assets/logo.png';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import checkLoginStatus from '../../../scripts/checkLoginStatus';
import theme from '../../../scripts/theme';

//------------------------------------------------------------------------------------

const Login = () => {
	document.title = `Login • Bhusawal Division Catering Vendor Id`;

	const [validLogin, setValidLogin] = useState(false);
	const [admin, setAdmin] = useState({
		email: '',
		password: '',
	});
	const [employee, setEmployee] = useState({
		mobile: '',
		password: '',
	});
	const [loader, setLoader] = useState(false);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [adminLogin, setAdminLogin] = useState(true);

	useEffect(() => {
		/* const body = document.getElementsByTagName('body')[0];
        const onEnter = document.getElementById('on-enter');
        body.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                onEnter.click();
            }
        }); */

		status();

		// blocking some keys for username
		const username = document.getElementById('username');
		username.addEventListener('keypress', (event) => {
			var regex = new RegExp('^[a-zA-Z0-9@.]+$');
			var key = String.fromCharCode(
				!event.charCode ? event.which : event.charCode
			);
			if (!regex.test(key)) {
				event.preventDefault();
				return false;
			}
		});
	}, []);

	// useEffect(() => {
	//     // blocking some keys for username
	//     const username = document.getElementById('username');
	//     username.addEventListener('keypress', (event) => {
	//         var regex = new RegExp("^[a-zA-Z0-9@.]+$");
	//         var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
	//         if (!regex.test(key)) {
	//             event.preventDefault();
	//             return false;
	//         }
	//     });
	// }, [adminLogin]);

	const status = async () => {
		setLoader(true);
		if (await checkLoginStatus()) {
			setValidLogin(true);
		} else {
			setValidLogin(false);
		}
		setLoader(false);
	};

	const login = async () => {
		if (adminLogin) {
			try {
				setLoader(true);

				let thisAdmin = { ...admin };
				thisAdmin.password = encryptData(admin.password);

				const res = await axios.post(`${baseUrl}/login`, thisAdmin);

				if (res.data.id) {
					localStorage.clear();
					localStorage.setItem('admin', JSON.stringify(res.data));
					// localStorage.setItem('admin-accesstoken', JSON.stringify(res.data.accessToken));
					setValidLogin(true);
					theme.set();
				} else {
					alert('Invalid user, please try again.');
				}
			} catch (err) {
				console.log(err);
			}
			setLoader(false);
		} else {
			try {
				setLoader(true);

				let thisEmployee = { ...employee };
				thisEmployee.password = encryptData(employee.password);

				const res = await axios.post(
					`${baseUrl}/employee/web/login`,
					thisEmployee
				);

				if (res.data.id) {
					localStorage.clear();
					localStorage.setItem('employee', JSON.stringify(res.data));
					setValidLogin(true);
					theme.set();
				} else if (res.data.message == 'DISABLED') {
					localStorage.clear();
					alert('Your account is not active.');
				} else {
					localStorage.clear();
					alert('Invalid user, please try again.');
				}
			} catch (err) {
				console.log(err);
			}
			setLoader(false);
		}
	};

	const validate = () => {
		let username = document.getElementById('username');
		let usernameHint = document.getElementById('username-hint');
		let password = document.getElementById('password');
		let passwordHint = document.getElementById('password-hint');

		let isUsernameValid = false;
		let isPasswordValid = false;

		//username validation
		if (username.value.length == 0) {
			username.parentElement?.classList.add('error');
			usernameHint.innerHTML = 'Please enter email';
			isUsernameValid = false;
		} else {
			username.parentElement?.classList.remove('error');
			usernameHint.innerHTML = '&nbsp;';
			isUsernameValid = true;
		}

		//password validation
		const passwordRegex =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
		if (password.value.match(passwordRegex)) {
			password.parentElement?.classList.remove('error');
			passwordHint.innerHTML = '&nbsp;';
			isPasswordValid = true;
		} else {
			password.parentElement?.classList.add('error');
			passwordHint.innerHTML =
				'Please enter password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';
			isPasswordValid = false;
		}

		//if valid
		if (isUsernameValid && isPasswordValid) {
			login();
		} else {
			setValidLogin(false);
		}
	};

	const validateEmployeeCreds = () => {
		let mobile = document.getElementById('mobile');
		let mobileHint = document.getElementById('mobile-hint');
		let password = document.getElementById('password');
		let passwordHint = document.getElementById('password-hint');

		let ismobileValid = false;
		let isPasswordValid = false;

		//mobile validation
		if (mobile.value.length == 0) {
			mobile.parentElement?.classList.add('error');
			mobileHint.innerHTML = 'Please enter mobile no.';
			ismobileValid = false;
		} else if (mobile.value.length != 10) {
			mobile.parentElement?.classList.add('error');
			mobileHint.innerHTML = 'Please enter valid mobile no.';
			ismobileValid = false;
		} else {
			mobile.parentElement?.classList.remove('error');
			mobileHint.innerHTML = '&nbsp;';
			ismobileValid = true;
		}

		//password validation
		const passwordRegex =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
		// if (password.value.match(passwordRegex)) {
		//     password.parentElement?.classList.remove('error');
		//     passwordHint.innerHTML = '&nbsp;';
		//     isPasswordValid = true;
		// }
		if (password.value.length != 0) {
			password.parentElement?.classList.remove('error');
			passwordHint.innerHTML = '&nbsp;';
			isPasswordValid = true;
		} else {
			password.parentElement?.classList.add('error');
			passwordHint.innerHTML =
				'Please enter password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';
			isPasswordValid = false;
		}

		//if valid
		if (ismobileValid && isPasswordValid) {
			login();
		} else {
			setValidLogin(false);
		}
	};

	const handleChange = (e) => {
		setAdmin((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleEmployeeChange = (e) => {
		setEmployee((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const changeLoginUser = () => {
		setAdminLogin((prev) => !prev);
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	return (
		<>
			<div className='external-screen'>
				<div className='frame'>
					<div className='outlined-card'>
						<form onKeyUp={(event) => enterClick(event)}>
							<div className='form-app-headline'>
								<div className='app-logo'>
									<img src={Logo} />
								</div>
								<div className='app-title title-small'>
									Admin Panel
								</div>
							</div>

							<div className='app-title headline-large'>
								Login
							</div>

							<div className='login-toggle-container'>
								<>
									<div
										className={
											adminLogin
												? 'user label-large active'
												: 'user label-large'
										}
										onClick={() => {
											if (!adminLogin) {
												changeLoginUser();
											}
										}}
									>
										Login as admin
									</div>
									<div
										className={
											!adminLogin
												? 'user label-large active'
												: 'user label-large'
										}
										onClick={() => {
											if (adminLogin) {
												changeLoginUser();
											}
										}}
									>
										Login as employee
									</div>
								</>
							</div>

							{adminLogin ? (
								<>
									<div className='label label-medium'>
										Enter your email
									</div>
									<div className='input-container'>
										<input
											type='email'
											id='username'
											name='email'
											className='inputfield'
											placeholder='Email'
											maxLength={200}
											onChange={handleChange}
										/>
										<div
											id='username-hint'
											className='input-hints'
										>
											&nbsp;
										</div>
									</div>

									<div className='label label-medium'>
										Enter your password
									</div>
									<div className='input-container password-inputfield'>
										<input
											type={
												passwordVisibility
													? 'text'
													: 'password'
											}
											id='password'
											name='password'
											className='inputfield'
											placeholder='Password'
											maxLength={200}
											onChange={(e) => {
												handleChange(e);
												handleEmployeeChange(e);
											}}
										/>
										<div
											id='password-hint'
											className='input-hints'
										>
											&nbsp;
										</div>
										<div className='password-visibility'>
											<button
												type='button'
												className='icon-button'
												onClick={() =>
													setPasswordVisibility(
														(prev) => !prev
													)
												}
											>
												{passwordVisibility ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</button>
										</div>
									</div>

									<div className='bottom-buttons'>
										<Link to='/register'>
											<button
												type='button'
												className='text-button'
											>
												Don't have admin account?
												Register here
											</button>
										</Link>
										<button
											type='button'
											id='on-enter'
											className='filled-button'
											onClick={validate}
										>
											Login
										</button>
									</div>
								</>
							) : (
								<>
									<div className='label label-medium'>
										Enter your mobile no. which is
										registered with mobile app Bhusawal Rail
										Vendor
									</div>
									<div className='input-container'>
										<input
											type='number'
											id='mobile'
											name='mobile'
											className='inputfield'
											placeholder='Mobile no.'
											maxLength={10}
											onChange={handleEmployeeChange}
										/>
										<div
											id='mobile-hint'
											className='input-hints'
										>
											&nbsp;
										</div>
									</div>

									<div className='label label-medium'>
										Enter your password which is registered
										with mobile app Bhusawal Rail Vendor
									</div>
									<div className='input-container password-inputfield'>
										<input
											type={
												passwordVisibility
													? 'text'
													: 'password'
											}
											id='password'
											name='password'
											className='inputfield'
											placeholder='Password'
											maxLength={200}
											onChange={handleEmployeeChange}
										/>
										<div
											id='password-hint'
											className='input-hints'
										>
											&nbsp;
										</div>
										<div className='password-visibility'>
											<button
												type='button'
												className='icon-button'
												onClick={() =>
													setPasswordVisibility(
														(prev) => !prev
													)
												}
											>
												{passwordVisibility ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</button>
										</div>
									</div>

									<div className='bottom-buttons'>
										<div></div>
										<button
											type='button'
											id='on-enter'
											className='filled-button'
											onClick={validateEmployeeCreds}
										>
											Login
										</button>
									</div>
								</>
							)}
						</form>
					</div>
				</div>
			</div>
			{loader && <Loading />}
			{validLogin && (
				<Navigate
					replace
					to='/dashboard'
				/>
			)}

			<Footer />
		</>
	);
};

export default Login;

//------------------------------------------------------------------------------------
