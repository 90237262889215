//------------------------------------------------------------------------------------

/* import Qr from "./screens/internal/Qr/Qr"; */
import './styles/globals.scss';
import './styles/colors.scss';
import './styles/typography.scss';
import './styles/buttons.scss';
import './styles/inputs.scss';
import './styles/cards.scss';
import './styles/keyframes.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Add from './screens/internal/Add/Add';
import AddOwner from './screens/external/NewRegister/Owner/Owner';
import AddVendor from './screens/external/NewRegister/Vendor/Vendor';
import Approvals from './screens/internal/Approvals/Approvals';
import Dashboard from './screens/internal/Dashboard/Dashboard';
import Employees from './screens/internal/Employees/Employees';
import ForgetPassword from './screens/external/ForgetPassword/ForgetPassword';
import Intro from './screens/external/Intro/Intro';
import Login from './screens/external/Login/Login';
import Logs from './screens/internal/Logs/Logs';
import NotFound from './screens/external/NotFound/NotFound';
import Notifications from './screens/internal/Notifications/Notifications';
import Offline from './screens/external/Offline/Offline';
import OnlineStatus from './components/OnlineStatus/OnlineStatus';
import Otp from './screens/external/Otp/Otp';
import Owners from './screens/internal/Owners/Owners';
import Payment from './screens/internal/Payment/Payment';
import Register from './screens/external/Register/Register';
import ResetPassword from './screens/external/ResetPassword/ResetPassword';
import Vendors from './screens/internal/Vendors/Vendors';
import axios from 'axios';
import baseUrl from './scripts/baseUrl';
import tableScroll from './scripts/tableScroll';
import theme from './scripts/theme';

import io from 'socket.io-client';
export const socket = io.connect(baseUrl);

//------------------------------------------------------------------------------------

const App = () => {
	useEffect(() => {
		theme.set();
		tableScroll.horizontal('table-container');
		tableScroll.vertical('side-container');
	}, []);

	return (
		<div className='app'>
			<BrowserRouter>
				<Routes>
					<Route
						index
						element={<Login />}
					/>
					<Route
						path='/login'
						element={<Login />}
					/>
					<Route
						path='/register'
						element={<Register />}
					/>

					<Route
						path='/dashboard'
						element={<Dashboard />}
					/>
					<Route
						path='/owners'
						element={<Owners />}
					/>
					<Route
						path='/vendors'
						element={<Vendors />}
					/>
					<Route
						path='/owners/vendors'
						element={<Vendors />}
					/>
					<Route
						path='/employees'
						element={<Employees />}
					/>

					<Route
						path='/logs'
						element={<Logs />}
					/>
					<Route
						path='/add'
						element={<Add />}
					/>
					<Route
						path='/notifications'
						element={<Notifications />}
					/>

					<Route
						path='/add/owner'
						element={<AddOwner />}
					/>
					<Route
						path='/add/vendor'
						element={<AddVendor />}
					/>

					<Route
						path='*'
						element={<NotFound />}
					/>

					{/* <Route path="/forget-password" element={<ForgetPassword />} /> */}
					{/* <Route path="/otp" element={<Otp />} /> */}
					{/* <Route path="/reset-password" element={<ResetPassword />} /> */}
					{/* <Route path="/payments" element={<Payment />} /> */}
					{/* <Route path="/approvals" element={<Approvals />} /> */}
				</Routes>
			</BrowserRouter>
			<OnlineStatus />
		</div>
	);
};

export default App;

//------------------------------------------------------------------------------------
