//------------------------------------------------------------------------------------

export const Menu = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Clear = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const ExpandLess = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const ExpandMore = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M24 24H0V0h24v24z"
				fill="none"
				opacity=".87"
			/>
			<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const PersonAdd = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm-6 4c.22-.72 3.31-2 6-2 2.7 0 5.8 1.29 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const AddPhoto = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Badge = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
		>
			<g>
				<rect
					fill="none"
					height="24"
					width="24"
					y="0"
				/>
			</g>
			<g>
				<g>
					<rect
						height="1.5"
						width="4"
						x="14"
						y="12"
					/>
					<rect
						height="1.5"
						width="4"
						x="14"
						y="15"
					/>
					<path d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M11,7V4h2v3v2h-2V7z M20,20H4V9h5c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2h5V20z" />
					<circle
						cx="9"
						cy="13.5"
						r="1.5"
					/>
					<path d="M11.08,16.18C10.44,15.9,9.74,15.75,9,15.75s-1.44,0.15-2.08,0.43C6.36,16.42,6,16.96,6,17.57V18h6v-0.43 C12,16.96,11.64,16.42,11.08,16.18z" />
				</g>
			</g>
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Visibility = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const VisibilityOff = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
		>
			<path
				d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M12 6c3.79 0 7.17 2.13 8.82 5.5-.59 1.22-1.42 2.27-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68C3.06 7.83 1.77 9.53 1 11.5 2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02-1.38 0-2.5-1.12-2.5-2.5 0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75c-.23.55-.36 1.15-.36 1.78 0 2.48 2.02 4.5 4.5 4.5.63 0 1.23-.13 1.77-.36l.98.98c-.88.24-1.8.38-2.75.38-3.79 0-7.17-2.13-8.82-5.5.7-1.43 1.72-2.61 2.93-3.53z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Notifications = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Done = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const LightMode = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<rect
				fill="none"
				height="24"
				width="24"
			/>
			<path d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const DarkMode = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<rect
				fill="none"
				height="24"
				width="24"
			/>
			<path d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const GridView = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<g>
				<rect
					fill="none"
					height="24"
					width="24"
				/>
			</g>
			<g>
				<g>
					<g>
						<path d="M3,3v8h8V3H3z M9,9H5V5h4V9z M3,13v8h8v-8H3z M9,19H5v-4h4V19z M13,3v8h8V3H13z M19,9h-4V5h4V9z M13,13v8h8v-8H13z M19,19h-4v-4h4V19z" />
					</g>
				</g>
			</g>
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const SupervisorAccount = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm.05 10H4.77c.99-.5 2.7-1 4.23-1 .11 0 .23.01.34.01.34-.73.93-1.33 1.64-1.81-.73-.13-1.42-.2-1.98-.2-2.34 0-7 1.17-7 3.5V19h7v-1.5c0-.17.02-.34.05-.5zm7.45-2.5c-1.84 0-5.5 1.01-5.5 3V19h11v-1.5c0-1.99-3.66-3-5.5-3zm1.21-1.82c.76-.43 1.29-1.24 1.29-2.18C19 9.12 17.88 8 16.5 8S14 9.12 14 10.5c0 .94.53 1.75 1.29 2.18.36.2.77.32 1.21.32s.85-.12 1.21-.32z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const People = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const BadgeIcon = () => {
	return (
		<svg
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<g>
				<rect
					fill="none"
					height="24"
					width="24"
					y="0"
				/>
			</g>
			<g>
				<g>
					<rect
						height="1.5"
						width="4"
						x="14"
						y="12"
					/>
					<rect
						height="1.5"
						width="4"
						x="14"
						y="15"
					/>
					<path d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M11,7V4h2v3v2h-2V7z M20,20H4V9h5c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2h5V20z" />
					<circle
						cx="9"
						cy="13.5"
						r="1.5"
					/>
					<path d="M11.08,16.18C10.44,15.9,9.74,15.75,9,15.75s-1.44,0.15-2.08,0.43C6.36,16.42,6,16.96,6,17.57V18h6v-0.43 C12,16.96,11.64,16.42,11.08,16.18z" />
				</g>
			</g>
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const ListAlt = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M11 7h6v2h-6zm0 4h6v2h-6zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7zM20.1 3H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM19 19H5V5h14v14z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const AddBox = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-8-2h2v-4h4v-2h-4V7h-2v4H7v2h4z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Home = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Refresh = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const Edit = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------

export const CheckCircle = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#000000"
		>
			<path
				d="M0 0h24v24H0V0z"
				fill="none"
			/>
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
		</svg>
	);
};

//------------------------------------------------------------------------------------
