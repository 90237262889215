//------------------------------------------------------------------------------------

import '../internal.scss';

import {
	CheckCircle,
	Clear,
	ExpandLess,
	ExpandMore,
	Refresh,
} from '../../../components/Icons/Icons';
import { useEffect, useState } from 'react';

import Buffer from '../../../components/Buffer/Buffer';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import Loading from '../../../components/Loading/Loading';
import Navigation from '../../../components/Navigation/Navigation';
import Pagination from '../../../components/Pagination/Pagination';
import Toggle from '../../../components/Toggle/Toggle';
import auth from '../../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import hightlight from '../../../scripts/hightlighter';
import sortTable from '../../../scripts/sort';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';

// import employeesDesignations from '../../../scripts/employeesDesignations';

//------------------------------------------------------------------------------------

let searchTextField;

const Employees = () => {
	unauthorizedRedirection();
	document.title = `Employees • Bhusawal Division Catering Vendor Id`;

	let accessToken =
		(localStorage.getItem('admin') == null ||
			localStorage.getItem('admin') == '') &&
		(localStorage.getItem('employee') != null ||
			localStorage.getItem('employee') != '')
			? JSON.parse(localStorage.getItem('employee')).accessToken
			: JSON.parse(localStorage.getItem('admin')).accessToken;

	const [employees, setEmployees] = useState([]);
	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);
	const [loader, setLoader] = useState(false);
	const [employeesDesignations, setEmployeesDesignations] = useState([]);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [fetchComplete, setFetchComplete] = useState(false);

	const [employeesTotalItems, setEmployeesTotalItems] = useState(0);
	const [employeesItemsPerPage, setEmployeesItemsPerPage] = useState(20);
	const [employeesActivePage, setEmployeesActivePage] = useState(1);

	const getEmployees = async () => {
		setIsRefreshing(true);

		try {
			const res = await axios.get(`${baseUrl}/employees`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				// const arrayData = [];
				// for (const data of res.data) {
				// 	arrayData.unshift(data);
				// }
				// setEmployees([...arrayData]);

				const arrayData = [];
				for (const data of res.data) {
					arrayData.unshift(data);
				}

				let array = arrayData;
				let mainArray = [];

				let n = 0;
				for (
					let i = 0;
					i < Math.ceil(array.length / employeesItemsPerPage);
					i++
				) {
					mainArray.push([]);
					for (let a = 0; a < employeesItemsPerPage; a++) {
						if (array[n] != undefined) {
							mainArray[i].push(array[n]);
							n++;
						}
					}
				}

				setEmployees(mainArray);
				setEmployeesTotalItems(res.data.length);

				setNotFound(false);
			} else {
				setNotFound(true);
			}
			setBuffer(false);
			setTimeout(() => {
				setTimeout(() => {
					setFetchComplete(false);
				}, 2000);
				setFetchComplete(true);
				setIsRefreshing(false);
			}, 1000);
		} catch (err) {
			console.log(err);
		}
	};

	const getEmployeesDesignations = async () => {
		try {
			const res = await axios.get(`${baseUrl}/designations/employees`);
			setEmployeesDesignations(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getPageNumberEmployees = (number) => {
		setEmployeesActivePage(number);
	};

	useEffect(() => {
		getEmployees();
		getEmployeesDesignations();

		// let callData = setInterval(() => {
		//     getEmployees();
		//     getEmployeesDesignations();
		// }, 1000 * 5);

		// return () => {
		//     clearInterval(callData);
		// }

		// setTimeout(() => {
		//     const searchInput = document.getElementById('search-input');
		//     const onEnter = document.getElementById('on-enter');
		//     searchInput.addEventListener("keypress", function (event) {
		//         if (event.key === "Enter") {
		//             event.preventDefault();
		//             onEnter.click();
		//         }
		//     });
		// }, 500);
	}, []);

	const sort = async (table, column, order) => {
		setLoader(true);
		let thisArray = await sortTable(table, column, order);
		// const arrayData = [];
		// for (const data of array) {
		// 	arrayData.unshift(data);
		// }
		// setEmployees([...arrayData]);

		const arrayData = [];
		for (const data of thisArray) {
			arrayData.unshift(data);
		}

		let array = arrayData;
		let mainArray = [];

		let n = 0;
		for (let i = 0; i < Math.ceil(array.length / employeesItemsPerPage); i++) {
			mainArray.push([]);
			for (let a = 0; a < employeesItemsPerPage; a++) {
				if (array[n] != undefined) {
					mainArray[i].push(array[n]);
					n++;
				}
			}
		}

		setEmployees(mainArray);
		setEmployeesTotalItems(thisArray.length);

		setLoader(false);
	};

	const [searchText, setSearchText] = useState({
		search: '',
	});

	const handleChange = (e) => {
		setSearchText((prev) => ({ ...prev, search: e.target.value }));
	};

	const search = async () => {
		if (searchText.search.length !== 0 && searchText.search.length < 255) {
			try {
				setNotFound(true);
				setBuffer(true);
				const res = await axios.post(
					`${baseUrl}/employees/search`,
					searchText,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					if (res.data.length === 0) {
						setNotFound(false);
						setBuffer(false);
						setTimeout(() => {
							alert(`No results for "${searchText.search}".`);
						}, 10);
						return;
					}

					// const arrayData = [];
					// for (const data of res.data) {
					// 	arrayData.unshift(data);
					// }
					// setEmployees([...arrayData]);

					const arrayData = [];
					for (const data of res.data) {
						arrayData.unshift(data);
					}

					let array = arrayData;
					let mainArray = [];

					let n = 0;
					for (
						let i = 0;
						i < Math.ceil(array.length / employeesItemsPerPage);
						i++
					) {
						mainArray.push([]);
						for (let a = 0; a < employeesItemsPerPage; a++) {
							if (array[n] != undefined) {
								mainArray[i].push(array[n]);
								n++;
							}
						}
					}

					setEmployees(mainArray);
					setEmployeesTotalItems(res.data.length);

					setNotFound(false);
				} else {
					setNotFound(true);
				}
				setBuffer(false);
				searchTextField = searchText.search;
				hightlight(searchTextField);

				setSearchText((prev) => ({ ...prev, search: '' }));
				searchTextField = searchText.search;
			} catch (err) {
				console.log(err);
			}
		} else if (
			searchText.search.length !== 0 &&
			searchText.search.length > 255
		) {
			alert('Search text is too big to search.');
		} else {
			alert("Can't search, search field is empty.");
		}
	};

	//---------------------------------------------------------

	const [dialog, setDialog] = useState(false);

	const openDialog = (index) => {
		setDialog(employees[employeesActivePage - 1][index]);
	};

	const closeDialog = () => {
		setDialog(false);
	};

	//---------------------------------------------------------

	const changeStatus = async (id, status) => {
		if (status == 0) {
			status = 1;
		} else {
			status = 0;
		}

		const data = {
			id: id,
			status: status,
			adminId: JSON.parse(localStorage.getItem('admin')).id,
		};

		try {
			setNotFound(true);
			setBuffer(true);
			const res = await axios.post(`${baseUrl}/employees/changeStatus`, data, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				setNotFound(false);
				getEmployees();
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	// const totalCount = () => {
	// 	let count = 0;
	// 	for (let i = 0; i < employees.length; i++) {
	// 		count = count + employees[i].length;
	// 	}
	// 	return count;
	// };

	useEffect(() => {
		document.addEventListener('keyup', (e) => {
			if (e.key !== '/') return;
			let searchInput = document.getElementById('search-input');
			searchInput.focus();
		});
	}, []);

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Navigation />
				<div className='side-container'>
					{buffer && notFound ? <Buffer /> : <></>}

					{employees.length === 0 && !buffer ? (
						<Fallback
							title='Not found'
							text='No data found in the database. Try refreshing the page.'
						/>
					) : (
						<></>
					)}

					{employees.length !== 0 && !notFound && !buffer ? (
						<>
							<div className='screen-title headline-large'>Employees</div>
							<div className='search-container'>
								<input
									type='text'
									className='body-medium'
									id='search-input'
									name='search'
									onChange={handleChange}
									placeholder='Search here (press "/" to focus)'
									onKeyUp={(event) => enterClick(event)}
								/>
								<button
									disabled={isRefreshing}
									className={
										isRefreshing ? 'icon-button refreshing' : 'icon-button'
									}
									onClick={() => {
										getEmployees();
									}}
									title='Reload'
								>
									{fetchComplete ? <CheckCircle /> : <Refresh />}
								</button>
								<button
									type='button'
									id='on-enter'
									className='filled-tonal-button'
									onClick={search}
								>
									Search
								</button>
							</div>

							<div className='table-container center'>
								<table>
									<thead>
										<tr>
											<th className='left-box'>
												<div className='table-head'>
													<div className='table-title'>Sr.&nbsp;No.</div>
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>Full&nbsp;name</div>
													<div className='sort-container'>
														<button
															className='sort-button'
															title='Sort by ascending order'
															onClick={() =>
																sort('employees', 'fullname', 'ASC')
															}
														>
															<ExpandLess />
														</button>
														<button
															className='sort-button'
															title='Sort by descending order'
															onClick={() =>
																sort('employees', 'fullname', 'DESC')
															}
														>
															<ExpandMore />
														</button>
													</div>
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>Designation</div>
													<div className='sort-container'>
														<button
															className='sort-button'
															title='Sort by ascending order'
															onClick={() =>
																sort('employees', 'designation', 'ASC')
															}
														>
															<ExpandLess />
														</button>
														<button
															className='sort-button'
															title='Sort by descending order'
															onClick={() =>
																sort('employees', 'designation', 'DESC')
															}
														>
															<ExpandMore />
														</button>
													</div>
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>Station</div>
													<div className='sort-container'>
														<button
															className='sort-button'
															title='Sort by ascending order'
															onClick={() =>
																sort('employees', 'station', 'ASC')
															}
														>
															<ExpandLess />
														</button>
														<button
															className='sort-button'
															title='Sort by descending order'
															onClick={() =>
																sort('employees', 'station', 'DESC')
															}
														>
															<ExpandMore />
														</button>
													</div>
												</div>
											</th>

											<th>
												<div className='table-head'>
													<div className='table-title'>Mobile</div>
													<div className='sort-container'>
														<button
															className='sort-button'
															title='Sort by ascending order'
															onClick={() => sort('employees', 'mobile', 'ASC')}
														>
															<ExpandLess />
														</button>
														<button
															className='sort-button'
															title='Sort by descending order'
															onClick={() =>
																sort('employees', 'mobile', 'DESC')
															}
														>
															<ExpandMore />
														</button>
													</div>
												</div>
											</th>

											<th className='right-box'>
												<div className='table-head'>
													<div className='table-title'>Status</div>
													<div className='sort-container'>
														<button
															className='sort-button'
															title='Sort by ascending order'
															onClick={() => sort('employees', 'active', 'ASC')}
														>
															<ExpandLess />
														</button>
														<button
															className='sort-button'
															title='Sort by descending order'
															onClick={() =>
																sort('employees', 'active', 'DESC')
															}
														>
															<ExpandMore />
														</button>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									{employees[employeesActivePage - 1].map((employee, index) => {
										// let className = employee.active == 1 ? 'success-row' : '';

										return (
											<tbody
												// key={`employee-${employee.id}`}
												key={Math.random()}
											>
												<tr className='gap'></tr>
												<tr>
													<td className='center left-box'>
														<div
															className={
																employee.active == 0 ? 'dot' : 'dot success'
															}
														></div>
														{(employeesActivePage - 1) * employeesItemsPerPage +
															(index + 1)}
													</td>
													<td className='hightlight'>{employee.fullname}</td>
													<td className='hightlight'>{employee.designation}</td>
													<td className='hightlight'>{employee.station}</td>
													<td className='hightlight'>+91 {employee.mobile}</td>
													<td
														className='right-box'
														onClick={() => {
															employeesDesignations.map((desigination) => {
																if (
																	employee.designation ==
																		desigination.designation &&
																	desigination.designation != 'SR DCM'
																) {
																	changeStatus(employee.id, employee.active);
																}
															});
														}}
													>
														<div className='display-center'>
															{employeesDesignations.map(
																(desigination, index) => {
																	if (
																		employee.designation ==
																			desigination.designation &&
																		desigination.designation != 'SR DCM'
																	) {
																		return employee.active == 0 ? (
																			<Toggle
																				key={Math.random()}
																				state={false}
																			/>
																		) : (
																			<Toggle
																				key={Math.random()}
																				state={true}
																			/>
																		);
																		// ? <div key={index} className='success label-large'>Click to active</div>
																		// : <div key={index} className='warning label-large'>Click to inactive</div>
																	} else {
																		return <div key={Math.random()}></div>;
																	}
																}
															)}
															{employee.designation == 'SR DCM' &&
																'Active by default'}
														</div>
													</td>
												</tr>
											</tbody>
										);
									})}
								</table>
							</div>
							<Pagination
								getTotalItems={employeesTotalItems}
								getItemsPerPage={employeesItemsPerPage}
								getPageNumber={getPageNumberEmployees}
								activePage={employeesActivePage}
							/>
						</>
					) : (
						<></>
					)}

					<Footer />
				</div>
			</div>

			{dialog && (
				<div className='dialog-container'>
					<div className='dialog'>
						<div className='dialog-top'>
							<div className='title title-large'>Employee details</div>
							<button
								className='icon-button'
								onClick={closeDialog}
							>
								<Clear />
							</button>
						</div>

						<div className='dialog-content'>
							<div className='container-3'>
								<div className='label label-medium'>Full name</div>
								<div className='full-name title-large'>{dialog.fullname}</div>

								<div className='label label-medium'>Designation</div>
								<div className='id-number body-large'>{dialog.designation}</div>

								<div className='details-container-1'>
									<div>
										<div className='label label-medium'>Station</div>
										<div className='aadhaar-id body-large'>
											{dialog.station}
										</div>
									</div>

									<div>
										<div className='label label-medium'>Mobile number</div>
										<div className='valid-upto-date body-large'>
											{dialog.mobile}
										</div>
									</div>
								</div>

								{/* <div className='details-container-1'>
                                    <div>
                                        <div className='label label-medium'>Date of issue</div>
                                        <div className='designation body-large'>{dialog.issueDate}</div>
                                    </div>

                                    <div>
                                        <div className='label label-medium'>Valid upto</div>
                                        <div className='unit body-large'>{dialog.validUpto}</div>
                                    </div>

                                    <div>
                                        <div className='label label-medium'>Valid for</div>
                                        <div className='issue-date body-large'>{dialog.validFor}</div>
                                    </div>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			)}

			{loader && <Loading />}
		</>
	);
};

export default Employees;

//------------------------------------------------------------------------------------
