//------------------------------------------------------------------------------------

const getTime = (datetime) => {
	let date = false;
	if (datetime) {
		var c_date = new Date(datetime);
		var hrs = c_date.getHours();
		var min = c_date.getMinutes();
		if (isNaN(hrs) || isNaN(min) || c_date === 'Invalid Date') {
			return null;
		}
		var type = hrs < 12 ? ' AM' : ' PM';
		date = (+hrs % 12 || hrs) + ':' + min + type;
		return date;
	}
	return date;
};

export default getTime;

//------------------------------------------------------------------------------------
